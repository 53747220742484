import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'

import SidebarAdmin from "./sidebar"

// MUI
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Add, Delete, Edit, ExpandMore } from '@mui/icons-material'
import { useSnackbar } from "notistack";

export default function QuestionsAdmin({api}){
    const { enqueueSnackbar } = useSnackbar()
    let history = useHistory()

    const [subjectid, setSubjectid] = useState('')
    const [questionid, setQuestionid] = useState('')
    const [question, setQuestion] = useState('')
    const [op1, setOp1] = useState('')
    const [op2, setOp2] = useState('')
    const [op3, setOp3] = useState('')
    const [answer, setAnswer] = useState('')
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
        getSubjects()
    }, [])

    useEffect(() => {
        getQuestions(subjectid)
    }, [subjectid])

    const [questions, setQuestions] = useState([])
    const getQuestions = (subjectid) => {
		var formData = new FormData();
		formData.append("get-questions", subjectid);
		fetch(api, {
			method: "post",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				data.status === false ? setQuestions(data) : setQuestions(data.data);
			});
	};

    const addQuestion = () => {
        setUploading(true)
        var formData = new FormData()
        formData.append('add-question', subjectid)
        formData.append("questionid", questionid);
        formData.append("question", question);
        formData.append("op1", op1);
        formData.append("op2", op2);
        formData.append("op3", op3);
        formData.append("answer", answer);
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploading(false)
            enqueueSnackbar(data.msg)
            data.status === true && getQuestions(subjectid)
            setQuestion(''); setOp1(''); setOp2(''); setOp3(''); setAnswer('')
        })
    }

    const getQuestionDetail = (questionid) => {
        enqueueSnackbar('Loading question details')
        window.scroll(0, 0)
        var formData = new FormData()
        formData.append('get-question-details', questionid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            enqueueSnackbar('Question details loaded.')
            if(data.status === true){
                setQuestionid(data.data.questionid); setQuestion(data.data.question); setOp1(data.data.op1); setOp2(data.data.op2); setOp3(data.data.op3); setAnswer(data.data.answer)
            }
        })
    }

    const readytodelete = (questionid) => {
		setQuestionid(questionid); setDeleteDialog(true);
	}
    
    const [deleteDialog, setDeleteDialog] = useState(false)
	const [deleting, setDeleting] = useState(false)
	const [getdelete, setGetdelete] = useState('')
    const deleteQuestion = () => {
        setDeleting(true)
        enqueueSnackbar('Deleting...')
        var formData = new FormData()
        formData.append('delete-question', questionid)
        formData.append('subjectid', subjectid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setDeleting(false)
            enqueueSnackbar(data.msg)
            data.status === true && getQuestions(subjectid); setDeleteDialog(false); setGetdelete('')
        })
    }

    const [subjects, setSubjects] = useState([])
    const getSubjects = () => {
        var formData = new FormData()
        formData.append('subjects', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status === false){
                setSubjects(data)
            } else {
                setSubjects(data.data); setSubjectid(data.data[0]['subjectid'])
            }
        })
    }
    

    return(
        <Box><SidebarAdmin />
        <Box className='content' mb={7} >
            <Container>
                <Grid container spacing={2} mt={0} >
                    <Grid item xs={12} >
                        <Stack direction='row' spacing={1} mb={2} >
                            <FormControl fullWidth size="small" >
                                <InputLabel>Subject</InputLabel>
                                <Select
                                    value={subjectid}
                                    label="Subject"
                                    onChange={(event) => setSubjectid(event.target.value)}
                                >
                                {subjects.status !== false && subjects.map((row, i) =>
                                    <MenuItem value={row.subjectid} key={i} >{row.subject}</MenuItem> ) }
                                </Select>
                            </FormControl>
                            <Button variant="outlined" color="info" startIcon={<Add/>} onClick={() => history.push('/question-subject-admin')} >Add</Button>
                        </Stack> <Divider/>
                        <Box mt={2}>
                            <TextField fullWidth variant="outlined" type="text" label='Question' rows={3} multiline value={question} onChange={(event) => setQuestion(event.target.value)} />
                        </Box>
                        <Stack direction={{xs: 'column', md: 'row'}} spacing={1} mt={1} >
                            <TextField fullWidth variant="outlined" type="text" label='Option 1' size="small" value={op1} onChange={(event) => setOp1(event.target.value)} />
                            <TextField fullWidth variant="outlined" type="text" label='Option 2' size="small" value={op2} onChange={(event) => setOp2(event.target.value)} />
                        </Stack>
                        <Stack direction={{xs: 'column', md: 'row'}} spacing={1} mt={1} >
                            <TextField fullWidth variant="outlined" type="text" label='Option 3' size="small" value={op3} onChange={(event) => setOp3(event.target.value)} />
                            <FormControl fullWidth size="small" >
                                <InputLabel>Answer</InputLabel>
                                <Select
                                    value={answer}
                                    label="Answer"
                                    onChange={(event) => setAnswer(event.target.value)}
                                > 
                                <MenuItem value={op1} >Option 1</MenuItem>
                                <MenuItem value={op2} >Option 2</MenuItem>
                                <MenuItem value={op3} >Option 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Box mt={2}>
                            <Button variant="contained" color="info" sx={{width: '100%'}} disabled={uploading} onClick={() => addQuestion()} >{uploading ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} mt={3} >
                        { questions.status === false ? <Alert severity="warning">{questions.msg}</Alert> :
                        <TableContainer component={Paper}>
                            <Table size='small' >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell align="left">Question</TableCell>
                                        <TableCell align="left">Options</TableCell>
                                        <TableCell align="left">Answer</TableCell>
                                        <TableCell align="right">Delete</TableCell>
                                        <TableCell align="right">Edit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {questions.map((row, i) =>
                                    <TableRow>
                                        <TableCell align='left'>{(questions.length)-i}</TableCell>
                                        <TableCell align="left">{row.question}</TableCell>
                                        <TableCell align="left"><Typography fontSize={14} >{row.op1}, {row.op2}, {row.op3}</Typography></TableCell>
                                        <TableCell align="left">{row.answer}</TableCell>
                                        <TableCell align="right"><IconButton onClick={() => readytodelete(row.questionid)} ><Delete/></IconButton></TableCell>
                                        <TableCell align="right"><IconButton onClick={() => getQuestionDetail(row.questionid)} ><Edit/></IconButton></TableCell>
                                    </TableRow> )}
                                </TableBody>
                            </Table>
                        </TableContainer> }
                    </Grid>
                </Grid>
                <Dialog open={deleteDialog} fullWidth maxWidth="xs" onClose={() => setDeleteDialog(false)}>
					<DialogContent>
						<Box pb={3}>
							<Typography fontSize={30} color="orange" fontWeight="bold">
								Do you want to delete this question?
							</Typography>
							<Typography fontSize={12} color="darkred" mb={2}>
								Note: All data releted to this question will be deleted. Like results etc...
							</Typography>

							<Typography fontSize={14} color="gray" mb={1}>
								To delete this question type <i>'delete'</i> below
							</Typography>
							<TextField fullWidth variant="standard" placeholder="delete" value={getdelete} onChange={(event) => setGetdelete(event.target.value)} onKeyPress={(event) => getdelete === 'delete' && event.key === 'Enter' && deleteQuestion()} />
						</Box>
					</DialogContent>
					<Divider />
					<DialogActions>
						<Button onClick={() => setDeleteDialog(false)} >Close</Button>
						<Button variant="contained" disabled={getdelete === 'delete' ? false : true} onClick={() => deleteQuestion()} >{deleting ? <CircularProgress color="inherit" size={24} /> : 'Delete'}</Button>
					</DialogActions>
				</Dialog>
            </Container>
        </Box>
        </Box>
    );
}
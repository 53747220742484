import React from "react";
import { useHistory } from "react-router-dom";

// MUI
import { Button, Stack, Typography } from '@mui/material'
import { ArrowBackIosNew } from '@mui/icons-material'

export default function BackNew({name}){
    let history = useHistory()
    return(
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{borderBottom: 'thin solid #cccccc'}} mb={1} mt={1} >
            <Button startIcon={<ArrowBackIosNew/>} onClick={() => history.go(-1)} >Back</Button>
            <Typography fontSize={14} fontWeight='bold' letterSpacing={1} color='orange' >{name}</Typography>
        </Stack>
    );
}
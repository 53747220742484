import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import QRCode from "react-qr-code";
import { toJpeg } from 'html-to-image';

// MUI
import { AppBar, Backdrop, Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import { Download, WhatsApp } from "@mui/icons-material";

export default function ComputerCertificate({api, bimg}){
    let history = useHistory()
    let { studentid } = useParams()

    const area = useRef()
    const printit = useReactToPrint({
        content:() => area.current,
    })

    const [print, setPrint] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('computer-certificate-detail', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPrint(data)
            }
        })
    }, [studentid])

    useEffect(() => {
        document.title = print.s_name
    }, [print])

    const [admin, setAdmin] = useState(true)
    const [alertDia, setAlertDia] = useState(false)
    useEffect(() => {
        fetch('/instituteapi/admin/ui.php')
        .then(res => res.json())
        .then(data => {
            if(data.loggedin === true){
                setAdmin(true); setAlertDia(true)
            } else {
                setAdmin(false)
            }
        })
    }, [])
    const [progress, setProgress] = useState(false)
    async function download() {
        setProgress(true)
        try {
            await toJpeg(area.current, { cacheBust: true, quality: 0.3 })
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = print.s_name
                link.href = dataUrl
                link.click()
            })
            setProgress(false)
            .catch((err) => {
                console.log(err)
            })
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    }

    return(
        <>{ progress &&
        <Backdrop
        sx={{ color: '#fff', zIndex: 10000}}
        open={true}
        onClick={progress}
        >
        <CircularProgress color="inherit" />
      </Backdrop> }
        <AppBar position="sticky" component='nav' color="warning">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => history.go(-1)}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography sx={{flexGrow: '1'}} >CERTIFICATE</Typography>
                <Button color='inherit' size="small" onClick={() => history.push(`/marksheet/${studentid}`)} >Marksheet</Button>
                <Box><IconButton color="inherit" onClick={() => printit()} ><PrintIcon/></IconButton></Box>
                <Box><IconButton color="inherit" onClick={() => download()} ><Download/></IconButton></Box>
                <a href={`https://wa.me/91${print.s_mobile}/?text=${window.location.href}`} target="_blank" style={{color: 'white'}} ><IconButton color="inherit" ><WhatsApp/></IconButton></a>
            </Toolbar>
        </AppBar>
        <div className="container-fluid mt-5">
            <div className="row">
                <div className="col-md-9 col-12 mx-auto p-0 m-0 overflow-auto">
                    <Box ref={area} id="pdfContent" className='position-relative print-landscape' sx={{width: '295mm', height: '208mm'}} >
                        <img src='/cert/certificate.webp' alt='New certificate' />
                        <QRCode size={103} style={{top: '15.5%', left: '9%', position: 'absolute'}} value={`The certificate issued by 'NEXUS EDUCATION & WELFARE FOUNDATION' from franchise '${print.b_name} (${print.b_code})' of ${print.s_name} (Registration number: ${print.s_enrollment}) on ${print.s_issuedate} of course ${print.c_name}`} />
                        <img className="position-absolute" src={`/instituteassets/studentimg/${print.s_pic}`} alt='Print profile' style={{width: '120px', height: '130px', top: '15.5%', right: '8.5%'}} />
                        <Typography variant='body2' style={{position: 'absolute', top: '35.1%', left: '21%'}} >{print.b_code}</Typography>
                        <Typography variant='body2' style={{position: 'absolute', top: '35.1%', left: '56.5%'}} >{print.s_enrollment}</Typography>
                        <Typography variant='body2' style={{position: 'absolute', top: '35.1%', left: '84%'}} >{print.s_issuedate}</Typography>
                        <Typography variant='h6' style={{position: 'absolute', top: '40%', left: '8.6%', color: '#ef6c00'}} fontFamily='serif' >Certificate No. :</Typography>
                        <Typography variant='body1' style={{position: 'absolute', top: '40.7%', left: '21%'}} >{print.cert_no}</Typography>
                        { Number(print.s_aadhaar) > 0 && <>
                        <Typography variant='h6' style={{position: 'absolute', top: '40%', left: '71.5%', color:'#ef6c00'}} fontFamily='serif' >Aadhaar No. :</Typography>
                        <Typography variant='body1' style={{position: 'absolute', top: '40.7%', left: '82.5%'}} >{print.s_aadhaar}</Typography></>}
                        <div className="position-absolute col-12 text-center" style={{top: '48%'}}>
                            <Typography variant="h5" sx={{fontWeight: 'bold', fontFamily: 'sans-serif'}} >{print.s_name}</Typography>
                        </div>
                        <Typography variant='body1' style={{position: 'absolute', top: '58.8%', left: '18.4%'}} >{print.s_guardian}</Typography>
                        <Typography variant='body1' style={{position: 'absolute', top: '63.3%', left: '8.6%'}} >{print.c_duration}</Typography>
                        <Typography variant='body1' style={{position: 'absolute', top: '63.3%', left: '30%'}} >{print.c_name} ({print.c_title})</Typography>
                        <Typography variant='body1' style={{position: 'absolute', top: '67.8%', left: '23%'}} >{print.b_name}</Typography>
                        <Typography variant='body1' style={{position: 'absolute', top: '72.3%', left: '23%'}} >{(Number(print.marks) * 100 / Number(print.fullMarks)) >= 85 ? 'A+' : (Number(print.marks) * 100 / Number(print.fullMarks)) >= 70 ? 'A' : (Number(print.marks) * 100 / Number(print.fullMarks)) >= 60 ? 'B' : (Number(print.marks) * 100 / Number(print.fullMarks)) >= 45 ? 'C' : 'D'}</Typography>
                        <Typography variant='subtitle2' style={{position: 'absolute', top: '91.7%', right: '14.5%'}} >{print.issue_year >= '2023' && 'NGO Darpan Unique ID - BR/2022/0316689'}</Typography>

                        {print.issue_year >= '2023' && admin === false && <><img src={bimg+print.s_center_sig} alt="Center head signature" style={{width: '100px', position: 'absolute', bottom: '12%', left: '8.7%'}} />
                        <img src="/cert/seal.png" alt="Center head signature" style={{width: '70px', position: 'absolute', bottom: '10.5%', left: '25%'}} />
                        <img src="/cert/sig-head.png" alt="Center head signature" style={{width: '100px', position: 'absolute', bottom: '12%', left: '37%'}} />
                        <Typography sx={{position: 'absolute', bottom: '16%', right: '7.5%'}} color='red' variant="body1" >This is a computer-generated certificate and is digitally signed</Typography></>}
                    </Box>
                </div>
            </div>
            <Dialog open={alertDia} fullWidth>
                <DialogContent>
                    <Box sx={{padding: {md: 2}}} >
                        <Typography variant='h5' color='red' >Hey! You are logged in as Admin</Typography>
                        <Typography variant='caption' color='gray'>If you want to download the digital certificate, kindly log out of the admin account. Or log in to another browser.</Typography>
                        <Box textAlign='center' mt={3} >
                            <Button variant='outlined' onClick={() => setAlertDia(false)} >OK, GOT IT</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div></>
    );
}
import React, { useEffect, useState } from 'react'

/* MUI */
import { Box, Stack, Typography } from '@mui/material'

export default function PrintHeaderBranch(){

    const api = '/instituteapi/branch/index.php'

    const [view, setView] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('branch-detail', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setView(data)
            }
        })
    }, [])

    return(
        <Box mb={2} sx={{display: 'none', displayPrint: 'block'}} >
            <Stack direction='row' justifyContent='space-between' alignItems='center' >
                <Box>
                    <Typography variant='h5' noWrap fontWeight='bold' >{view.b_name}</Typography>
                    <Typography variant='body2' ml={0.1} fontFamily='monospace' >{view.b_state}</Typography>
                    <Typography variant='body2' ml={0.1} fontFamily='monospace' >{view.b_city} - {view.b_code}</Typography>
                </Box>
                <Box>
                    <img src='/img/logo.png' style={{width: 120}} alt='Nexus Computer Logo' />
                </Box>
            </Stack>
            <Box padding={0.4} sx={{background: '#ef6c00'}} mt={1.5} ></Box>
        </Box>
    );
}
import React, { useEffect, useState } from 'react'
import { Route, useRouteMatch, useHistory, useParams } from 'react-router-dom'

import SidebarBranch from './sidebar'
import HeaderBranch from './header'
import Close from './../close'
import SingleAttendanceReportBranch from './attendance-report-single'
import Bar from '../bar'

/* MUI */
import { Alert, AppBar, Avatar, Box, Button, ButtonGroup, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Divider, Fab, FormControl, Grid, IconButton, InputLabel, LinearProgress, Menu, MenuItem, Paper, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Cancel, CloseRounded, Delete, MoreVert, Visibility, WorkspacePremium } from '@mui/icons-material'
import axios from 'axios'

export default function StudentBranch({api}){
    const { path, url } = useRouteMatch()
    let history = useHistory()
    const today = new Date()

    const [students, setStudents] = useState([])
    const [studentwarn, setStudentwarn] = useState('')
    const [loading, setLoading] = useState(true)
    const [query] = useState('')
    useEffect(() => {
        viewstudents(query)
    }, [query])
    const viewstudents = (query) => {
        setStudentwarn('')
        var formData = new FormData()
        formData.append('view-students', query)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setStudentwarn(data.warning)
            } else {
                setStudents(data)
            }
        })
    }

    const searchstudent = (event) => {
        if(event.keyCode === 13){
            viewstudents(event.target.value)
        }
    }

    const [menu, setMenu] = useState(null)
    const [menuid, setMenuid] = useState('')
    const openmenu = (event, menuid) => {
        setMenuid(menuid)
        setMenu(event.currentTarget)
    }

    return(
        <>
        <div className='sidebar'> <SidebarBranch /> </div>
        <div className='content'>
        <HeaderBranch />
        <div className='container-fluid'>
            <div className='row p-3'>
                <div className='col-12 text-center p-0'>
                    <TextField fullWidth type='text' variant='standard' label='Search' placeholder='Search by student name, code...' onKeyUp={(event) => searchstudent(event)} />
                </div>
                <div className='text-end p-0'>
                    <Button color='warning' onClick={() => history.push(`${url}/configure/0`)} >Add student</Button>
                </div>
            </div>
        </div>
        <Container sx={{marginBottom: '3em'}} >
            { loading ? <LinearProgress color='warning' /> :
            studentwarn ? <Alert severity='warning'>{studentwarn}</Alert> :
            <Grid container justifyContent='center' >
                { students.map((data, i) =>
                <Grid item xs={12} md={7} key={i}>
                    <Paper sx={{ backgroundColor: data.pending === '1'  ? '#ffd666' : data.issued === '1' && '#a7ffeb'}} >
                        <Stack direction='row' justifyContent='space-between' alignItems='center' mb={1} padding={1} >
                            <Box sx={{overflow: 'hidden'}} p={1} >
                                <Typography variant='h6' noWrap >{data.s_name}</Typography>
                                <Typography variant='body1' noWrap color='gray' >{data.s_code}</Typography>
                                <Typography variant='body2' noWrap color='gray' >{data.c_title}, {data.c_name}</Typography>
                                <Typography variant='body2' noWrap color='gray' >{data.c_duration} Months</Typography>
                                <Typography variant='body2' noWrap mb={1} > <a style={{color: 'green'}} href={`tel: ${data.s_mobile}`}>{data.s_mobile}</a> </Typography>
                                
                                <IconButton size='small' onClick={() => history.push(`${url}/info/${data.studentid}`)} ><InfoIcon /></IconButton>
                                <IconButton size='small' onClick={() => history.push(`${url}/configure/${data.studentid}`)} ><EditIcon /></IconButton>
                                { data.c_title === 'TYPING' ? 
                                    data.issued === '1' && <IconButton size='small' onClick={() => history.push(`/typing-certificate/${data.studentid}`)} ><WorkspacePremium /></IconButton>
                                    :
                                    data.issued === '1' && <IconButton size='small' onClick={() => history.push(`/certificate/${data.studentid}`)} ><WorkspacePremium /></IconButton>
                                }
                                <IconButton size='small' onClick={(event) => openmenu(event, data.studentid)} ><MoreVert /></IconButton>
                            </Box>
                            <Avatar src={`/instituteassets/studentimg/${data.s_pic}`} sx={{width: 140, height: 140, borderRadius: 1}} />
                        </Stack>
                    </Paper>
                </Grid>) }
            </Grid> }
            <Menu open={Boolean(menu)} anchorEl={menu} onClose={() => setMenu(null)} >
                <MenuItem onClick={() => history.push(`${url}/signature/${menuid}`)} >Signature & Aadhaar</MenuItem>
                <MenuItem onClick={() => history.push(`/idcard/${menuid}`)} >ID Card</MenuItem>
                <MenuItem onClick={() => history.push(`/student-history-branch/${menuid}`)} >Student History</MenuItem>
                <MenuItem onClick={() => history.push(`${url}/at-report/${menuid}`)} >Attendance report</MenuItem>
                <MenuItem onClick={() => history.push(`/form/${menuid}`)} >Admission form</MenuItem>
            </Menu>
        </Container>
        </div>
        <Route path={`${path}/configure/:studentid`} component={Configure} />
        <Route path={`${path}/info/:studentid`} component={Info} />
        <Route path={`${path}/signature/:studentid`} component={Signature} />
        <Route path={`${path}/at-report/:studentid`} component={() => <SingleAttendanceReportBranch api={api} />} />
        </>
    );

    function Configure(){
        const { studentid } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')
        const { path, url } = useRouteMatch()

        const [code, setCode] = useState('')
        const [email, setEmail] = useState('')
        const [name, setName] = useState('')
        const [guardian, setGuardian] = useState('')
        const [address, setAddress] = useState('')
        const [po, setPo] = useState('')
        const [ps, setPs] = useState('')
        const [city, setCity] = useState('')
        const [dist, setDist] = useState('')
        const [state, setState] = useState('')
        const [postel, setPostel] = useState('')
        const [aadhaar, setAadhaar] = useState('')
        const [mobile, setMobile] = useState('')
        const [birth, setBirth] = useState('')
        const [gender, setGender] = useState('')
        const [nation, setNation] = useState('')
        const [pending, setPending] = useState('')
        const [joindate, setJoindate] = useState(`${today.getFullYear()}-${today.toLocaleString(undefined, {month: '2-digit'})}-${today.toLocaleString(undefined, {day: '2-digit'})}`)
        useEffect(() => {
            var formData = new FormData()
            formData.append('student-detail', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setCode(data.s_code); setEmail(data.s_email); setName(data.s_name); setGuardian(data.s_guardian); setAddress(data.s_address); setPo(data.s_po); setPs(data.s_ps); setCity(data.s_city); setDist(data.s_dist); setState(data.s_state); setPostel(data.s_postel); setAadhaar(data.s_aadhaar); setMobile(data.s_mobile); setBirth(data.s_birth); setGender(data.s_gender); setNation(data.s_nation); setPending(data.s_pending); setJoindate(data.s_joindate)
                }
            })
        }, [studentid])

        const [joining, setJoining] = useState(false)
        const upload = () => {
            setJoining(true)
            var formData = new FormData()
            formData.append('configure-student', studentid)
            formData.append('code', code)
            formData.append('email', email)
            formData.append('name', name)
            formData.append('guardian', guardian)
            formData.append('address', address)
            formData.append('po', po)
            formData.append('ps', ps)
            formData.append('city', city)
            formData.append('dist', dist)
            formData.append('state', state)
            formData.append('postel', postel)
            formData.append('aadhaar', aadhaar)
            formData.append('mobile', mobile)
            formData.append('birth', birth)
            formData.append('gender', gender)
            formData.append('nation', nation)
            formData.append('pending', pending)
            formData.append('joindate', joindate)

            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setJoining(false)
                if(data.warning){
                    setMessage(data.warning)
                    setOpen(true)
                } else {
                    viewstudents('')
                    history.go(-1)
                }
            })
        }

        const [qualifications, setQualifications] = useState([])
        const [qualiwarn, setQualiwarn] = useState('')
        const [loading, setLoading] = useState(true)

        useEffect(() => {
            viewqualifications(studentid)
        }, [studentid])

        const viewqualifications = (studentid) => {
            setQualiwarn('')
            var formData = new FormData()
            formData.append('view-qualifications', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setQualiwarn(data.warning)
                } else {
                    setQualifications(data)
                }
            })
        }

        const [qid, setQid] = useState('')
        const deleteQualification = (tableid) => {
            setQid(tableid)
            var formData = new FormData()
            formData.append('delete-qualification', tableid)
            formData.append('studentid', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setQid('')
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else {
                    viewqualifications(studentid)
                }
            })
        }

        const [qopen, setQopen] = useState(false)
        const [board, setBoard] = useState('')
        const [exam, setExam] = useState('')
        const [pass, setPass] = useState('')
        const [marks, setMarks] = useState('')

        const [warning, setWarning] = useState('')
        const [uploading, setUploading] = useState(false)
        const [added, setAdded] = useState('')
        const uploadnow = () => {
            setUploading(true); setAdded('')
            var formData = new FormData()
            formData.append('configure-qualification', studentid)
            formData.append('board', board)
            formData.append('exam', exam)
            formData.append('pass', pass)
            formData.append('marks', marks)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWarning('')
                setUploading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setBoard(''); setExam(''); setPass(''); setMarks('')
                    setAdded('Adding succeed');
                    viewqualifications(studentid);
                }
            })
        }

        return(
            <Dialog open={true} fullScreen>
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <Dialog open={qopen} fullWidth maxWidth='xs' onClose={() => setQopen(false)}>
                    <DialogTitle>Add qualification</DialogTitle><Divider/>
                    <DialogContent>
                        <Box pb={4} >
                            <Stack spacing={1.5}>
                                <TextField fullWidth variant='outlined' type='text' label='Board' value={board} onChange={(event) => setBoard(event.target.value)} />
                                <TextField fullWidth variant='outlined' type='text' label='Examination' value={exam} onChange={(event) => setExam(event.target.value)} />
                                <TextField fullWidth variant='outlined' type='number' label='Passed year' value={pass} onChange={(event) => setPass(event.target.value)} />
                                <TextField fullWidth variant='outlined' type='text' label='Obtain marks' value={marks} onChange={(event) => setMarks(event.target.value)} />

                                {warning && <Alert severity='warning'>{warning}</Alert> }
                                {added && <Alert severity='success'>{added}</Alert> }
                            </Stack>
                            <Box textAlign='center' mt={3} >
                                <Button color='warning' variant='contained' size='large' onClick={() => uploadnow()} disabled={uploading} sx={{boxShadow: 'none'}} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
                <AppBar>
                    <Toolbar sx={{display: 'flex'}} >
                        <Typography sx={{flexGrow: 1}} variant='body1' >Configure student</Typography>
                        <Box>
                            <IconButton color='inherit' onClick={() => history.go(-1)} ><CloseRounded /></IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <div className='container-fluid mt-4'>
                        <div className='row p-3'>
                            <div className='col-md-6 col-12 mx-auto'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <TextField fullWidth variant='standard' label='Student code' type='text' className='mt-4' value={code} onChange={(event) => setCode(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Name*' type='text' className='mt-4' value={name} onChange={(event) => setName(event.target.value)} />
                                        <TextField fullWidth variant='standard' label="Father's name*" type='text' className='mt-4' value={guardian} onChange={(event) => setGuardian(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Address*' type='text' className='mt-4' value={address} onChange={(event) => setAddress(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Post office' type='text' className='mt-4' value={po} onChange={(event) => setPo(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Police station' type='text' className='mt-4' value={ps} onChange={(event) => setPs(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='City' type='text' className='mt-4' value={city} onChange={(event) => setCity(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='District' type='text' className='mt-4' value={dist} onChange={(event) => setDist(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='State' type='text' className='mt-4' value={state} onChange={(event) => setState(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Postal code' type='number' className='mt-4' value={postel} onChange={(event) => setPostel(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Aadhaar' type='number' className='mt-4' value={aadhaar} onChange={(event) => setAadhaar(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Email' type='email' className='mt-4' value={email} onChange={(event) => setEmail(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Mobile' type='number' className='mt-4' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                                        <TextField fullWidth variant='standard' helperText='Date of birth' type='date' className='mt-4' value={birth} onChange={(event) => setBirth(event.target.value)} />
                                        <FormControl fullWidth variant='standard' >
                                            <InputLabel>Gender</InputLabel>
                                            <Select value={gender} onChange={(event) => setGender(event.target.value)} >
                                                <MenuItem value='MALE' >Male</MenuItem>
                                                <MenuItem value='FEMALE' >Female</MenuItem>
                                                <MenuItem value='OTHERS' >Others</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField fullWidth variant='standard' label='Nation' type='text' className='mt-4' value={nation} onChange={(event) => setNation(event.target.value)} />
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Pending</InputLabel>
                                            <Select value={pending} onChange={(event) => setPending(event.target.value)} >
                                                <MenuItem value='1' >Yes</MenuItem>
                                                <MenuItem value='0' >No</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField fullWidth variant='standard' helperText='Admission date' type='date' className='mt-4' value={joindate} onChange={(event) => setJoindate(event.target.value)} />
                                    </div>
                                    <div className='col-12 mt-4 text-center'>
                                        <Button className='col-md-4 col-6' variant='contained' size='large' color='warning' disabled={joining} onClick={() => upload()} >{joining ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                                    </div>
                                </div>

                                <div className='row mt-5'>
                                    <div className='col-12'>
                                        {loading ? <LinearProgress color='warning' /> :
                                        qualiwarn ? <Alert severity='warning'>{qualiwarn}</Alert> :
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Board</TableCell>
                                                        <TableCell align='center' >Examinatin</TableCell>
                                                        <TableCell align='center' >Passed year</TableCell>
                                                        <TableCell align='center' >Obtain marks</TableCell>
                                                        <TableCell align='center' >Delete</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {qualifications.map((q, i) =>
                                                    <TableRow key={i} >
                                                        <TableCell>{q.board}</TableCell>
                                                        <TableCell align='center' >{q.exam}</TableCell>
                                                        <TableCell align='center' >{q.pass}</TableCell>
                                                        <TableCell align='center' >{q.marks}</TableCell>
                                                        <TableCell align='center' ><IconButton onDoubleClick={() => deleteQualification(q.q_table_id)} >{q.q_table_id === qid ? <CircularProgress color='inherit' size={24} /> : <DeleteIcon /> }</IconButton></TableCell>
                                                    </TableRow>)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer> }
                                        
                                        <Stack mt={2} spacing={1} direction={{xs: 'column', md: 'row'}} sx={{border: 'thin solid grey'}} padding={2} borderRadius={1} >
                                            <Button variant='outlined' fullWidth color='error' onClick={() => history.push(`${url}/delete/${studentid}`)} >Delete student</Button>
                                            <Button variant='outlined' fullWidth color='info' onClick={() => setQopen(true) } >Add qualification</Button>
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <Route path={`${path}/delete/:studentid`} component={DeleteStudent} />
            </Dialog>
        );
    }

    function Info(){
        const { studentid } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        useEffect(() => {
            joinedcourebatch(studentid)
        }, [studentid])
        const [jcourse, setJcourse] = useState('')
        const [coursetitle, setCoursetitle] = useState('')
        const [jbatch, setJbatch] = useState('')
        const [batchnum, setBatchnum] = useState('')
        const [pic, setPic] = useState('')
        const [name, setName] = useState('')
        const [code, setCode] = useState('')
        const [module, setModule] = useState(0)
        const joinedcourebatch = (studentid) => {
            var formData = new FormData()
            formData.append('joined-course-batch', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setJcourse(data.courseid); setJbatch(data.batchid); setBatchnum(data.batchnum); setPic(data.s_pic); setCoursetitle(data.c_title); setName(data.s_name); setCode(data.s_code); setModule(data.module)
                }
            })
        }

        const [courses, setCourses] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-course', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setCourses(data)
                }
            })
        }, [])

        const joincourse = (courseid) => {
            setJcourse(courseid)
            var formData = new FormData()
            formData.append('join-course', studentid)
            formData.append('courseid', courseid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.success) {
                    setMessage('Succedd'); setOpen(true)
                }
            })
        }

        const [batches, setBatches] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-batches', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setBatches(data)
                }
            })
        }, [])

        const joinbatch = (batchid) => {
            setJbatch(batchid)
            var formData = new FormData()
            formData.append('join-batch', studentid)
            formData.append('batchid', batchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else {
                    setMessage('Succedd'); setOpen(true)
                }
            })
        }

        const [picuploading, setPicuploading] = useState(false)
        const updatepic = (pic) => {
            setPicuploading(true)
            var formData = new FormData()
            formData.append('update-pic', studentid)
            formData.append('pic', pic.target.files[0])

            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data =>{
                setPicuploading(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.success){
                    joinedcourebatch(studentid)
                }
            })
        }

        const [subjects, setSubjects] = useState([])
        const [subjectwarn, setSubjectwarn] = useState('')
        useEffect(() => {
            viewResult(studentid)
        }, [studentid])

        const viewResult = () => {
            var formData = new FormData()
            formData.append('view-results', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setSubjectwarn(data.warning)
                } else {
                    setSubjects(data)
                }
            })
        }

        const [added, setAdded] = useState('')

        const uploadresult = (event, subjectid) => {
            setOpen(false)
            if(event.key === 'Enter' ){
                event.preventDefault()
                var formData = new FormData()
                formData.append('upload-result', studentid)
                formData.append('subjectid', subjectid)
                formData.append('marks', event.target.value)
                setAdded(Number(added)+Number(event.target.value))
                fetch(api, {
                    method: 'post',
                    body: formData
                }).then(res => res.json())
                .then(data => {
                    if(data.warning){
                        setMessage(data.warning); setOpen(true)
                    } else if(data.success){
                        setMessage('Succedd'); setOpen(true);
                        viewResult(studentid)
                    }
                })
            }
        }

        const [typing, setTyping] = useState('')
        
        useEffect(() => {
            viewtyping(studentid)
        }, [])

        const viewtyping = (studentid) => {
            var formData = new FormData()
            formData.append('view-typing-result', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setTyping(data.r_typing)
                }
            })
        }

        const [speed, setSpeed] = useState('')
        const [speedType, setSpeedType] = useState('')
        const [speed1, setSpeed1] = useState('')
        const [speed2, setSpeed2] = useState('')

        const updatetyping = () => {
            var formData = new FormData()
            formData.append('upload-typing-result', studentid)
            formData.append('courseid', jcourse)
            if(speed !== ''){
                formData.append('typing', speed+" Word Per Minute")
            } else {
                formData.append('typing', "Hin-"+speed1+" & Eng-"+speed2+" Word Per Minute")
            }

            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.success){
                    viewtyping(studentid)
                    setMessage('Succedd'); setOpen(true)
                }
            })
        }

        var result = 0
        
        const [deletingresult, setDeletingresult] = useState(false)
        const delete_result = () => {
            setDeletingresult(true)
            var formData = new FormData()
            formData.append('delete-result', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setDeletingresult(false)
                if(data.status == false){
                    setMessage(data.message); setOpen(true)
                } else {
                    setMessage(data.message); setOpen(true)
                    viewResult(); viewtyping(studentid)
                }
            })
        }

        const update_batchnum = (num) => {
            setBatchnum(num)
            var formData = new FormData()
            formData.append('assign-batchnum', studentid)
            formData.append('batchnum', num)
            axios.post(api, formData)
            .then(function(res){
                setMessage(res.data.msg); setOpen(true)
            })
        }

        return(
            <Dialog open={true} fullScreen>
                <Bar text='Student info' />
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogContent>
                    <Container sx={{padding: 0}} >
                        <Grid container spacing={3} >
                            <Grid item xs={12} md={6}>
                                <Card elevation={0} variant='outlined' sx={{height: '100%'}} >
                                    <CardContent sx={{height: '100%'}} >
                                        <label className='col-12 text-center globalimgsize mx-auto' style={{position: 'relative'}} >
                                            <input type='file' accept='.png, .jpeg, .jpg' style={{position: 'absolute', zIndex: '-1', display: 'none'}} onChange={(event) => updatepic(event)} />
                                            <img src={`/instituteassets/studentimg/${pic}`} alt='Student img' className='globalimgsize' />
                                        </label>
                                        <Typography color='green' >{picuploading && 'Uploading... Please wait.' }</Typography>

                                        <TextField fullWidth variant='standard' value={name} disabled sx={{marginTop: 3}} />
                                        <TextField fullWidth variant='standard' value={code} disabled className='mt-4' />
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Select course</InputLabel>
                                            <Select value={jcourse} onChange={(event) => joincourse(event.target.value)} >
                                                {courses.map((course, i) =>
                                                    Number(module) === 1 ?
                                                    course.c_module === '1' &&
                                                    <MenuItem className='text-truncate' key={i} value={course.courseid} >{course.c_title} ({course.c_name}) (Duration: {course.c_duration} Months)</MenuItem>
                                                    :
                                                    course.c_module === '0' &&
                                                    <MenuItem className='text-truncate' key={i} value={course.courseid} >{course.c_title} ({course.c_name}) (Duration: {course.c_duration} Months)</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Select batch</InputLabel>
                                            <Select value={jbatch} onChange={(event) => joinbatch(event.target.value)} >
                                                {batches.map((batch, i) =>
                                                    <MenuItem key={i} value={batch.batchid} >{batch.bh_start} - {batch.bh_end}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>

                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Batch number</InputLabel>
                                            <Select value={batchnum} onChange={(event) => update_batchnum(event.target.value)} >
                                                <MenuItem value={'a'} >A</MenuItem>
                                                <MenuItem value={'b'} >B</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card elevation={0} variant='outlined'>
                                    <CardContent>
                                        {coursetitle === 'TYPING' ?
                                        <Stack spacing={2}>
                                            <FormControl fullWidth variant='standard' >
                                                <InputLabel>Typing Type</InputLabel>
                                                <Select value={speedType} onChange={(event) => setSpeedType(event.target.value)} >
                                                    <MenuItem value='1' >Hindi Typing</MenuItem>
                                                    <MenuItem value='1' >English Typing</MenuItem>
                                                    <MenuItem value='2' >Hindi & English Typing</MenuItem>
                                                </Select>
                                            </FormControl>
                                            { speedType === '1' &&
                                            <TextField fullWidth variant='standard' type='number' label='Speed' value={speed} onChange={(event) => setSpeed(event.target.value)} />}
                                            {speedType === '2' &&
                                            <Stack spacing={2} direction='row' >
                                                <TextField fullWidth variant='standard' type='number' label='Speed (Hindi)' value={speed1} onChange={(event) => setSpeed1(event.target.value)} />
                                                <TextField fullWidth variant='standard' type='number' label='Speed (English)' value={speed2} onChange={(event) => setSpeed2(event.target.value)} />
                                            </Stack>}
                                            <FormControl >
                                                <TextField fullWidth disabled label='Typing speed' value={typing} onChange={(event) => setTyping(event.target.value)} />
                                            </FormControl>
                                            
                                            <Button color='warning' onClick={() => updatetyping()} >UPDATE</Button>
                                        </Stack>
                                        :
                                        <div className='col-12'>
                                            {subjectwarn ? <Alert severity='warning'>{subjectwarn}</Alert> :
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Term</TableCell>
                                                            <TableCell align='right' >Subject</TableCell>
                                                            <TableCell align='right' >Full marks</TableCell>
                                                            <TableCell align='right' >Pass marks</TableCell>
                                                            <TableCell align='right' >Obtain marks</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {subjects.map((sub, i) =>
                                                        <TableRow key={i} >
                                                            <TableCell>{sub.s_term}</TableCell>
                                                            <TableCell align='right' >{sub.s_subject}</TableCell>
                                                            <TableCell align='right' >{sub.s_fmark}</TableCell>
                                                            <TableCell align='right' >{sub.s_pmark}</TableCell>
                                                            <TableCell align='right' >
                                                                <form onSubmit={(event) => event.preventDefault()} >
                                                                    <TextField fullWidth type='number' variant='standard' placeholder={sub.r_marks} onKeyDown={(event) => uploadresult(event, sub.subjectid)} />
                                                                </form>
                                                            </TableCell>
                                                        </TableRow> )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer> }
                                        </div> }
                                        <Typography mt={3} fontSize={12} color='gray' >Double click to <i>delete</i> marks</Typography>
                                        <ButtonGroup sx={{width: '100%'}} >
                                            <Button color='error' onDoubleClick={() => delete_result()} disabled={deletingresult} sx={{width: '100%'}} >{deletingresult ? <CircularProgress color='inherit' size={20} /> : <Delete />}</Button>
                                            { coursetitle == 'TYPING' ? '' : <Button sx={{width: '100%'}} color='primary' >{ (result =  subjects.reduce((total, i) => total + Number(i.r_marks), 0 )) }</Button> }
                                        </ButtonGroup>

                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>    
                </DialogContent>
            </Dialog>
        );
    }

    function Signature(){
        const { studentid } = useParams()

        const [sig, setSig] = useState([])
        useEffect(() => {
            viewSig(studentid)
        }, [studentid])

        const viewSig = (studentid) => {
            var formData = new FormData()
            formData.append('search-sig', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setSig(data)
                }
            })
        }

        const [working, setWorking] = useState(false)
        const [warning, setWarning] = useState('')
        const upload = (file) => {
            var img = file.files[0]
            if(img !== undefined){
                setWorking(true); setWarning('')
                var formData = new FormData()
                formData.append('upload-signature', studentid)
                formData.append('signature', img)
                fetch(api, {
                    method: 'post',
                    body: formData
                }).then(res => res.json())
                .then(data => {
                    setWorking(false)
                    if(data.warning){
                        setWarning(data.warning)
                    } else {
                        viewSig(studentid)
                    }
                })
            } else {
                setWarning('Please select signature.')
            }
        }

        const [aadhaarWarn, setAadhaarWarn] = useState('')
        const [aadhaarUploading, setAadhaarUploading] = useState(false)
        const uploadAadhaar = (file) => {
            setAadhaarUploading(true)
            setAadhaarWarn('')
            var formData = new FormData()
            formData.append('upload-aadhaar', studentid)
            formData.append('aadhaar', file.files[0])
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setAadhaarUploading(false)
                if(data.warning){
                    setAadhaarWarn(data.warning)
                } else {
                    viewSig(studentid)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='sm' onClose={() => history.go(-1)}>
                <DialogTitle>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6' >Student assets</Typography>
                        <IconButton onClick={() => history.go(-1)} ><CloseRounded/></IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Box pb={5} >
                        <Typography variant='caption' color='gray' >Student Signature (Size(W/H) - 500/250, PNG)</Typography>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} mb={3} >
                            <input type='file' className='form-control' accept='.jpeg, .jpg, .png' onChange={(event) => upload(event.target)} />
                            { sig.s_sig1 === '1' ?
                            <a href={`/instituteassets/studentimg/${sig.s_sig}`} rel='tag' target='_blank' ><IconButton color='success' ><Visibility/></IconButton></a>
                            :
                            <Cancel color='error' />}
                        </Stack>
                        {warning && <Alert severity='warning'>{warning}</Alert>}
                        {working && <Typography variant='body2' color='gray' >Uploading...</Typography> }
                        
                        <Typography variant='caption' color='gray' >Student Aadhaar</Typography>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} mb={1} >
                            <input type='file' className='form-control' accept='.pdf' onChange={(event) => uploadAadhaar(event.target)} />
                            { sig.aadhaar_exist === '1' ?
                            <a href={`/instituteassets/studentimg/${sig.s_aadhaar_file}`} rel='tag' target='_blank' ><IconButton color='success' ><Visibility/></IconButton></a>
                            :
                            <Cancel color='error' />}
                        </Stack>
                        { aadhaarUploading && <Typography variant='body2' color='gray'>Uploading...</Typography> }
                        {aadhaarWarn && <Alert severity='warning'>{aadhaarWarn}</Alert> }
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    function DeleteStudent(){
        const { studentid } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [deleting, setDeleting] = useState(false)
        const deleteNow = () => {
            setDeleting(true)
            var formData = new FormData()
            formData.append('delete-student', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setMessage('')
                setDeleting(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.success){
                    history.go(-2)
                }
            })
        }
        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <Snackbar open={open} onClose={() => setOpen(false)} message={message} />
                <DialogContent>
                    <div className='container-fluid mt-4 mb-5'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h3 className='fw-bold'>Do you want to delete student?</h3>
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button color='error' variant='contained' size='large' disabled={deleting} onClick={() => deleteNow()} >{deleting ? <CircularProgress color='inherit' size={24} /> : 'Yes' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}
import React, { useEffect, useRef, useState } from "react";
import { } from 'react-router-dom'

import Bar from './../bar'

// MUI
import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function MonthPaymentReportAdmin({api}){

    const area = useRef()
    const date = new Date()

    const [year, setYear] = useState(date.toLocaleDateString('default', {year: 'numeric'}))
    const [month, setMonth] = useState(date.toLocaleDateString('default', {month: '2-digit'}))

    useEffect(() => {
        entryDetail(year, month)
        branchEntry(year, month)
    }, [year, month])

    const [closing, setClosing] = useState([])
    const entryDetail = (year, month) => {
        var formData = new FormData()
        formData.append('entry-detail', year)
        formData.append('month', month)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setClosing(0)
            } else {
                setClosing(data.c_balance)
            }
        })
    }

    const [bentry, setBentry] = useState([])
    const branchEntry = (year, month) => {
        var formData = new FormData()
        formData.append('view-entry-report', year)
        formData.append('month', month)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setBentry([])
            } else{
                setBentry(data)
            }
        })
    }

    var x = 0
    var y = 0

    const income = bentry.reduce((total, i) => total+Number(i.e_credit), 0)
    const expand = bentry.reduce((total, i) => total+Number(i.e_debit), 0)

    return(
        <Box>
            <Bar text='Payment report' printTrue={true} printArea={area} />
            <Container>
                <Grid container mt={3} >
                    <Grid item sx={{background: '#fafafa'}} overflow='scroll' mx='auto' >
                        <Box ref={area} padding={4} sx={{width: '210mm', height: '295mm'}} >

                            <Stack direction='row' spacing={2} sx={{displayPrint: 'none'}} mb={2} >
                                <FormControl fullWidth variant="standard" >
                                    <InputLabel>Select Year</InputLabel>
                                    <Select value={year} onChange={(event) => setYear(event.target.value)} >
                                        <MenuItem value='2019' >2019</MenuItem>
                                        <MenuItem value='2020' >2020</MenuItem>
                                        <MenuItem value='2021' >2021</MenuItem>
                                        <MenuItem value='2022' >2022</MenuItem>
                                        <MenuItem value='2023' >2023</MenuItem>
                                        <MenuItem value='2024' >2024</MenuItem>
                                        <MenuItem value='2025' >2025</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth variant="standard" >
                                    <InputLabel>Select Month</InputLabel>
                                    <Select value={month} onChange={(event) => setMonth(event.target.value)} >
                                        <MenuItem value='01' >January</MenuItem>
                                        <MenuItem value='02' >February</MenuItem>
                                        <MenuItem value='03' >March</MenuItem>
                                        <MenuItem value='04' >April</MenuItem>
                                        <MenuItem value='05' >May</MenuItem>
                                        <MenuItem value='06' >June</MenuItem>
                                        <MenuItem value='07' >July</MenuItem>
                                        <MenuItem value='08' >August</MenuItem>
                                        <MenuItem value='09' >September</MenuItem>
                                        <MenuItem value='10' >October</MenuItem>
                                        <MenuItem value='11' >November</MenuItem>
                                        <MenuItem value='12' >December</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>

                            <Box sx={{display: 'none', displayPrint: 'block'}} >
                                <Box>
                                    <Typography variant="h5" fontWeight='bold' sx={{color: '#ef6c00'}} >NEXUS EDUCATION & WELFARE FOUNDATION</Typography>
                                    <Typography>Gami Tola, Hariganj, Katihar</Typography>
                                    <Typography>854105</Typography>
                                </Box>
                                <Box mt={1} sx={{background: '#ef6c00'}} padding={0.2} mb={3} ></Box>
                            </Box>

                            <Box>
                                <Typography variant="body1" >Monthly collection report</Typography>
                                <Table size="small" >
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Month</TableCell>
                                            <TableCell>{new Date(2000, month-1).toLocaleString(undefined, {month: 'long'})} {year}</TableCell>

                                            <TableCell>Closing balance ({new Date(2000, month-2).toLocaleString(undefined, {month: 'long'})})</TableCell>
                                            <TableCell>{Number(closing).toLocaleString('en-in')} &#8377;</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Income</TableCell>
                                            <TableCell>{Number(income).toLocaleString('en-in')} &#8377;</TableCell>
                                            <TableCell>Expand</TableCell>
                                            <TableCell>{Number(expand).toLocaleString('en-in')} &#8377;</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{new Date(2000, month-1).toLocaleString(undefined, {month: 'long'})} balance</TableCell>
                                            <TableCell>{Number(Number(income)-Number(expand)).toLocaleString('en-in')} &#8377;</TableCell>
                                            <TableCell>Available balance</TableCell>
                                            <TableCell>{(Number(closing)+ income - expand).toLocaleString('en-in')} &#8377;</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                            <Box mt={3} >
                                <Typography variant="body1">Income</Typography>
                                <Table size="small" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" >SR. NO.</TableCell>
                                            <TableCell align="left" >Description</TableCell>
                                            <TableCell align="left" >Date</TableCell>
                                            <TableCell align="right" >Income</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bentry.map((data, i) =>
                                        Number(data.e_credit) > 0 &&
                                        <TableRow key={i} >
                                            <TableCell align="left" >{x = x+1}</TableCell>
                                            <TableCell align="left" >{data.e_description} {data.show_branch === '1' ? `(${data.b_name})` : ''}</TableCell>
                                            <TableCell align="left" >{data.e_date}</TableCell>
                                            <TableCell align="right" >{Number(data.e_credit).toLocaleString('en-in')} &#8377;</TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                </Table>
                            </Box>
                            <Box mt={3} >
                                <Typography variant="body1">Expand</Typography>
                                <Table size="small" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" >SR. NO.</TableCell>
                                            <TableCell align="left" >Description</TableCell>
                                            <TableCell align="left" >Date</TableCell>
                                            <TableCell align="right" >Expand</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bentry.map((data, i) =>
                                        Number(data.e_debit) > 0 &&
                                        <TableRow key={i} >
                                            <TableCell align="left" >{y = y+1}</TableCell>
                                            <TableCell align="left" >{data.e_description} {data.show_branch === '1' ? `(${data.b_name})` : ''}</TableCell>
                                            <TableCell align="left" >{data.e_date}</TableCell>
                                            <TableCell align="right" >{Number(data.e_debit).toLocaleString('en-in')} &#8377;</TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                </Table>
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
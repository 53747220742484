import React, { useState } from 'react'

/* HEADER */
import PagesHeader from './header';
import PagesFooter from './footer';
import { Button, Dialog, DialogContent, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TextField, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';
import { Search } from '@mui/icons-material';

export default function Student(){
    const [snack, setSnack] = useState(false)
    const [snackmessage, setSnackmessage] = useState('')
    const [openview, setOpenview] = useState(false)
    const [view, setView] = useState([])
    const [open, setOpen] = useState(false)

    const [certValue, setCertValue] = useState('')
    const registration = () => {
        var formData = new FormData()
        formData.append('verify-certificate', certValue)
        fetch('/instituteapi/public/index.php', {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setOpenview(false)
                setSnackmessage(data.warning); setSnack(true)
            } else {
                setOpenview(true)
                setView(data)
            }
        })
    }

    
    const [student, setStudent] = useState([])
    const [message, setMessage] = useState('')
    const [verified, setVerified] = useState(false)
    const [scode, setScode] = useState('')
    const verifyAd = () => {
        setVerified(false)
        var formData = new FormData()
        formData.append('verify-admission', scode)
        fetch('/instituteapi/public/index.php', {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setSnackmessage(data.warning); setSnack(true)
            } else {
                setVerified(true)
                setStudent(data)
            }
        })
    }

    return(
        <><PagesHeader />
        <div className='container-fluid mt-5 mb-5 flex'>
            <Snackbar message={snackmessage} open={snack} onClose={() => setSnack(false)} />
            <div className='row p-3'>
                <Typography align='center' variant='h4' fontWeight='bold' mb={2} mt={3} fontFamily='serif' >Verify your presence</Typography>
                <div className='col-md-6 col-12 mx-auto mt-5 mb-5'>
                    <FormControl variant='outlined' fullWidth >
                        <InputLabel >Verify Certificate</InputLabel>
                        <OutlinedInput placeholder='Enter your Registration/Enrollment number...' label='Verify Certificate' type='text' value={certValue} onChange={(event) => setCertValue(event.target.value)} endAdornment={<InputAdornment position='end' ><IconButton onClick={(event) => registration(event)} ><Search/></IconButton></InputAdornment>} />
                    </FormControl>

                    <Typography align='center' color='gray' mt={3} mb={3} >Or</Typography>
                
                    <div className='text-center'>
                        <Button color='warning' onClick={() => setOpen(true)} >Verify Admission</Button>
                    </div>

                    { openview &&
                    <div className='col-12 mt-4'>
                        <div className='col-12 text-center'>
                            <VerifiedIcon color='success' style={{fontSize: '10em'}} />
                        </div>
                        <div className='col-12 mt-4'>
                            <table className='table' >
                                <tbody>
                                    <tr><td>Name</td><td>{view.s_name}</td></tr>
                                    <tr><td>Father/Mother's name</td><td>{view.s_guardian}</td></tr>
                                    <tr><td>Enrollment</td><td>{view.s_enrollment}</td></tr>
                                    <tr><td>Issue date</td><td>{view.s_issuedate}</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div> }
                </div>
            </div>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
                <DialogContent>
                    <div className='container-fluid mt-5 mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <FormControl variant='outlined' fullWidth>
                                    <InputLabel>Enter your code</InputLabel>
                                    <OutlinedInput label='Enter your code' type='text' value={scode} onChange={(event) => setScode(event.target.value)} endAdornment={<InputAdornment position='end' ><IconButton onClick={() => verifyAd()} ><Search/></IconButton></InputAdornment>} />
                                </FormControl>
                            </div>
                            <div className='mt-4'>
                                {verified === true &&
                                <div>
                                    <div className='text-center'>
                                        <VerifiedIcon color='success' style={{fontSize: '10em'}} />
                                    </div>
                                    <div className='mt-3'>
                                        <table className='table'>
                                            <tbody>
                                                <tr><td>Name</td><td>{student.s_name}</td></tr>
                                                <tr><td>Code</td><td>{student.s_code}</td></tr>
                                                <tr><td>Guardian</td><td>{student.s_guardian}</td></tr>
                                                <tr><td>Birth</td><td>{student.s_birth}</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
        <PagesFooter /></>
    );

}
import React from "react";
import { useHistory } from 'react-router-dom'
import { useReactToPrint } from "react-to-print";
import { RWebShare } from "react-web-share";

// MUI
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import { ArrowBack, Print, Share } from '@mui/icons-material'

export default function Bar({text, printTrue, printArea, share}){
    let history = useHistory()

    const print = useReactToPrint({
        content:() => printArea.current
    })

    return(
        <AppBar position="sticky" elevation={0} >
            <Toolbar>
                <Box sx={{flexGrow: 1}} >
                    <IconButton color="inherit" edge='start' onClick={() => history.go(-1)} ><ArrowBack/></IconButton>
                </Box>
                <Typography variant="body2" noWrap >{text}</Typography>
                {printTrue === true && <IconButton color="inherit" onClick={() => print()} ><Print/></IconButton> }
                {share === true && <RWebShare data={{text: text}} ><IconButton color="inherit" ><Share/></IconButton></RWebShare> }
            </Toolbar>
        </AppBar>
    );
}
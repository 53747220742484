import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Header from './header';

/* MUI */
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import axios from 'axios';
import { Telegram } from '@mui/icons-material';

export default function DashboardStudent({api, apistudent2}){
    let history = useHistory()

    const [preview, setPreview] = useState('')
    useEffect(() => {
        var formData = new FormData()
        formData.append('dashboard-preview', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPreview(data)
            }
        })
    }, [])

    const [test, setTest] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('assigned-subject', '')
        axios.post(apistudent2, formData)
        .then(function(res){
            if(res.data.status === false){
                setTest(res.data)
            } else {
                setTest(res.data.data)
            }
        })
    }, [])

    return(
        <Box><Header api={api} />
        <div className='container mb-5' >
            <div className='row mt-3'>
                <div className='col-md-6 col-12 mx-auto'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='lightshadow p-3 rounded-3'>
                                <h3 className='fw-bold' >HELLO, {preview.s_name}</h3>
                                <small>You joined</small><br/>
                                <small>{preview.c_title} ({preview.c_name} - {preview.c_duration} Months Duration)</small>
                            </div>
                        </div>
                        { preview.issued === '1' && 
                        <div className='col-12 text-center mt-4'>
                            <div className='lightshadow p-3 rounded-3'>
                                { preview.c_title === 'TYPING' &&
                                    <Button color='success' onClick={() => history.push(`/typing-certificate/${preview.studentid}`)} >GET TYPING CERTIFICATE</Button>
                                }
                                { preview.c_title !== 'TYPING' && 
                                    <Button color='success' onClick={() => history.push(`/certificate/${preview.studentid}`)} >GET CERTIFICATE & MARKSHEET</Button> 
                                }
                            </div>
                        </div> }
                    </div>
                    { test.status !== false && 
                    <><Box className='lightshadow' padding={2} borderRadius={2} mt={3} sx={{cursor: 'pointer'}} onClick={() => history.push(`/mcq-daily/${test.exam_batch_id}`)} >
                        <Typography mb={1} >Everyday MCQ test details</Typography><Divider/>
                        <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography>Assigned subject</Typography>
                            <Typography fontWeight={'bold'} >{test.subject}</Typography>
                        </Stack>
                        <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography>Questions</Typography>
                            <Typography fontWeight={'bold'} >{test.questions}</Typography>
                        </Stack>
                        <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography>Time</Typography>
                            <Typography fontWeight={'bold'} >{test.timer} Minutes</Typography>
                        </Stack>
                    </Box> 
                    <Box className='lightshadow' padding={2} borderRadius={2} mt={3} sx={{cursor: 'pointer'}} onClick={() => history.push(`/test-report/${preview.studentid}`)} >
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                            <Typography color={'info.main'} >Check your answer</Typography>
                            <IconButton color='info' ><Telegram /></IconButton>
                        </Stack>
                    </Box></> }
                    <div className='row mt-4'>
                        <div className='col-md-4 col-6 mb-4'>
                            <div className='lightshadow p-3 rounded-3 text-center flex text-break' style={{height: '150px'}} >
                                <h6 className='text-muted' >YOUR CODE</h6>
                                <h3 className='fw-bold'>{preview.s_code}</h3>
                            </div>
                        </div>
                        <div className='col-md-4 col-6 mb-4'>
                            <div className='lightshadow p-3 rounded-3 text-center flex text-break pointer' style={{height: '150px'}} onClick={() => history.push('/scanner-student')} >
                                <h6 className='text-muted' >MAKE ATTENDANCE</h6>
                                <div className='text-center'>
                                    <QrCodeScannerIcon fontSize='large' />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mb-4'>
                            <div className='lightshadow p-3 rounded-3 text-center flex text-break' style={{height: '150px'}} >
                                <h6 className='text-muted' >ATTEND</h6>
                                <h3 className='fw-bold'>{preview.attend} DAYS</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6 mb-4'>
                            <div className='lightshadow p-3 rounded-3 text-center flex text-dark' style={{height: '150px'}}>
                                <h6 className='text-muted'>PAYMENT PAYED</h6>
                                <h3 className='fw-bold' >{(Number(preview.payed)).toLocaleString('en-in')} &#8377;</h3>
                            </div>
                        </div>
                        <div className='col-6 mb-4'>
                            <div className='lightshadow p-3 rounded-3 text-center flex text-dark' style={{height: '150px'}}>
                                <h6 className='text-muted'>DUES</h6>
                                <h3 className='fw-bold' >{(Number(preview.fees) - Number(preview.payed)).toLocaleString('en-in')} &#8377;</h3>
                            </div>
                        </div>
                    </div>
                    
                    <div className='row mt-4'>
                        <div className='col-md-4 col-6 mb-4'>
                            <div className='lightshadow p-3 rounded-3 text-center flex text-break pointer' style={{height: '150px'}} onClick={() => history.push('/attendance-history-student')} >
                                <h5 className='fw-bold'>ATTENDANCE HISTORY</h5>
                            </div>
                        </div>
                        <div className='col-md-4 col-6 mb-4'>
                            <div className='lightshadow p-3 rounded-3 text-center flex text-break pointer' style={{height: '150px'}} onClick={() => history.push('/payment-history-student')} >
                                <h5 className='fw-bold' >PAYMENT HISTORY</h5>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='lightshadow p-3 rounded-3 text-center flex text-break pointer' style={{height: '150px'}} onClick={() => history.push('/profile-student')} >
                                <h5 className='fw-bold'>PROFILE</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Box>
    );

}
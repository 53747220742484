import React, { useEffect, useState } from 'react'
import AOS from 'aos'

/* HEADER */
import PagesHeader from './header';
import PagesFooter from './footer.jsx'

/* MATERIAL UI */
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, ListItem, ListItemIcon, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material'
import WifiIcon from '@mui/icons-material/Wifi';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LayersIcon from '@mui/icons-material/Layers';
import VerifiedIcon from '@mui/icons-material/Verified';
import CameraOutdoorIcon from '@mui/icons-material/CameraOutdoor';
import CameraIndoorIcon from '@mui/icons-material/CameraIndoor';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export default function Home(){
    const api = '/instituteapi/public/index.php'

    useEffect(() => {
        AOS.init({
            duration: 1500
        })
    }, [])

    const [offers, setOffers] = useState([])
    const [open, setOpen] = useState(false)

    useEffect(() => {
        var formData = new FormData()
        formData.append('offers', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setOpen(true); setOffers(data)
            }
        })
    }, [])

    const [courses, setCourses] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('courses-new', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setCourses(data)
            }
        })
    }, [])

    const [braches, setBranches] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('our-branch', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBranches(data)
            }
        })
    }, [])

    const [name, setName] = useState('')
    const [guardian, setGuardian] = useState('')
    const [mobile, setMobile] = useState('')
    const [gender, setGender] = useState('')
    const [known, setKnows] = useState('')
    const [course, setCourse] = useState('')
    const [admission, setAdmission] = useState('')
    const [branch, setBranch] = useState('')
    const [sendWarn, setSendWarn] = useState('')
    const [sending, setSending] = useState(false)

    const sendQuery = () => {
        setSendWarn(''); setSending(true)
        var formData = new FormData()
        formData.append('send-enquiry', branch)
        formData.append('name', name); formData.append('guardian', guardian); formData.append('mobile', mobile); formData.append('gender', gender);
        formData.append('known', known); formData.append('course', course); formData.append('admission', admission)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setSending(false)
            if(data.warning){
                setSendWarn(data.warning)
            } else if(data.success){
                setName(''); setGuardian(''); setMobile(''); setGender(''); setKnows(''); setCourse(''); setAdmission(''); setBranch('')
                setSendWarn('Yehh! Your query sended. We will back to you as soon as possible.')
                setQueryDialog(false)
            }
        })
    }

    const [queryDialog, setQueryDialog] = useState(false)

    return(
        <>
        <PagesHeader />
        <Dialog open={open} onClose={() => setOpen(false)} scroll='paper' PaperProps={{style: {backgroundColor: 'transparent', boxShadow: 'none'}}} >
            <DialogContent className='p-1' >
                <div className='container-fluid mt-4 mb-4'>
                    <div className='row'>
                        { offers.map((off, i) =>
                        <div className='col-12 mb-2' key={i} >
                            <div className='rounded-3 p-3' style={{background: '#dee2e6'}} >
                                <small className='text-muted' >{off.of_date}</small>
                                <h3 className='fw-bold'>{off.of_head}</h3>
                                <p className='mt-3' >{off.of_offer}</p>
                            </div>
                        </div> ) }
                    </div>
                </div>
            </DialogContent>
        </Dialog>
        
        <Box className='landing-bg' sx={{backgroundImage: "url('/img/Landing-bg.webp')"}} >
            <Container>
                <Grid container pt={5} direction={{xs: 'column-reverse', md: 'row'}} justifyContent='flex-start' alignItems='center' spacing={3} >
                    <Grid item md={6} >
                        <Box>
                            <Typography variant='h3' fontWeight='bold' >Nexus Computer</Typography>
                            <Typography variant='body1' color='GrayText' mt={1} mb={3} >Solution of Your IT Education!</Typography>

                            <Button color='primary' variant='contained' sx={{boxShadow: 0, borderRadius: 1}} >Join Now</Button>
                        </Box>
                    </Grid>
                    <Grid item md={6} textAlign='center' >
                        <img src='/img/landing-boy.png' alt='Landing boy' style={{width: '100%'}} />
                    </Grid>
                </Grid>
            </Container>
        </Box>

        <Container>
            <Grid container sx={{backgroundImage: 'linear-gradient(120deg, #adb5bd, #dee2e6)', borderRadius: 3, padding: 2}} >
                <Grid item margin='auto' >
                    <Typography align='center' variant='h6' fontWeight='bold' >Hey! We are waiting for you...</Typography>
            
                    <Box textAlign='center' mt={2} >
                        <Button variant='outlined' size='large' color='inherit' onClick={() => setQueryDialog(true)} >Send your query</Button>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={queryDialog} fullWidth={true} maxWidth='xs' onClose={() => setQueryDialog(false)} >
                <DialogTitle>We are waiting for you</DialogTitle><Divider/>
                <DialogContent>
                    <TextField fullWidth variant='standard' type='text' label='Your name' value={name} onChange={(event) => setName(event.target.value)} />
                    <TextField fullWidth variant='standard' type='text' label='Guardian name' value={guardian} onChange={(event) => setGuardian(event.target.value)} />
                    <TextField fullWidth variant='standard' type='number' label='Mobile number' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                    <FormControl fullWidth variant='standard' >
                        <InputLabel>Gender</InputLabel>
                        <Select value={gender} onChange={(event) => setGender(event.target.value)} >
                            <MenuItem value='MALE' >Male</MenuItem>
                            <MenuItem value='FEMALE' >Female</MenuItem>
                            <MenuItem value='OTHERS' >Others</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant='standard' >
                        <InputLabel>Course you like</InputLabel>
                        <Select value={course} onChange={(event) => setCourse(event.target.value)} >
                        { courses.map((cour, i) =>
                            <MenuItem value={cour.c_title+' - '+cour.c_duration+' MONTHS '+'('+cour.c_name+')'} key={i} >{cour.c_title} - {cour.c_duration} Months ({cour.c_name})</MenuItem> ) }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant='standard' >
                        <InputLabel>Where you want to join</InputLabel>
                        <Select value={branch} onChange={(event) => setBranch(event.target.value)} >
                            { braches.map((bran, i) =>
                            <MenuItem value={bran.branchid} key={i} >{bran.b_name}</MenuItem> ) }
                        </Select>
                    </FormControl>
                    <TextField fullWidth variant='standard' type='date' helperText='When you want to join' className='mt-3' value={admission} onChange={(event) => setAdmission(event.target.value)} />
                    <FormControl fullWidth variant='standard' >
                        <InputLabel>How you know about us</InputLabel>
                        <Select value={known} onChange={(event) => setKnows(event.target.value)} >
                            <MenuItem value='BANNER' >Banner</MenuItem>
                            <MenuItem value='FRIEND' >Friend</MenuItem>
                            <MenuItem value='POSTER' >Poster</MenuItem>
                            <MenuItem value='HOARDINGS' >Hoardings</MenuItem>
                            <MenuItem value='NEWS' >News paper</MenuItem>
                            <MenuItem value='OTHERS' >Others</MenuItem>
                        </Select>
                    </FormControl>
                    {sendWarn && <Alert severity='warning' className='mt-2' >{sendWarn}</Alert>}
                </DialogContent><Divider/>
                <DialogActions>
                    <Button onClick={() => setQueryDialog(false)} >CANCEL</Button>
                    <Button variant='contained' color='primary' disabled={sending} onClick={() => sendQuery()} >{sending ? <CircularProgress color='inherit' size={24} /> : 'SEND'}</Button>
                </DialogActions>
            </Dialog>
        </Container>

        <div className='container-fluid mb-5 mt-5'>
            <div className='row p-3 mt-5'>
                <div className='col-12 text-center'>
                    <h2><span className='colorOrange'>---</span> FACILITIES <span className='colorOrange'>---</span></h2>
                </div>

                <div className='col-md-10 col-11 mx-auto mt-4'>
                    <div className='row'>
                        <div className='col-md-3 mb-4'>
                            <div className='p-3 bgGrey' data-aos="zoom-in">
                                <ListItem>
                                    <ListItemIcon><WifiIcon style={{color: '#ff4081'}} fontSize='large'/></ListItemIcon>
                                    <ListItemText>Free WIFI</ListItemText>
                                </ListItem>
                            </div>
                        </div>
                        <div className='col-md-3 mb-4'>
                            <div className='p-3 bgGrey' data-aos="zoom-in">
                                <ListItem>
                                    <ListItemIcon><VideoLabelIcon style={{color: '#e040fb'}} fontSize='large'/></ListItemIcon>
                                    <ListItemText>Computer Lab</ListItemText>
                                </ListItem>
                            </div>
                        </div>
                        <div className='col-md-3 mb-4'>
                            <div className='p-3 bgGrey' data-aos="zoom-in">
                                <ListItem>
                                    <ListItemIcon><AcUnitIcon style={{color: '#7c4dff'}} fontSize='large'/></ListItemIcon>
                                    <ListItemText>AC Classrooms</ListItemText>
                                </ListItem>
                            </div>
                        </div>
                        <div className='col-md-3 mb-4'>
                            <div className='p-3 bgGrey text-truncate' data-aos="zoom-in">
                                <ListItem>
                                    <ListItemIcon><LayersIcon style={{color: '#40c4ff'}} fontSize='large'/></ListItemIcon>
                                    <ListItemText>Arranged Classrooms</ListItemText>
                                </ListItem>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3 mb-4'>
                            <div className='p-3 bgGrey' data-aos="zoom-in">
                                <ListItem>
                                    <ListItemIcon><VerifiedIcon style={{color: '#84ffff'}} fontSize='large'/></ListItemIcon>
                                    <ListItemText>Certificate Courses</ListItemText>
                                </ListItem>
                            </div>
                        </div>
                        <div className='col-md-3 mb-4'>
                            <div className='p-3 bgGrey' data-aos="zoom-in">
                                <ListItem>
                                    <ListItemIcon><CameraOutdoorIcon style={{color: '#64ffda'}} fontSize='large'/></ListItemIcon>
                                    <ListItemText>Seminar Hall</ListItemText>
                                </ListItem>
                            </div>
                        </div>
                        <div className='col-md-3 mb-4'>
                            <div className='p-3 bgGrey' data-aos="zoom-in">
                                <ListItem>
                                    <ListItemIcon><QrCodeScannerIcon style={{color: '#69f0ae'}} fontSize='large'/></ListItemIcon>
                                    <ListItemText>Virtual Attendance</ListItemText>
                                </ListItem>
                            </div>
                        </div>
                        <div className='col-md-3 mb-4'>
                            <div className='p-3 bgGrey' data-aos="zoom-in">
                                <ListItem>
                                    <ListItemIcon><CameraIndoorIcon style={{color: '#ffff00'}} fontSize='large'/></ListItemIcon>
                                    <ListItemText>CCTV Surveillance</ListItemText>
                                </ListItem>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row p-3 mt-5'>
                <div className='col-12 text-center'>
                    <h2><span className='colorOrange'>---</span> COURSES <span className='colorOrange'>---</span></h2>
                </div>

                <div className='col-md-10 col-11 mx-auto mt-4'>
                    <div className='row'>
                        <div className='col-md-4 col-12 mb-5'>
                            <div className='p-4 rounded-3 lightshadow' data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration='500'>
                                <IconButton className='bgOrange text-light' ><LocalLibraryIcon style={{fontSize: '2em'}} /></IconButton>
                                <h5 className='mt-4 fw-bold'>ADCA</h5>
                                Advanced Diploma In Computer <br/>Application
                            </div>
                        </div>
                        <div className='col-md-4 col-12 mb-5'>
                            <div className='p-4 rounded-3 lightshadow' data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration='700'>
                                <IconButton className='bgOrange text-light' ><LocalLibraryIcon style={{fontSize: '2em'}} /></IconButton>
                                <h5 className='mt-4 fw-bold'>DCA</h5>
                                Diploma In Computer <br/>Application
                            </div>
                        </div>
                        <div className='col-md-4 col-12 mb-5'>
                            <div className='p-4 rounded-3 lightshadow' data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration='900'>
                                <IconButton className='bgOrange text-light' ><LocalLibraryIcon style={{fontSize: '2em'}} /></IconButton>
                                <h5 className='mt-4 fw-bold'>ADOA</h5>
                                Advanced Diploma In Office <br/>Automation
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 col-12 mb-5'>
                            <div className='p-4 rounded-3 lightshadow' data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration='1100'>
                                <IconButton className='bgOrange text-light' ><LocalLibraryIcon style={{fontSize: '2em'}} /></IconButton>
                                <h5 className='mt-4 fw-bold'>CFA/Tally</h5>
                                Certificate in Financial <br/>Accounting
                            </div>
                        </div>
                        <div className='col-md-4 col-12 mb-5'>
                            <div className='p-4 rounded-3 lightshadow' data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration='1300'>
                                <IconButton className='bgOrange text-light' ><LocalLibraryIcon style={{fontSize: '2em'}} /></IconButton>
                                <h5 className='mt-4 fw-bold'>PGDCA</h5>
                                Post Graduate Diploma in Computer <br/>Application
                            </div>
                        </div>
                        <div className='col-md-4 col-12 mb-5'>
                            <div className='p-4 rounded-3 lightshadow' data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration='1300'>
                                <IconButton className='bgOrange text-light' ><LocalLibraryIcon style={{fontSize: '2em'}} /></IconButton>
                                <h5 className='mt-4 fw-bold'>Typing</h5>
                                Hindi & English <br/>Typing
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='row p-3 mt-5'>
                <div className='col-md-10 col-11 mx-auto'>
                    <div className='row'>
                        <div className='col-md-4 col-12 text-center mb-4'>
                            <Accordion>
                                <AccordionSummary className='bgOrange text-light p-3 rounded-3'>
                                    <Typography>About NEXUS</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography style={{textAlign: 'justify'}}>
                                        NEXUS COMPUTER at Katihar in Bihar is well organized computer institute. The objective of the institute is to provide a wide veriety of Job Oriented courses .Skill development Courses, Computer education short term and long term courses, which is designed by our expert academicians after careful market study and research. We offers Franchise Services. We are committed to provide best IT education.
                                        We provides the job oriented study material and training to our students so that they could learn and earn consecutively. As we as IT education provider that now a days computer has become important for every organization. Nexus Computer is trying to provide computer education so that student can explore the IT world and could make their future in IT sector.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className='col-md-4 col-12 text-center mb-4'>
                            <Accordion>
                                <AccordionSummary className='bgOrange text-light p-3 rounded-3'>
                                    <Typography>NEXUS Vision</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography style={{textAlign: 'justify'}}>
                                    Our Mission is to make enable each and every one to know the computer work. In order to achieve our mission our IT team is regularly working and arranging seminars time to time at Katihar in Bihar. The purpose our team of Nexus Computer at Katihar in Bihar, behind these activities is to let the youngsters know that computer learning is for every age group and for everyone. We are also trying to break the myth that computer is for those who have good background in education.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className='col-md-4 col-12 text-center mb-4'>
                            <Accordion>
                                <AccordionSummary className='bgOrange text-light p-3 rounded-3'>
                                    <Typography>NEXUS Mission</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography style={{textAlign: 'justify'}}>
                                    Our Mission is to make enable each and every one to know the computer work. In order to achieve our mission our IT team is regularly working and arranging seminars time to time at Katihar in Bihar. The purpose our team of Nexus Computer at Katihar in Bihar, behind these activities is to let the youngsters know that computer learning is for every age group and for everyone. We are also trying to break the myth that computer is for those who have good background in education.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row p-3 mt-5'>
                <div className='col-12 text-center'>
                    <h2><span className='colorOrange'>---</span> CONTACT US <span className='colorOrange'>---</span></h2>
                </div>

                <div className='col-md-10 col-11 mx-auto mt-4'>
                    <div className='row'>
                        <div className='col-md-3 col-12'>
                            <div className='p-4 rounded-3 bg-light mb-4'>
                                <CallIcon className='colorOrange' style={{fontSize: '3em'}} />
                                <h5 className='fw-bold mt-2'>Call Us</h5>
                                <a rel='next' className='text-dark' href="tel: 9431668289" >9431668289</a>
                            </div>
                        </div>
                        <div className='col-md-3 col-12 mb-4'>
                            <div className='p-4 rounded-3 bg-light text-truncate'>
                                <MailOutlineIcon className='colorOrange' style={{fontSize: '3em'}} />
                                <h5 className='fw-bold mt-2'>Mail Us</h5>
                                <a rel='next' className='text-dark' href="mailto: nexuscomputerkatihar@gmail.com" >nexuscomputerkatihar@gmail.com</a>
                            </div>
                        </div>
                        <div className='col-md-3 col-12 mb-4'>
                            <div className='p-4 rounded-3 bg-light text-truncate'>
                                <LocationOnIcon className='colorOrange' style={{fontSize: '3em'}} />
                                <h5 className='fw-bold mt-2'>Locate Us</h5>
                                <a rel='noreferrer' className='text-dark' href='https://goo.gl/maps/4pur6i7mFHojKdGw6' target='_blank'>Gami Tola, Hariganj(Near Chammar Kalimandir)</a>
                            </div>
                        </div>
                        <div className='col-md-3 col-12 mb-4'>
                            <div className='p-4 rounded-3 bg-light'>
                                <AccessTimeIcon className='colorOrange' style={{fontSize: '3em'}} />
                                <h5 className='fw-bold mt-2'>Open Hours</h5>
                                7am - 7pm/ Mon - Sat
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PagesFooter />
        </>
    );
}
import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom';

import Logo from '../images/logo.png'

/* MATERIAL UI */
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Popover, Stack, Toolbar, Typography } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import CollectionsIcon from '@mui/icons-material/Collections';
import GroupIcon from '@mui/icons-material/Group';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LoginIcon from '@mui/icons-material/Login';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Menu } from '@mui/icons-material';


export default function PagesHeader(){

    const [anchorEl, setAnchorEl] = useState(null)
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const links = [
        {name: 'Home', link: '/', icon: <HomeIcon/>},
        {name: 'Gallery', link: '/gallery', icon: <CollectionsIcon/>},
        {name: 'Student', link: '/student', icon: <GroupIcon />},
        {name: 'Franchise', link: '/get-franchise', icon: <OtherHousesIcon/>},
        {name: 'Documents', link: '/documents', icon: <LibraryBooksIcon />},
        {name: 'Courses', link: '/courses', icon: <InsertDriveFileIcon/>}]

    const [linkDrawer, setLinkDrawer] = useState(false)

    return(
        <>
            <AppBar color='inherit' position='sticky' sx={{boxShadow: 1}} >
                <Toolbar>
                    <Box sx={{flexGrow: 1}} >
                        <Link to='/'> <img src={Logo} alt='Nexus Computer Logo' style={{width: 100}} /> </Link>
                    </Box>
                    <Box display={{xs: 'none', md: 'block'}} >
                        <Stack direction='row' justifyContent='right' spacing={2} >
                            { links.map((data, i) =>
                            <NavLink key={i} to={data.link} style={{color: '#000000'}} >{data.name}</NavLink>) }
                            <Typography onClick={(event) => openMenu(event)} sx={{cursor: 'pointer'}} >Login</Typography>
                        </Stack>
                    </Box>
                    <Stack direction='row' alignItems='center' display={{xs: 'block', md: 'none'}} >
                        <IconButton color='inherit' onClick={() => setLinkDrawer(true)} ><Menu /></IconButton>
                    </Stack>
                </Toolbar>
            </AppBar>
            
            <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} >
                <List>
                    <Link to='/login-student' style={{color: '#000000'}} ><ListItem>
                        <ListItemText>Student Login</ListItemText>
                    </ListItem></Link>
                    <Link to='/login-teacher' style={{color: '#000000'}} ><ListItem>
                        <ListItemText>Teacher Login</ListItemText>
                    </ListItem></Link>
                    <Link to='/login-branch' style={{color: '#000000'}} ><ListItem>
                        <ListItemText>Franchise Login</ListItemText>
                    </ListItem></Link>
                    <Link to='/login-admin' style={{color: '#000000'}} ><ListItem>
                        <ListItemText>Admin Login</ListItemText>
                    </ListItem></Link>
                </List>
            </Popover>
            <Drawer open={linkDrawer} anchor='bottom' onClose={() => setLinkDrawer(false)} PaperProps={{style: {borderTopRightRadius: 20, borderTopLeftRadius: 20}}} >
                <List>
                    { links.map((data, i) =>
                    <NavLink to={data.link} key={i} style={{color: '#000000'}} >
                        <ListItem>
                            <ListItemIcon>{data.icon}</ListItemIcon>
                            <ListItemText>{data.name}</ListItemText>
                        </ListItem>
                    </NavLink>) }
                    <ListItem onClick={(event) => openMenu(event)} >
                        <ListItemIcon><LoginIcon/></ListItemIcon>
                        <ListItemText>Login</ListItemText>
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
}
import React, { useEffect, useState } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import SidebarAdmin from './sidebar'
import HeaderAdmin from './header'
import Close from './../close'

/* MUI */
import { Alert, Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VerifiedIcon from '@mui/icons-material/Verified';
import EditIcon from '@mui/icons-material/Edit';
import { History } from '@mui/icons-material'

export default function StudentAdmin({api, bimg}){
    let history = useHistory()
    const { path, url } = useRouteMatch()

    useEffect(() => {
        viewstudents('', branchid)
        var formData = new FormData()
        formData.append('view-students', '')
    }, [''])

    const [students, setStudents] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)
    const viewstudents = (query, branchid) => {
        setWarning('')
        var formData = new FormData()
        formData.append('view-students', query)
        formData.append('branchid', branchid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setStudents(data)
            }
        })
    }

    const searchstudent = (event) => {
        if(event.keyCode === 13){
            viewstudents(event.target.value, branchid)
        }
    }

    const [menustudentid, setMenustudentid] = useState('')
    const [anchorEl, setAnchorEl] = useState('')
    const [type, setType] = useState('')
    const openmenu = (event, studentid, type) => {
        setMenustudentid(studentid)
        setType(type)
        setAnchorEl(event.currentTarget)
    }

    const [branchid, setBranchid] = useState('')
    const [branches, setBranches] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-branches', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBranches(data)
            }
        })
    }, [])

    const filterbranch = (branchid) => {
        setBranchid(branchid)
        viewstudents('', branchid)
    }

    return(
        <><SidebarAdmin />
        <div className='content'>
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-9 p-0'>
                    <TextField fullWidth variant='standard' label='Search student' placeholder='Search by name, code, Reg. no....' onKeyUp={(event) => searchstudent(event)} />
                </div>
                <div className='col-3 p-0'>
                    <FormControl fullWidth variant='standard' >
                        <InputLabel>Filter branch</InputLabel>
                        <Select value={branchid} onChange={(event) => filterbranch(event.target.value)} >
                            {branches.map((b, i) =>
                            <MenuItem key={i} value={b.branchid} >{b.b_name}</MenuItem> )}
                        </Select>
                    </FormControl>
                </div>
                <div className='col-md-6 col-12 mx-auto mt-4'>
                    
                    { loading ? <LinearProgress color='warning' /> :
                    warning ? <Alert severity='warning'>No student added</Alert> :
                    students.map((stu, i) =>
                    <div key={i} className={`row mb-2 rounded-3 overflow-hidden ${stu.s_enrollment ? 'bggreen' : 'bggrey' } `}>
                        <div className='col-md-9 col-8 p-3 position-relative'>
                            <h5 className='fw-bold text-truncate' >{stu.s_name}</h5>
                            <div style={{fontSize: '14px'}} className='mb-4' >
                                <p className='m-0 text-muted' >Code: {stu.s_code}</p>
                                <p className='text-truncate m-0 text-muted' >Course: {stu.c_title} ({stu.c_name})</p>
                                <p className='text-truncate m-0 text-muted'>Duration: {stu.c_duration} Months</p>
                                <p className='text-truncate m-0 text-muted' >Branch: {stu.b_name}</p>
                                <a href={`tel: ${stu.s_mobile}`}>Call: {stu.s_mobile}</a>
                            </div>
                            <div className='position-absolute bottom-0'>
                                <IconButton onClick={() => history.push(`${url}/details/${stu.studentid}`)} ><VisibilityIcon /></IconButton>
                                <IconButton onClick={(event) => openmenu(event, stu.studentid, stu.c_title)} ><VerifiedIcon /></IconButton>
                                <IconButton onClick={() => history.push(`${url}/issue/${stu.studentid}/${stu.b_code}`)} ><EditIcon /></IconButton>
                                <IconButton onClick={() => history.push(`/student-history/${stu.studentid}`)}><History/></IconButton>
                            </div>
                        </div>
                        <div className='col-md-3 col-4 border p-0'>
                            <Avatar src={`/instituteassets/studentimg/${stu.s_pic}`} style={{height: '100%', width: '100%', borderRadius: '0', objectFit: 'cover'}} />
                        </div>
                    </div> )}
                    <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl} >
                        {type === 'TYPING' ? 
                        <><MenuItem onClick={() => history.push(`/typing-certificate/${menustudentid}`)} >Typing Certificate</MenuItem>
                        <MenuItem onClick={() => history.push(`/form/${menustudentid}`)} >Admission Form</MenuItem></> :
                        <>
                        <MenuItem onClick={() => history.push(`/certificate/${menustudentid}`)} >Computer Certificate</MenuItem>
                        <MenuItem onClick={() => history.push(`/marksheet/${menustudentid}`)} >Marksheet</MenuItem>
                        <MenuItem onClick={() => history.push(`/form/${menustudentid}`)} >Admission Form</MenuItem>
                        </>}
                    </Menu>
                    
                </div>
            </div>
        </div>
        </div>
        <Route path={`${path}/issue/:studentid/:bCode`} component={Issue} />
        <Route path={`${path}/details/:studentid`} component={StudentDetail} />
        </>
    );

    function Issue(){
        const { studentid } = useParams()
        const { bCode } = useParams()
        const [open, setOpen] = useState(false)
        const [warning, setWarning] = useState('')

        const [detail, setDetail] = useState([])
        const [enrollment, setEnrollment] = useState('')
        const [issuedate, setIssuedate] = useState('')
        const [register, setRegister] = useState('')
        const [issuing, setIssuing] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('view-student-detail', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setDetail(data)
                    setEnrollment(data.s_enrollment); setIssuedate(data.s_issuedate); setRegister(data.s_register);
                }
            })
        }, [studentid])

        const callIssue = () => {
            if(register == '' || issuedate == ''){
                issue()
            } else {
                if(detail.s_joindate < register){
                    if(issuedate > register){
                        issue()
                    } else {
                        setWarning('Issue date should be greater then registration date.'); setOpen(true)
                    }
                } else {
                    setWarning('Registration date should be greater then admission date.'); setOpen(true)
                }
            }
        }

        const issue = () => {
            setIssuing(true)
            var formData = new FormData()
            formData.append('issue-certificate', studentid)
            formData.append('enrollment', enrollment)
            formData.append('issuedate', issuedate)
            formData.append('register', register)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setIssuing(false)
                if(data.warning){
                    setWarning(data.warning); setOpen(true)
                } else {
                    setWarning('Issue succeed.'); setOpen(true)
                }
            })
        }

        const [sigworking, setSigworking] = useState(false)
        const issue_signature = () => {
            setSigworking(true)
            setWarning('')
            var formData = new FormData()
            formData.append('issue-cert-signature', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setSigworking(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setWarning('Issue succeed. Please refresh page.'); setOpen(true)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' onClose={() => history.go(-1)}>
                <DialogTitle>Issue student</DialogTitle><Divider/>
                <DialogContent>
                    <Snackbar open={open} message={warning} onClose={() => setOpen(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}} />
                    <List sx={{background: '#fafafa'}} className='rounded-3' >
                        <ListItem>
                            <ListItemText>Name</ListItemText>
                            <ListItemIcon>{detail.s_name}</ListItemIcon>
                        </ListItem><Divider/>
                        <ListItem>
                            <ListItemText>Father/Mother's name:</ListItemText>
                            <ListItemIcon>{detail.s_guardian}</ListItemIcon>
                        </ListItem><Divider/>
                        <ListItem>
                            <ListItemText>Code</ListItemText>
                            <ListItemIcon>{detail.s_code}</ListItemIcon>
                        </ListItem><Divider/>
                        <ListItem>
                            <ListItemText>Admission date</ListItemText>
                            <ListItemIcon>{detail.s_joindate}</ListItemIcon>
                        </ListItem>
                    </List>
                    <Box sx={{background: '#fafafa'}} className='rounded-3' p={2} mt={1} >
                        <TextField fullWidth type='text' variant='standard' label='Registration/Enrollment number' value={enrollment === '' ? bCode : enrollment} onChange={(event) => setEnrollment(event.target.value)} />
                        <TextField fullWidth type='date' variant='standard' helperText='Registration date' className='mt-3' value={register} onChange={(event) => setRegister(event.target.value)} />
                        <TextField fullWidth type='date' variant='standard' helperText='Date of issue' className='mt-2' value={issuedate} onChange={(event) => setIssuedate(event.target.value)} />
                    </Box>

                    <Box sx={{background: '#fafafa'}} borderRadius={1.5} p={2} mt={1} textAlign='center' >
                        {detail.s_center_sig === null ? <Typography mb={2} fontWeight='bold' color='orange' >No signature issued yet</Typography> :
                        <img src={bimg+detail.s_center_sig} alt='Issued signature' style={{height: 80}} />
                        }
                        <Button variant='outlined' color='primary' onClick={() => issue_signature()} endIcon={sigworking && <CircularProgress color='inherit' size={24} /> } disabled={sigworking} >{detail.s_center_sig === null ? 'Issue signature' : 'Update signature'}</Button>
                    </Box>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button color='inherit' type='text' onClick={() => history.go(-1)} >Close</Button>
                    <Button variant='contained' color='warning' disabled={issuing} onClick={() => callIssue()} >{issuing ? <CircularProgress color='inherit' size={24} /> : 'Issue' }</Button>
                </DialogActions>
            </Dialog>
        );
    }

    function StudentDetail(){
        const { studentid } = useParams()

        const [student, setStudent] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-student-detail', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setStudent(data)
                }
            })
        }, [studentid])

        const [qualifications, setQualifications] = useState([])
        const [warning, setWarning] = useState('')
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-student-qualification', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setQualifications(data)
                }
            })
        }, [studentid])

        return(
            <Dialog open={true} fullScreen onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Student details' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-md-6 col-12 mx-auto'>
                                <div className='row'>
                                    <div className='col-12 text-center'>
                                        <Avatar src={`/instituteassets/studentimg/${student.s_pic}`} className='globalimgsize mx-auto' />
                                    </div>
                                    <div className='col-12 mt-4'>
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>Student code</TableCell>
                                                        <TableCell >{student.s_code}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Course</TableCell>
                                                        <TableCell >{student.c_title} ({student.c_name})</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Joined on</TableCell>
                                                        <TableCell >{student.s_joindate}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell >{student.s_name}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Father/Mother's name</TableCell>
                                                        <TableCell >{student.s_guardian}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Address</TableCell>
                                                        <TableCell >{student.s_address}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Post office</TableCell>
                                                        <TableCell >{student.s_po}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Police station</TableCell>
                                                        <TableCell >{student.s_ps}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>City</TableCell>
                                                        <TableCell >{student.s_city}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>District</TableCell>
                                                        <TableCell >{student.s_dist}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>State</TableCell>
                                                        <TableCell >{student.s_state}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Postel</TableCell>
                                                        <TableCell >{student.s_postel}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Aadhaar number</TableCell>
                                                        <TableCell >{student.s_aadhaar}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Mobile</TableCell>
                                                        <TableCell >{student.s_mobile}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Birth</TableCell>
                                                        <TableCell >{student.s_birth}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Gender</TableCell>
                                                        <TableCell >{student.s_gender}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Nation</TableCell>
                                                        <TableCell >{student.s_nation}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <TableContainer className='mt-4' >
                                            {warning ? <Alert severity='warning'>{warning}</Alert> :
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Board</TableCell>
                                                        <TableCell align='center' >Examnination</TableCell>
                                                        <TableCell align='center' >Passed year</TableCell>
                                                        <TableCell align='right' >Obtained marks</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {qualifications.map((qua,i) =>
                                                    <TableRow key={i} >
                                                        <TableCell>{qua.board}</TableCell>
                                                        <TableCell align='center' >{qua.exam}</TableCell>
                                                        <TableCell align='center' >{qua.pass}</TableCell>
                                                        <TableCell align='right' >{qua.marks}</TableCell>
                                                    </TableRow> )}
                                                </TableBody>
                                            </Table>}
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}
import React, { useEffect, useState, ChangeEvent } from "react";
import { useHistory, useParams } from 'react-router-dom'
import Bar from '../bar'

// MUI
import { Alert, Box, Button, Card, CardContent, CircularProgress, Container, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function Mcq_student({apistudent2}){
    let history = useHistory();
    const { exambatchid } = useParams()
    const [action, setAction] = useState('')

    const [exam, setExam] = useState([])
    const [question, setQuestion] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('generated-questions', exambatchid)
        axios.post(apistudent2, formData)
        .then(function(res){
            if(res.data.questions.status === false){
                setQuestion(res.data.questions)
            } else {
                setQuestion(res.data.questions.data)
            }
            if(res.data.exam.status === false){
                setExam(res.data.exam)
            } else {
                setExam(res.data.exam.data)
            }
        })
    }, [exambatchid])

    const update_answer = (qid, ans) => {
        const updatedQuestions = question.map((row) =>
            row.questionid === qid ? { ...row, answer: ans } : row
        );
        setQuestion(updatedQuestions)
    }

    const submit_answers = () => {
        if(window.confirm('Do you want to submit your answer?') === true){
            setAction('submitting')
            var formData = new FormData()
            formData.append('submit-test-answer', JSON.stringify(question))
            formData.append('timer', exam.timer);
            formData.append('exambatchid', exambatchid)
            axios.post(apistudent2, formData)
            .then(function(res){
                setAction('')
                if(res.data.status === false){
                    alert(res.data.msg)
                } else {
                    alert(res.data.msg)
                    history.go(-1)
                }
            })
        }
    }

    return(
        <Box mb={5} ><Bar text='Regular test series' />
            <Container>
                <Grid container mt={3} >
                    <Grid item xs={12} md={7} mx={'auto'} >
                        <Card sx={{mb: 2}} >
                            <CardContent>
                                <Box mb={3}>
                                    <Typography mb={1} fontWeight={'bold'} >MCQ test details</Typography><Divider/>
                                    <Stack direction={'row'} justifyContent={'space-between'} mt={1} >
                                        <Typography>Subject</Typography>
                                        <Typography fontWeight={'bold'} >{exam.subject}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} mt={1} >
                                        <Typography>Questions</Typography>
                                        <Typography fontWeight={'bold'} >{exam.questions}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} mt={1} >
                                        <Typography>Time scheduled</Typography>
                                        <Typography fontWeight={'bold'} color={'red'} >{exam.timer} Minutes</Typography>
                                    </Stack>
                                </Box> <Divider/>
                                <Box mt={3} >
                                    <Typography mb={1} fontWeight={'bold'} >Exam details</Typography><Divider/>
                                    <Typography fontSize={14} color={'gray'}>1. Make sure questions are clear, with straightforward answer choices to avoid confusion.</Typography>
                                    <Typography fontSize={14} color={'gray'} >2. Mix up question difficulty and cover different topics to fairly test understanding and skills</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                        { question.status === false ? <Alert severity="warning">{question.msg}</Alert> : question.map((row, i) =>
                        <Card sx={{mt: 1.5}} key={i} >
                            <CardContent>
                                <Typography mb={1} mt={1} >{i+1}. {row.question}</Typography>
                                <FormControl>
                                    <RadioGroup row >
                                        <FormControlLabel value="op1" control={<Radio />} label={row.op1} onClick={() => update_answer(row.questionid, row.op1)} />
                                        <FormControlLabel value="op2" control={<Radio />} label={row.op2} onClick={() => update_answer(row.questionid, row.op2)} />
                                        <FormControlLabel value="op3" control={<Radio />} label={row.op3} onClick={() => update_answer(row.questionid, row.op3)} />
                                    </RadioGroup>
                                </FormControl>
                            </CardContent>
                        </Card> ) }

                        <Box textAlign={'center'} mt={3} >
                            <Button variant="contained" size="large" color="success" sx={{width: '100%'}} onClick={() => submit_answers()} disabled={action === 'submitting' ? true : false} >{action === 'submitting' ? <CircularProgress color="inherit" size={24} /> : 'Submit' }</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'
import Bar from "../bar";
import { toJpeg } from 'html-to-image';
import QRCode from "react-qr-code";

// MUI
import { Avatar, Box, Button, Container, Grid, Typography } from '@mui/material'
import { Download } from '@mui/icons-material'

export default function StudentIdCard({apibranch}){
    const { studentid } = useParams()
    const area = useRef()

    const download = useCallback(() => {
        if (area.current === null) {
          return
        }
      
        toJpeg(area.current, { cacheBust: true })
          .then((dataUrl) => {
            const link = document.createElement('a');
            link.download = 'idcard.jpeg';
            link.href = dataUrl;
            link.click();
        })
        .catch((err) => {
            console.log(err);
        });
    }, [area])

    const [card, setCard] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('create-id-card', studentid)
        fetch(apibranch, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status === true){
                setCard(data.data)
            }
        })
    }, [])

    return(
        <Box><Bar text='ID Card' printArea={area} />
        <Container>
            <Grid container>
                <Grid item mx='auto' mt={5} >
                    <Box textAlign='center' mb={3} >
                        <Button variant="outlined" startIcon={<Download/>} size="small" onClick={() => download()} >Download</Button>
                    </Box>
                    <Box ref={area} sx={{position: 'relative', width: '152mm', height: '228mm'}} >
                        <img src="/img/idcard.webp" alt="student id card" style={{width: '152mm', height: '228mm'}} />

                        <Avatar src={`/instituteassets/studentimg/${card.s_pic}`} sx={{position: 'absolute', top: '16mm', left: '57.5mm', width: 220, height: 220}} />
                        <Box sx={{position: 'absolute', top: '90mm', left: '24mm', width: '125mm'}} >
                            <Typography fontSize={30} fontWeight='bold' textAlign='center' noWrap letterSpacing={1} >{card.s_name}</Typography>
                        </Box>
                        <Box sx={{position: 'absolute', top: '105mm', left: '24mm', width: '125mm'}} >
                            <Typography fontSize={15} textAlign='center' noWrap color='gray' >Student Code</Typography>
                            <Typography fontSize={30} fontWeight='bold' textAlign='center' noWrap color='primary.main' >{card.s_code}</Typography>
                        </Box>
                        <Box sx={{position: 'absolute', top: '125mm', left: '24mm', width: '125mm'}} >
                            <Typography fontSize={15} textAlign='center' noWrap color='gray' >Joined Course</Typography>
                            <Typography fontSize={20} fontWeight='bold' textAlign='center' color='primary.main' >{card.c_name}</Typography>
                            <Typography fontSize={20} fontWeight='bold' textAlign='center' color='primary.main' >({card.c_title})</Typography>
                        </Box>
                        <Box sx={{position: 'absolute', top: '150mm', left: '24mm', width: '125mm', textAlign:'center'}} >
                            <Typography fontSize={12} color={'gray'} mb={1} >Scan this QR code to make attendance</Typography>
                            <QRCode value={studentid} size={250} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
}
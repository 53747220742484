import React, { useEffect, useState, useRef } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import SidebarAdmin from './sidebar'
import HeaderAdmin from './header'
import Close from './../close'

/* MUI */
import { Alert, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system'
import { CloseRounded } from '@mui/icons-material'

export default function PaymentAdmin(){
    const api = '/instituteapi/admin/index.php'
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const { path, url } = useRouteMatch()
    const history = useHistory()
    let date = new Date()

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    const [desc, setDesc] = useState('')
    const [type, setType] = useState('')
    const [amount, setAmount] = useState('')
    const [paydate, setPaydate] = useState('')

    const [debit, setDebit] = useState(0)
    const [credit, setCredit] = useState(0)
    const [balance, setBalance] = useState(0)

    useEffect(() => {
        viewentry()
    }, [])
    const viewentry = () => {
        var formData = new FormData()
        formData.append('total-entry', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setDebit(data.debit); setCredit(data.credit); setBalance(data.balance)
            }
        })
    }

    const upload = (event) => {
        setType(event)
        var formData = new FormData()
        formData.append('configure-entry', event)
        formData.append('amount', amount)
        formData.append('desc', desc)
        formData.append('paydate', paydate)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setMessage(data.warning); setOpen(true)
            } else if(data.success) {
                viewentry(); setDesc(''); setType(''); setAmount('');
            }
        })
    }

    return(
        <><SidebarAdmin />
        <div className='content'>
        <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-md-8 col-12 mx-auto flex mt-5' >
                    <div className='row p-3 rounded-3 lightshadow'>
                        <div className='col-8 p-0 mb-2'>
                            <TextField fullWidth variant='outlined' type='text' label='Description' value={desc} onChange={(event) => setDesc(event.target.value)} />
                        </div>
                        <div className='col-4 p-0 mb-2'>
                            <FormControl fullWidth >
                                <InputLabel>Default</InputLabel>
                                <Select label='Default' value={desc} onChange={(event) => setDesc(event.target.value)} >
                                    <MenuItem value='Electriciy bill' >Electricity bill</MenuItem>
                                    <MenuItem value='Oil' >Oil</MenuItem>
                                    <MenuItem value='Recharge' >Rechage</MenuItem>
                                    <MenuItem value='Institute' >Institute</MenuItem>
                                    <MenuItem value='Grocery' >Grocery</MenuItem>
                                    <MenuItem value='Water' >Water</MenuItem>
                                    <MenuItem value='Ink' >Ink</MenuItem>
                                    <MenuItem value='Marker' >Marker</MenuItem>
                                    <MenuItem value='Register' >Register</MenuItem>
                                    <MenuItem value='A4 paper' >A4 paper</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-4 p-0 mb-2'>
                            <TextField fullWidth type='number' label='Amount &#8377;' value={amount} onChange={(event) => setAmount(event.target.value)} />
                        </div>
                        <div className='col-md-4 p-0 mb-2'>
                            <TextField fullWidth type='date' label=' ' value={paydate} onChange={(event) => setPaydate(event.target.value)} />
                        </div>
                        <div className='col-md-4 p-0 mb-2'>
                            <FormControl fullWidth >
                                <InputLabel>Type</InputLabel>
                                <Select label='Type' value={type} onChange={(event) => upload(event.target.value)} >
                                    <MenuItem value='DEBIT' >EXPANCE</MenuItem>
                                    <MenuItem value='CREDIT' >INCOME</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='row mt-4 rounded-3 p-3 lightshadow pointer' onClick={() => history.push(`${url}/history`)} >
                        <div className='col-md-6 col-12' >
                            <div className='rounded-3 flex text-center' style={{border: 'thin dashed #cccccc', height: '200px'}} >
                                <h5 className='lspace3'>EXPANCE</h5>
                                <h3 className='fw-bold'>{Number(debit).toLocaleString('en-in')} &#8377;</h3>
                            </div>
                        </div>
                        <div className='col-md-6 col-12' >
                            <div className='rounded-3 flex text-center' style={{border: 'thin dashed #cccccc', height: '200px'}} >
                                <h5 className='lspace3'>INCOME</h5>
                                <h3 className='fw-bold'>{Number(credit).toLocaleString('en-in')} &#8377;</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Route path={`${path}/history`} component={Entryhistory} />
        </>
    );

    function Entryhistory(){

        const close_dialog = () => {
            viewentry(); history.go(-1)
        }

        const [year, setYear] = useState(date.toLocaleDateString('en-in', {year: 'numeric'}))
        const [month, setMonth] = useState(date.toLocaleDateString('en-in', {month: '2-digit'}))
        const [warning, setWarning] = useState('')
        const [histories, setHistories] = useState([])
        const [loading, setLoading] = useState(true)
        useEffect(() => {
            viewhistory(year, month)
            closing_balance(year, month)
        }, [year, month])

        const viewhistory = (year, month) => {
            setWarning(''); setHistories([])
            var formData = new FormData()
            formData.append('view-entry', year)
            formData.append('month', month)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setHistories(data)
                }
            })
        }

        const [deleting, setDeleting] = useState('')
        const deleteentry = (tableid) => {
            setDeleting(tableid)
            var formData = new FormData()
            formData.append('delete-entry', tableid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.success){
                    viewhistory(year, month)
                }
            })
        }

        const [cbalance, setCbalance] = useState('')
        const closing_balance = (year, month) => {
            var formData = new FormData()
            formData.append('entry-detail', year)
            formData.append('month', month)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setCbalance(data.c_balance)
                }
            })
        }

        const area = useRef()
        const printit = useReactToPrint({
            content: () => area.current
        })

        let x = 0
        let y = 0

        const income = histories.reduce((t, i) => t + Number(i.e_credit), 0)
        const expance = histories.reduce((t, i) => t + Number(i.e_debit), 0)

        return(
            <Dialog open={true} fullWidth={true} maxWidth='md' fullScreen={fullScreen} onClose={() => close_dialog()}>
                <DialogTitle>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6' >Entry history</Typography>
                        <Box>
                            <IconButton onClick={() => printit()} ><PrintIcon /></IconButton>
                            <IconButton onClick={() => close_dialog()} ><CloseRounded /></IconButton>
                        </Box>
                    </Stack>
                </DialogTitle><Divider/>
                <DialogContent>
                    <Box ref={area} sx={{'@media print' : {padding: 3}, padding: {xs: 0, md: 3}}} >
                        <Box sx={{display: 'none', displayPrint: 'block'}} >
                            <Box>
                                <Typography variant='h5' fontWeight='bold' sx={{color: '#ef6c00'}} >NEXUS EDUCATION & WELFARE FOUNDATION</Typography>
                                <Typography variant='body1' color='gray' >Gami Tola, Hariganj, Katihar - 854105</Typography>
                                <Typography variant='body1' color='gray'>Bihar</Typography>
                            </Box>
                            <Box sx={{background: '#ef6c00'}} padding={0.3} mt={1} ></Box>
                        </Box>
                        <Stack direction='row' spacing={2} sx={{displayPrint: 'none'}} >
                            <FormControl fullWidth variant='standard' >
                                <InputLabel>Year</InputLabel>
                                <Select value={year} onChange={(event) => setYear(event.target.value)} >
                                    <MenuItem value='2019' >2019</MenuItem>
                                    <MenuItem value='2020' >2020</MenuItem>
                                    <MenuItem value='2021' >2021</MenuItem>
                                    <MenuItem value='2022' >2022</MenuItem>
                                    <MenuItem value='2023' >2023</MenuItem>
                                    <MenuItem value='2024' >2024</MenuItem>
                                    <MenuItem value='2025' >2025</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant='standard' >
                                <InputLabel>Month</InputLabel>
                                <Select value={month} onChange={(event) => setMonth(event.target.value)} >
                                    <MenuItem value='01' >January</MenuItem>
                                    <MenuItem value='02' >February</MenuItem>
                                    <MenuItem value='03' >March</MenuItem>
                                    <MenuItem value='04' >April</MenuItem>
                                    <MenuItem value='05' >May</MenuItem>
                                    <MenuItem value='06' >June</MenuItem>
                                    <MenuItem value='07' >July</MenuItem>
                                    <MenuItem value='08' >August</MenuItem>
                                    <MenuItem value='09' >September</MenuItem>
                                    <MenuItem value='10' >October</MenuItem>
                                    <MenuItem value='11' >November</MenuItem>
                                    <MenuItem value='12' >December</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Box mt={2} >
                            <Typography>Monthly collection report</Typography>
                            <Table size='small' >
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Month</TableCell>
                                        <TableCell>{new Date(2000, month-1).toLocaleString(undefined, {month: 'long'})} {year}</TableCell>
                                        
                                        <TableCell>Closing balance ({new Date(2000, month-2).toLocaleString(undefined, {month: 'long'})})</TableCell>
                                        <TableCell>{Number(cbalance).toLocaleString('en-in')} &#8377;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Income</TableCell>
                                        <TableCell>{Number(income).toLocaleString('en-in')} &#8377;</TableCell>
                                        
                                        <TableCell>Expance</TableCell>
                                        <TableCell>{Number(expance).toLocaleString('en-in')} &#8377;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{new Date(2000, month-1).toLocaleString(undefined, {month: 'long'})} balance</TableCell>
                                        <TableCell>{Number(income-expance).toLocaleString('en-in')} &#8377;</TableCell>
                                        
                                        <TableCell>Total</TableCell>
                                        <TableCell>{Number(Number(cbalance) + Number(income) - Number(expance)).toLocaleString('en-in')} &#8377;</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                        <Box mt={2} >
                            <Typography variant='body2' color='gray' >Income</Typography>
                            {loading ? <LinearProgress color='warning' /> :
                            warning ? <Alert severity='warning'>{warning}</Alert> :
                            <TableContainer>
                                <Table size='small' >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>SR.NO.</TableCell>
                                            <TableCell align='left' >Description</TableCell>
                                            <TableCell align='left' >Date</TableCell>
                                            <TableCell align='right' >Income</TableCell>
                                            <TableCell align='right' className='d-print-none' >Delete</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {histories.map((his, i) =>
                                        Number(his.e_credit) > 0 &&
                                        <TableRow key={i} >
                                            <TableCell align='left' >{x = x+1}</TableCell>
                                            <TableCell align='left' >{his.e_description} {his.show_branch === '1' ? `(${his.b_name})` : ''}</TableCell>
                                            <TableCell align='left' >{his.e_date}</TableCell>
                                            <TableCell align='right' >{Number(his.e_credit).toLocaleString('en-in')} &#8377;</TableCell>
                                            <TableCell align='right' className='d-print-none' ><IconButton onDoubleClick={() => deleteentry(his.e_table_id)} >{his.e_table_id === deleting ? <CircularProgress color='inherit' size={24} /> : <DeleteIcon />}</IconButton></TableCell>
                                        </TableRow> )}
                                    </TableBody>
                                </Table>
                            </TableContainer> }
                        </Box>
                        <Box mt={2} >
                            <Typography variant='body2' color='gray' >Expance</Typography>
                            {loading ? <LinearProgress color='warning' /> :
                            warning ? <Alert severity='warning'>{warning}</Alert> :
                            <TableContainer>
                                <Table size='small' >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>SR.NO.</TableCell>
                                            <TableCell align='left' >Description</TableCell>
                                            <TableCell align='left' >Date</TableCell>
                                            <TableCell align='right' >Expance</TableCell>
                                            <TableCell align='right' className='d-print-none' >Delete</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {histories.map((his, i) =>
                                        Number(his.e_debit) > 0 &&
                                        <TableRow key={i} >
                                            <TableCell align='left' >{y = y+1}</TableCell>
                                            <TableCell align='left' >{his.e_description} {his.show_branch === '1' ? `(${his.b_name})` : ''}</TableCell>
                                            <TableCell align='left' >{his.e_date}</TableCell>
                                            <TableCell align='right' >{Number(his.e_debit).toLocaleString('en-in')} &#8377;</TableCell>
                                            <TableCell align='right' className='d-print-none' ><IconButton onDoubleClick={() => deleteentry(his.e_table_id)} >{his.e_table_id === deleting ? <CircularProgress color='inherit' size={24} /> : <DeleteIcon />}</IconButton></TableCell>
                                        </TableRow> )}
                                    </TableBody>
                                </Table>
                            </TableContainer> }
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
}
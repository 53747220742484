import React, {  } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack';

import './index.css';

// MUI
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

/* ADMIN */
import AdminLogin from './login/admin'
import DashboardAdmin from './admin/dashboard'
import BranchAdmin from './admin/branch'
import StudentAdmin from './admin/student'
import CoursedAdmin from './admin/course'
import PaymentAdmin from './admin/payment'
import CertificatedAdmin from './admin/dashboard'
import BranchFranchiseCertificate from './certificate/branch'
import GalleryAdmin from './admin/gallery'
import StudentListAdmin from './admin/student-list'
import OffersAdmin from './admin/offer'
import CertNewAdmin from './admin/cert-new'
import StudentHistoryAdmin from './admin/student-history'
import MonthPaymentReportAdmin from './admin/payment-report'
import QuestionsAdmin from './admin/questions.jsx';
import QuestionSubject from './admin/question-subject.jsx';

/* BRANCH */
import BranchLogin from './login/branch'
import DashboardBranch from './branch/dashboard'
import AttendanceBranch from './branch/attendance'
import StudentBranch from './branch/student'
import BatchBranch from './branch/batch'
import PaymentBranch from './branch/payment'
import EntryBranch from './branch/entry'
import StudentList from './branch/student-list'
import TeacherBranch from './branch/teacher'
import EnquiryBranch from './branch/enquiry'
import AttendanceMakeBranch from './branch/attendance-make'
import AttendanceReportBranch from './branch/attendance-report'
import StudentHistoryBranch from './branch/student-history'
import MonthPaymentReportBranch from './branch/month-payment-report'
import StudentIdCard from './branch/idcard.jsx'
import TestBatchBranch from './branch/test-batch.jsx'

/* STUDENT */
import StudentLogin from './login/student'
import StudentForgetPassword from './login/student/forget'
import CreateAccountStudent from './login/student/create'
import DashboardStudent from './student/index'
import AttendanceHistoryStudent from './student/attendance'
import PaymentHistoryStudent from './student/payment'
import ProfileStudent from './student/profile'
import ScannerStudent from './student/scanner.jsx';
import Mcq_student from './student/mcq.jsx';
import TestAnswerStudent from './student/test-answer.jsx';

/* ISSUE */
import ComputerCertificate from './certificate/certificate'
import Marksheet from './certificate/marksheet'
import TypingCertificate from './certificate/typing-certificate'

/* TEACHER */
import TeacherLogin from './login/teacher'
import DashboardTeacher from './teacher/index'
import AttendanceTeacher from './teacher/attendance'

/* PAGES */
import Home from './pages/home.jsx'
import Student from './pages/student.jsx'
import Courses from './pages/courses.jsx'
import Gallery from './pages/gallery.jsx'
import GetFranchise from './pages/franchise'
import Documents from './pages/documents'

// GLOBAL
import AttendanceScanner from './global/attendance/scanner';
import QuestionGenerator from './global/exam/question-generator.jsx';
import TestReportGlobal from './global/exam/test-report.jsx'
import TestReportStudents from './global/exam/test-report-student.jsx';

/* PRINT */
import Invoice from './print/invoice'
import AdmissionForm from './print/form'
import PrintPayment from './print/payment'

function Institute(){

  const theme = createTheme({
    palette: {
      primary: {
        light: '#fc7951',
        main: '#ef6c00',
        dark: '#ff5926',
        contrastText: '#fff',
      },
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          }
        }
      }
    }

    
  });

  const fullscreen = useMediaQuery(useTheme().breakpoints.down('md'));

  const studentimg = '/instituteassets/studentimg/'

  const apiAdmin = '/instituteapi/admin/index.php'
  const apiadmin2 = '/instituteapi/admin/index_v2'
  const doc = '/instituteassets/documents'

  const apiPrint = '/instituteapi/print-view/index.php'

  const apiBranch = '/instituteapi/branch/index.php'
  const apiBranch2 = '/instituteapi/branch/index_v2.php'
  const imgBranch = '/instituteassets/branchimg/'

  const apiStudent = '/instituteapi/student/index.php'
  const apistudent2 = '/instituteapi/student/index_v2.php'
  const loginapistudent = '/instituteapi/login/student/index.php'
  const imgStudent = '/instituteassets/studentimg/'

  const apiteacher = '/instituteapi/teacher/index.php';

  const apipublic = '/instituteapi/public/index_v2.php'

  return(
    <SnackbarProvider maxSnack={5} anchorOrigin={{horizontal: 'right', vertical: 'top'}} >
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path='/login-admin' component={AdminLogin} />
        <Route path='/dashboard-admin' component={() => <DashboardAdmin api={apiAdmin} />} />
        <Route path='/branch-admin' component={BranchAdmin} />
        <Route path='/student-admin' component={() => <StudentAdmin api={apiAdmin} bimg={imgBranch} />} />
        <Route path='/course-admin' component={() => <CoursedAdmin api={apiAdmin} />} />
        <Route path='/payment-admin' component={PaymentAdmin} />
        <Route path='/certificate-admin' component={CertificatedAdmin} />
        <Route path='/gallery-admin' component={GalleryAdmin} />
        <Route path='/student-list-admin' component={() => <StudentListAdmin api={apiAdmin} />} />
        <Route path='/offer-admin' component={OffersAdmin} />
        <Route path='/cert-new-admin/:studentid' component={() => <CertNewAdmin api={apiAdmin} doc={doc} />} />
        <Route path='/student-history/:studentid' component={() => <StudentHistoryAdmin api={apiAdmin} imgpath={imgStudent} />} />
        <Route path='/report-admin' component={() => <MonthPaymentReportAdmin api={apiAdmin} />} />
        <Route path='/question-admin' component={() => <QuestionsAdmin api={apiadmin2} />} />
        <Route path='/question-subject-admin' component={() => <QuestionSubject api={apiadmin2} />} />

        <Route path='/login-branch' component={() => <BranchLogin />} />
        <Route path='/dashboard-branch' component={() => <DashboardBranch api={apiBranch} fullscreen={fullscreen} />} />
        <Route path='/attendance-branch' component={AttendanceBranch} />
        <Route path='/student-branch' component={() => <StudentBranch api={apiBranch} />} />
        <Route path='/batch-branch' component={BatchBranch} />
        <Route path='/payment-branch' component={PaymentBranch} />
        <Route path='/entry-branch' component={() => <EntryBranch api={apiBranch} />} />
        <Route path='/student-list-branch' component={StudentList} />
        <Route path='/teacher-branch' component={TeacherBranch} />
        <Route path='/enquiry-branch' component={EnquiryBranch} />
        <Route path='/attendance-make-branch/:batchid' component={AttendanceMakeBranch} />
        <Route path='/attendance-report-branch' component={AttendanceReportBranch} />
        <Route path='/student-history-branch/:studentid' component={() => <StudentHistoryBranch api={apiBranch} imgpath={imgStudent} />} />
        <Route path='/month-payment-report' component={() => <MonthPaymentReportBranch api={apiBranch} />} />
        <Route path='/idcard/:studentid' component={() => <StudentIdCard apibranch={apiBranch} /> } />
        <Route path={'/test-batch-branch'} component={() => <TestBatchBranch apib={apiBranch2} />} />

        <Route path='/login-student' component={StudentLogin} />
        <Route path='/forget-password-student' component={() => <StudentForgetPassword api={loginapistudent} />} />
        <Route path='/create-account-student' component={() => <CreateAccountStudent api={loginapistudent} />} />
        <Route path='/dashboard-student' component={() => <DashboardStudent api={apiStudent} apistudent2={apistudent2} />} />
        <Route path='/attendance-history-student' component={AttendanceHistoryStudent} />
        <Route path='/payment-history-student' component={PaymentHistoryStudent} />
        <Route path='/profile-student' component={ProfileStudent} />
        <Route path='/scanner-student' component={() => <ScannerStudent api={apiStudent} />} />
        <Route path={'/mcq-daily/:exambatchid'} component={() => <Mcq_student apistudent2={apistudent2} />} />
        <Route path={'/test-result-student'} component={() => <TestAnswerStudent apistudent2={apistudent2} />} />

        <Route path='/certificate/:studentid' component={() => <ComputerCertificate api={apiPrint} bimg={imgBranch} />} />
        <Route path='/marksheet/:studentid' component={() => <Marksheet api={apiPrint} bimg={imgBranch} />} />
        <Route path='/typing-certificate/:studentid' component={() => <TypingCertificate api={apiPrint} bimg={imgBranch} />} />
        <Route path='/certificate-branch/:branchid' component={() => <BranchFranchiseCertificate api={apiPrint} />} />

        <Route path='/invoice/:studentid/:paymentid' component={() => <Invoice api={apiPrint} img={imgBranch} />} />
        <Route path='/form/:studentid' component={() => <AdmissionForm api={apiPrint} img={studentimg} imgBranch={imgBranch} />} />
        <Route path='/payment-detail' component={PrintPayment} />

        <Route path='/login-teacher' component={TeacherLogin} />
        <Route path='/dashboard-teacher' component={() => <DashboardTeacher apipublic={apipublic} fullscreen={fullscreen} />} />
        <Route path='/attendance-teacher/:batchid' component={AttendanceTeacher} />

        <Route path='/attendance-scanner/:branchid' component={() => <AttendanceScanner apipublic={apipublic} />} />
        <Route path={'/question-generator/:branchid'} component={() => <QuestionGenerator apipublic={apipublic} apiteacher={apiteacher} />} />
        <Route path={'/test-report/:studentid'} component={() => <TestReportGlobal apipublic={apipublic} />} />
        <Route path={'/test-report-students/:batchid'} component={() => <TestReportStudents apipublic={apipublic} />} />

        <Route exact path='/' component={Home} />
        <Route path='/student' component={Student} />
        <Route path='/courses' component={Courses} />
        <Route path='/gallery' component={Gallery} />
        <Route path='/documents' component={Documents} />
        <Route path='/get-franchise' component={GetFranchise} />
      </Switch>
    </ThemeProvider>
    </SnackbarProvider>
  );

} ReactDOM.render(<BrowserRouter><Institute /></BrowserRouter>, document.getElementById('root'))
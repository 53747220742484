import React, { useState } from "react";
import { useHistory } from 'react-router-dom'

// MUI
import { Box, Container, Grid, Alert, Button, CircularProgress, TextField, Typography, Card, CardContent } from '@mui/material'
import {  } from '@mui/icons-material'

export default function StudentForgetPassword({api}){
    let history = useHistory()

    const [otpsended, setOtpsended] = useState(false)
    const [email, setEmail] = useState('')
    const [newpass, setNewpass] = useState('')
    const [otp, setOtp] = useState('')
    const [warning, setWarning] = useState('')
    const [reseting, setReseting] = useState(false)
        
    const verify = () => {
            setWarning('')
            setReseting(true)
            var formData = new FormData()
            formData.append('verify-email', email)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setReseting(false)
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.otpsended){
                    setOtpsended(true)
                }
            })
    }

    const reset = () => {
            setWarning('')
            setReseting(true)
            var formData = new FormData()
            formData.append('reset-password', email)
            formData.append('new-password', newpass)
            formData.append('otp', otp)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setReseting(false)
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.success){
                    // REDIRECT HERE
                    history.replace('/dashboard-student')
                }
            })
    }

    return(
        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh"}} >
            <Container>
                <Grid container>
                    <Grid item xs={12} md={5} mx='auto'>
                        <Card>
                            <CardContent sx={{mt: 2, mb: 4}} >
                                <Typography fontSize={30} fontWeight='bold' letterSpacing={3} mb={4} >RESET PASSWORD</Typography>
                                <TextField fullWidth variant='standard' type='email' label='Email' disabled={otpsended} value={email} onChange={(event) => setEmail(event.target.value)} />
                                {otpsended === true && <>
                                <TextField fullWidth variant='standard' type='text' label='New password' className='mt-4' value={newpass} onChange={(event) => setNewpass(event.target.value)} />
                                <TextField fullWidth variant='standard' type='number' label='OTP' className='mt-4' value={otp} onChange={(event) => setOtp(event.target.value)} />
                                </>}
                                {warning && <Alert severity='warning' sx={{mt: 1}} >{warning}</Alert> }
                                {otpsended === true ?
                                <Button sx={{width: '100%', mt: 5}} color='warning' variant='contained' disabled={reseting} onClick={() => reset()} >{reseting ? <CircularProgress color='inherit' size={24} /> : 'Reset Password' }</Button> :
                                <Button sx={{width: '100%', mt: 5}} color='warning' variant='contained' disabled={reseting} onClick={() => verify()} >{reseting ? <CircularProgress color='inherit' size={24} /> : 'Verify Email' }</Button> }
                                <Button sx={{width: '100%', mt: 2}} variant="outlined" color="info" onClick={() => history.go(-1)} >Login instead</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
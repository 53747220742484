import React, { useEffect } from 'react'

/* HEADER */
import PagesHeader from './header';
import PagesFooter from './footer'

/* MATERIAL UI */
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ClassIcon from '@mui/icons-material/Class';

export default function Courses(){

    useEffect(() => {
        document.title = 'Courses'
    }, [])

    return(
        <><PagesHeader />
        <div className='container-fluid courses' style={{backgroundImage: 'url(/nexusassets/landing.png)'}} >
            <div className='row p-3'>
                <div className='col-md-7 col-12 mx-auto mb-4 mt-4'>
                    <Accordion expanded>
                        <AccordionSummary>
                            <Typography>
                                <h5 className='fw-bold' ><ClassIcon fontSize='large' className='colorOrange' /> DCA</h5>
                                <small>Diploma In Computer Application (6 - Months)</small>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>Fundamental of Computer</li>
                                    <li>MS Windows
                                        <ul>
                                            <li>Windows Concept</li>
                                            <li>MS Paint</li>
                                            <li>MS Notepad</li>
                                            <li>MS WordPad</li>
                                            <li>Disk cleanup</li>
                                            <li>Backup and recovery</li>
                                            <li>Bit locker</li>
                                            <li>Snipping tool</li>
                                            <li>User account</li>
                                            <li>Control panel</li>
                                        </ul>
                                    </li>
                                    <li>MS Office
                                        <ul>
                                            <li>MS Word</li>
                                            <li>MS Excel</li>
                                            <li>MS Power Point</li>
                                        </ul>
                                    </li>
                                    <li>Internet & E-mail</li>
                                    <li>HTML, CSS JavaScript, Basic website deveoping</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='col-md-7 col-12 mx-auto mb-4'>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                <h5 className='fw-bold' ><ClassIcon fontSize='large' className='colorOrange' /> ADCA</h5>
                                <small>Advanced Diploma In Computer Application (12 - Months)</small>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>Fundamental of Computer</li>
                                    <li>MS Windows
                                        <ul>
                                            <li>MS Notepad</li>
                                            <li>MS MS Paint</li>
                                            <li>MS WordPad</li>
                                            <li>Disk cleanup</li>
                                            <li>Backup and recovery</li>
                                            <li>Bit locker</li>
                                            <li>Snipping tool</li>
                                            <li>User account</li>
                                            <li>Control panel</li>
                                        </ul>
                                    </li>
                                    <li>MS Office
                                        <ul>
                                            <li>MS Word</li>
                                            <li>MS Excel</li>
                                            <li>MS PowerPoint</li>
                                        </ul>
                                    </li>
                                    <li>DTP
                                        <ul>
                                            <li>CorelDraw</li>
                                            <li>Photoshop</li>
                                            <li>Page Maker</li>
                                        </ul>
                                    </li>
                                    <li>HTML, CSS JavaScript, Basic website deveoping</li>
                                    <li>Internet & E-mail</li>
                                    <li>Tally Prime With GST</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='col-md-7 col-12 mx-auto mb-4'>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                <h5 className='fw-bold' ><ClassIcon fontSize='large' className='colorOrange' /> ADOA</h5>
                                <small>Advanced Diploma In Office Automation (12 - Months)</small>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>Fundamental of Computer</li>
                                    <li>MS Windows
                                        <ul>
                                            <li>MS Notepad</li>
                                            <li>MS MS Paint</li>
                                            <li>MS WordPad</li>
                                            <li>Disk cleanup</li>
                                            <li>Backup and recovery</li>
                                            <li>Bit locker</li>
                                            <li>Snipping tool</li>
                                            <li>User account</li>
                                            <li>Control panel</li>
                                        </ul>
                                    </li>
                                    <li>MS Office
                                        <ul>
                                            <li>MS Word</li>
                                            <li>MS Excel</li>
                                            <li>MS PowerPoint</li>
                                        </ul>
                                    </li>
                                    <li>DTP
                                        <ul>
                                            <li>CorelDraw</li>
                                            <li>Photoshop</li>
                                            <li>Page Maker</li>
                                        </ul>
                                    </li>
                                    <li>HTML</li>
                                    <li>Internet & E-mail</li>
                                    <li>Tally Prime With GST</li>
                                    <li>Accounting Fundamental</li>
                                    <li>Basics of Accounting</li>
                                    <li>Final Accounting</li>
                                    <li>Assignment & Projects</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='col-md-7 col-12 mx-auto mb-4'>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                <h5 className='fw-bold' ><ClassIcon fontSize='large' className='colorOrange' />DOA</h5>
                                <small>Diploma In Office Automation (9 - Months)</small>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>Fundamental of Computer</li>
                                    <li>MS Windows
                                        <ul>
                                            <li>MS Notepad</li>
                                            <li>MS MS Paint</li>
                                            <li>MS WordPad</li>
                                            <li>Disk cleanup</li>
                                            <li>Backup and recovery</li>
                                            <li>Bit locker</li>
                                            <li>Snipping tool</li>
                                            <li>User account</li>
                                            <li>Control panel</li>
                                        </ul>
                                    </li>
                                    <li>MS Office
                                        <ul>
                                            <li>MS Word</li>
                                            <li>MS Excel</li>
                                            <li>MS PowerPoint</li>
                                        </ul>
                                    </li>
                                    <li>Internet & E-mail</li>
                                    <li>Accounting Fundamental</li>
                                    <li>Basics of Accounting</li>
                                    <li>Final Accounting</li>
                                    <li>Advance Accounts</li>
                                    <li>Tally Prime With GST</li>
                                    <li>Assignment & Projects</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='col-md-7 col-12 mx-auto mb-4'>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                <h5 className='fw-bold' ><ClassIcon fontSize='large' className='colorOrange' />DFA</h5>
                                <small>Diploma In Financial Accounting (6 - Months)</small>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>Accounting Fundamentals</li>
                                    <li>Basics of Accounting</li>
                                    <li>Final Accounting</li>
                                    <li>Advance Accounts</li>
                                    <li>Tally Prime with GST</li>
                                    <li>Assignment & Projects</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='col-md-7 col-12 mx-auto mb-4'>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                <h5 className='fw-bold' ><ClassIcon fontSize='large' className='colorOrange' />DTP</h5>
                                <small>DeskTop Publication (3 - Months)</small>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>CorelDraw</li>
                                    <li>Photoshop</li>
                                    <li>Page Maker</li>
                                    <li>Using Scanner</li>
                                    <li>Using Printer</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='col-md-7 col-12 mx-auto mb-4'>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                <h5 className='fw-bold' ><ClassIcon fontSize='large' className='colorOrange' />Tally</h5>
                                <small>Certificate in Financial Accounting (3 - Months)</small>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>Accounting Fundamental</li>
                                    <li>Voucher Entry</li>
                                    <li>Day Book</li>
                                    <li>Ledger</li>
                                    <li>Trial Balance</li>
                                    <li>Trading and Profit & Loss A/C</li>
                                    <li>Balance Sheet</li>
                                    <li>Stock</li>
                                    <li>Tally Prime with GST</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='col-md-7 col-12 mx-auto mb-4'>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                <h5 className='fw-bold' ><ClassIcon fontSize='large' className='colorOrange' />CCA</h5>
                                <small>Certificate Course of Computer Application (3 - Months)</small>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>Fundamental of Computer</li>
                                    <li>MS Windows
                                        <ul>
                                            <li>MS Notepad</li>
                                            <li>MS MS Paint</li>
                                            <li>MS WordPad</li>
                                        </ul>
                                    </li>
                                    <li>MS Office
                                        <ul>
                                            <li>MS Word</li>
                                            <li>MS Excel</li>
                                            <li>MS PowerPoint</li>
                                        </ul>
                                    </li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='col-md-7 col-12 mx-auto mb-4'>
                    <Accordion>
                        <AccordionSummary>
                            <Typography>
                                <h5 className='fw-bold' ><ClassIcon fontSize='large' className='colorOrange' />DCH</h5>
                                <small>Diploma In Computer Hardware (6 - Months)</small>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>PC Fundamental</li>
                                    <li>MS Windows & MS Office</li>
                                    <li>Internet & E-mail</li>
                                    <li>CMOS Setup</li>
                                    <li>Complete Computer Assembling</li>
                                    <li>Fault Fiding</li>
                                    <li>Understanding of PC Beep Formating & Installing</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div><PagesFooter /></>
    );
}
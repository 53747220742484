import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

/* MUI */
import { CircularProgress, Dialog, DialogContent, List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import BoyIcon from '@mui/icons-material/Boy';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentIcon from '@mui/icons-material/Payment';
import AddCardIcon from '@mui/icons-material/AddCard';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';

export default function HeaderBranch(){
    const api = '/instituteapi/branch/index.php';
    let history = useHistory()
    const [branch, setBranch] = useState([])

    useEffect(() => {
        var formData = new FormData()
        formData.append('branch-detail', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBranch(data)
            }
        })
    }, [])

    const [open, setOpen] = useState(false)

    const [loggingout, setLoggingout] = useState(false)
        const logout = () => {
            setLoggingout(true)
            var formData = new FormData()
            formData.append('logout', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoggingout(false)
                if(data.success){
                    // REDIRECT HERE
                    history.replace('/login-branch')
                }
            })
        }

    return(
        <div className='container-fluid header sticky-top'>
            <div className='row'>
                <div className='col-12 text-center' style={{padding: '0.5em'}} >
                    <h3 className='fw-bold mt-2 text-light'>{branch.b_name}</h3>
                </div>
            </div>
        </div>
    );
}
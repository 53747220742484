import React from 'react'
import { useHistory } from 'react-router-dom'

/* MUI */
import { IconButton } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function Close({title}){
    let history = useHistory()

    return(
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-10 flex'>
                    <h5 className='fw-bold mt-2 text-truncate'>{title}</h5>
                </div>
                <div className='col-2 p-0 text-end'>
                    <IconButton size='large' className='text-dark' onClick={() => history.go(-1)} ><CloseRoundedIcon /></IconButton>
                </div>
            </div>
        </div>
    );
}
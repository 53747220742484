import React from 'react'
import { Link } from 'react-router-dom';

export default function PagesFooter(){

    return(
        <>
        <div className='container-fluid bgOrange footer'>
            <div className='row p-3'>
                <div className='col-md-4 col-12 text-light border-end mb-4 flex'>
                    <h1 className='fw-bold' style={{letterSpacing: '10px', lineHeight: '50px'}} >Welcome in<br/>Nexus Computer</h1>
                </div>
                <div className='col-md-8 col-12'>
                    <div className='row'>
                        <div className='col-md-4 col-12 border-end siteMap mb-4'>
                            <h3 className='text-light fw-bold lspace2'>Site Map</h3>
                            <ul>
                                <li><Link className='text-light' to='/'>Home</Link></li>
                                <li><Link className='text-light' to='/gallery'>Gallery</Link></li>
                                <li><Link className='text-light' to='/student'>Student</Link></li>
                                <li><Link className='text-light' to='/get-franchise'>Franchise</Link></li>
                                <li><Link className='text-light' to='/courses'>Courses</Link></li>
                            </ul>
                        </div>
                        <div className='col-md-4 col-12 border-end mb-4'>
                            <h3 className='text-light fw-bold lspace2'>Social Links</h3>
                            
                            <ul className='text-light'>
                                <li><a href='#' className='text-light' target="_blank" rel='noreferrer'>Instagram</a></li>
                                <li><a href='#' className='text-light' target="_blank" rel='noreferrer'>Facebook</a></li>
                                <li><a href='#' className='text-light' target="_blank" rel='noreferrer'>Twitter</a></li>
                                <li><a href="https://wa.me/919431668289" className='text-light' target="_blank" rel='noreferrer'>WhatsApp</a></li>
                            </ul>
                        </div>
                        <div className='col-md-4 col-12'>
                            <h3 className='text-light fw-bold lspace2'>Login</h3>

                            <ul className='text-light'>
                                <li><Link className='text-light' to='/dashboard-student'>Student</Link></li>
                                <li><Link className='text-light' to='/dashboard-branch'>Branch/Franchise</Link></li>
                                <li><Link className='text-light' to='/login-teacher'>Teacher</Link></li>
                                <li><Link className='text-light' to='/dashboard-admin'>Admin</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row p-2'>
                <div className='col-12 text-end p-0'>
                    <a href='https://passionfrom.com' target="_blank" rel='noreferrer' ><img alt='Designed & Developed by PassionFrom' src='https://passionfrom.com/passionfromassets/whitePassionfrom.png' style={{width: '120px'}} /></a>
                </div>
            </div>
        </div>
        </>
    );
}
import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

/* MUI */
import { Box, Button, CircularProgress, Dialog, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import BoyIcon from '@mui/icons-material/Boy';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentIcon from '@mui/icons-material/Payment';
import AddCardIcon from '@mui/icons-material/AddCard';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { MoreVert, QuestionAnswer } from '@mui/icons-material';

export default function SidebarBranch(){
    let history = useHistory()
    const { path, url } = useRouteMatch()
    const api = '/instituteapi/branch/index.php'

    useEffect(() => {
        fetch('/instituteapi/branch/ui.php')
        .then(res => res.json())
        .then(data => {
            if(data.loggedin === false){
                history.replace('/login-branch')
            }
        })
    }, [])

    const links = [
        {name: 'Dashboard', link: '/dashboard-branch', icon: <DashboardIcon/>},
        {name: 'Attendance', link: '/attendance-branch', icon: <BoyIcon/>},
        {name: 'Student', link: '/student-branch', icon: <GroupIcon/>},
        {name: 'Test', link: '/test-batch-branch', icon: <QuestionAnswer/>},
        {name: 'Enquiry', link: '/enquiry-branch', icon: <NotificationsIcon/>},
        {name: 'Teacher', link: '/teacher-branch', icon: <GroupIcon/>},
        {name: 'Batch', link: '/batch-branch', icon: <AccessTimeIcon/>},
        {name: 'Payment', link: '/payment-branch', icon: <PaymentIcon/>},
        {name: 'Entry', link: '/entry-branch', icon: <AddCardIcon/>},
        {name: 'Logout', link: `${url}/logout`, icon: <LogoutIcon/>},
    ]

    const [open, setOpen] = useState(false)

    return(
        <>
        <Drawer variant='permanent' sx={{display: {xs: 'none', md: 'block'}}} PaperProps={{style: {background: '#ef6c00', width: '232px'}}} >
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography align='center' color='white' variant='h6' fontWeight='bold' >NEXUS COMPUTER</Typography>
                    </ListItemText>
                </ListItem><Divider/>
                { links.map((data, i) =>
                <ListItem key={i} >
                    <ListItemButton onClick={() => history.push(`${data.link}`)} >
                        <ListItemIcon sx={{color: 'white'}} >{data.icon}</ListItemIcon>
                        <ListItemText sx={{color: 'white'}} >{data.name}</ListItemText>
                    </ListItemButton>
                </ListItem>)}
            </List>
            <Button color='inherit' size='small' onClick={() => window.location.reload()} >UPDATE</Button>
        </Drawer>
        <Drawer open={open} variant='temporary' onClose={() => setOpen(false)} >
            <List>
                { links.map((data, i) =>
                <ListItem key={i} >
                    <ListItemButton onClick={() => history.push(`${data.link}`)} >
                        <ListItemIcon>{data.icon}</ListItemIcon>
                        <ListItemText>{data.name}</ListItemText>
                    </ListItemButton>
                </ListItem>)}
            </List>
            <Button color='inherit' size='small' onClick={() => window.location.reload()} >UPDATE</Button>
        </Drawer>
        <Box sx={{display: {xs: 'block', md: 'none'}}} >
            <Stack direction='row' spacing={3} justifyContent='space-around' sx={{position: 'fixed', bottom: 0, width: '100%', background: '#fafafa', zIndex: 100}} padding={0.5} >
                <IconButton onClick={() => history.push('/dashboard-branch')} ><DashboardIcon/></IconButton>
                <IconButton onClick={() => history.push('/student-branch')} ><GroupIcon/></IconButton>
                <IconButton onClick={() => history.push('/payment-branch')} ><PaymentIcon/></IconButton>
                <IconButton onClick={() => setOpen(true)} ><MoreVert/></IconButton>
            </Stack>
        </Box>
        <Route path={`${path}/logout`} component={Logout} />
        </>
    );

    function Logout(){

        const [loggingout, setLoggingout] = useState(false)
        const logout = () => {
            setLoggingout(true)
            var formData = new FormData()
            formData.append('logout', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoggingout(false)
                if(data.success){
                    // REDIRECT HERE
                    history.replace('/login-branch')
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <div className='container-fluid mt-5 mb-5'>
                    <div className='row p-3'>
                        <div className='col-12 text-center'>
                            <h1 className='fw-bold' >Do you want to logout?</h1>
                        </div>
                        <div className='col-12 text-center text-center mt-4'>
                            <Button color='warning' size='large' className='col-md-4 col-6' variant='contained' disabled={loggingout} onClick={() => logout()} >{loggingout ? <CircularProgress color='inherit' size={24} /> : 'Yes' }</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

}
import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

import QRCode from "react-qr-code";

/* MUI */
import { Alert, AppBar, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material'
import { ArrowForwardIos, Assessment, AutoAwesome, PanToolAlt, PowerSettingsNew, QrCode2, QrCodeScanner, Refresh } from '@mui/icons-material';
import axios from 'axios';

export default function DashboardTeacher({apipublic, fullscreen}){
    const api = '/instituteapi/teacher/index.php'
    let history = useHistory()
    let { path, url } = useRouteMatch()
    const [action, setAction] = useState('')

    useEffect(() => {
        fetch('/instituteapi/teacher/ui.php').then(res => res.json())
        .then(data => {
            if(data.loggedin === false){
                history.replace('/login-teacher')
            }
        })
    }, [])

    const [view, setView] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('teacher-detail', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setView(data)
        })
    }, [])

    const [loggingout, setLoggintout] = useState(false)
    const [openlogout, setOpenlogout] = useState(false)

    const logout = () => {
        setLoggintout(true)
        var formData = new FormData()
        formData.append('logout-teacher', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                history.replace('/login-teacher')
            }
        })
    }

    useEffect(() => {
        view.branchid !== undefined &&
        attendanceList(view.branchid)
    }, [view.branchid])

    const [attendance, setAttendance] = useState([])
    const attendanceList = (branchid) => {
        var formData = new FormData()
        formData.append('get-attendance-list', branchid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status === false){
                setAttendance(data)
            } else {
                setAttendance(data.data)
            }
        })
    }

    const [batch, setBatch] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-batches', '')
        axios.post(api, formData)
        .then(function(res){
            setBatch(res.data)
        })
    }, [])

    const [qr, setQr] = useState('')
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-qr', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setQr(data.qr)
            }
        })
    }, [])

    return(
        <Box>
        <AppBar position='sticky' >
            <Toolbar>
                <Typography fontFamily='serif' fontSize={25} fontWeight='bold' noWrap flexGrow={1} >{view.b_name}</Typography>
                <IconButton color='inherit' onClick={() => window.location.reload()} ><Refresh /></IconButton>
                <IconButton color='inherit' onClick={() => setOpenlogout(true)} ><PowerSettingsNew /></IconButton>
            </Toolbar>
        </AppBar>
        <Container>
            <Grid container mt={5} >
                <Grid item xs={12} md={9} mx='auto' >
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={5}>
                            <Card>
                                <CardContent>
                                    <Box>
                                        <Typography fontSize={35} fontWeight='bold' color='#008B8B' letterSpacing={2} >Hello...</Typography>
                                        <Typography fontSize={25} fontWeight='bold' >{view.t_name}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={7} >
                            <Card >
                                <CardContent>
                                    <Typography fontSize={12} color='gray' >Your code</Typography>
                                    <Typography fontSize={16} color='#008B8B' fontWeight='bold' >{view.t_code}</Typography>

                                    <Typography fontSize={12} color='gray' mt={1} >Join date</Typography>
                                    <Typography fontSize={16} color='#008B8B' fontWeight='bold' >{view.t_joindate}</Typography>

                                    <Typography fontSize={12} color='gray' mt={1} >Status</Typography>
                                    <Typography fontSize={16} color={view.t_status === 'ACTIVE' ? '#00A86B' : '#FF4F00'} fontWeight='bold' >{view.t_status}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Card>
                                <CardContent>
                                    <Stack direction={{xs: 'column', md: 'row'}} spacing={1} mt={1} >
                                        <Button variant='outlined' color='info' sx={{width: '100%'}} size='large' startIcon={<QrCodeScanner />} onClick={() => history.push(`/attendance-scanner/${view.branchid}`)} >Scanner</Button>
                                        <Button variant='outlined' color='success' sx={{width: '100%'}} size='large' startIcon={<QrCode2 />} onClick={() => setAction('openforqr')} >QR</Button>
                                        <Button variant='outlined' color='secondary' sx={{width: '100%'}} size='large' startIcon={<PanToolAlt />} onClick={() => setAction('openforattendance')} >Manually</Button>
                                    </Stack>
                                    <Stack direction={'row'} mt={1} spacing={1} >
                                        <Button variant='outlined' color='info' sx={{width: '100%'}} size='large' startIcon={<AutoAwesome />} onClick={() => history.push(`/question-generator/${view.branchid}`)} >Question generator</Button>
                                        <Button variant='outlined' color='success' sx={{width: '100%'}} size='large' startIcon={<Assessment />} onClick={() => setAction('openfortest')} >Test report</Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            { attendance.status === false ? <Alert severity="warning">{attendance.msg}</Alert> :
                            <TableContainer>
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell align="left">Name</TableCell>
                                                <TableCell align="left">Code</TableCell>
                                                <TableCell align="right">IN</TableCell>
                                                <TableCell align="right">OUT</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { attendance.map((data, i) =>
                                            <TableRow key={i} >
                                                <TableCell component="th" scope="row">{i+1}</TableCell>
                                                <TableCell align="left">{data.s_name}</TableCell>
                                                <TableCell align="left">{data.s_code}</TableCell>
                                                <TableCell align="right">{data.at_in}</TableCell>
                                                <TableCell align="right">{data.at_out}</TableCell>
                                            </TableRow> ) }
                                        </TableBody>
                                    </Table>
                            </TableContainer>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
        <Dialog open={openlogout} fullWidth maxWidth='xs' onClose={() => setOpenlogout(false)} >
            <DialogContent>
                <Box pt={3} pb={3} textAlign='center' >
                    <Typography fontSize={30} color='orange' fontWeight='bold' >Do you want to logout?</Typography>
                    <Typography fontSize={12} color='gray' mt={1} >This action will redirect you to the Teacher Login page.</Typography>

                    <Box textAlign='center' mt={3} >
                        <Button variant='contained' color='error' sx={{boxShadow: 0}} disabled={loggingout} onClick={() => logout()} >{loggingout ? <CircularProgress color='inherit' size={24} /> : 'Yes, Logout'}</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
        <Dialog open={action === 'openfortest' ? true : false} fullWidth maxWidth='xs' fullScreen={fullscreen} onClose={() => setAction('')} >
            <DialogTitle>Select batch for report</DialogTitle><Divider/>
            <DialogContent>
                { batch.warning ? <Alert severity='warning'>{batch.warning}</Alert> :
                batch.map((row, i) =>
                <List key={i} >
                    <ListItem>
                        <ListItemButton onClick={() => history.push(`/test-report-students/${row.batchid}`)} >
                            <ListItemIcon>{i+1}</ListItemIcon>
                            <ListItemText>{row.bh_start} to {row.bh_end}</ListItemText>
                            <ArrowForwardIos />
                        </ListItemButton>
                    </ListItem><Divider/>
                </List> )}
            </DialogContent><Divider/>
            <DialogActions>
                <Button variant='outlined' color='inherit' onClick={() => setAction('')} >Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={action === 'openforattendance' ? true : false} fullWidth maxWidth='xs' fullScreen={fullscreen} onClose={() => setAction('')} >
            <DialogTitle>Select batch for attendance</DialogTitle><Divider/>
            <DialogContent>
                { batch.warning ? <Alert severity='warning'>{batch.warning}</Alert> :
                batch.map((row, i) =>
                <List key={i} >
                    <ListItem>
                        <ListItemButton onClick={() => history.push(`/attendance-teacher/${row.batchid}`)} >
                            <ListItemIcon>{i+1}</ListItemIcon>
                            <ListItemText>{row.bh_start} to {row.bh_end}</ListItemText>
                            <ArrowForwardIos />
                        </ListItemButton>
                    </ListItem><Divider/>
                </List> )}
            </DialogContent><Divider/>
            <DialogActions>
                <Button variant='outlined' color='inherit' onClick={() => setAction('')} >Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog PaperProps={{style: {background: 'transparent'}}} open={action === 'openforqr' ? true : false} fullWidth fullScreen={fullscreen} onClose={() => setAction('')} >
            <DialogContent >
                <Box textAlign={'center'} >
                    <QRCode value={qr} size={450} />
                </Box>
            </DialogContent>
        </Dialog>
        </Box>
    );

}
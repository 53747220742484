import React, { useEffect, useRef, useState } from 'react'
import {  } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import SidebarAdmin from './sidebar'
import HeaderAdmin from './header'

/* MUI */
import { Alert, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print';

export default function StudentListAdmin({api}){

    const [filter, setFilter] = useState('')
    const [filter2, setFilter2] = useState('')

    const [warning, setWarning] = useState('')
    const [students, setStudents] = useState([])
    useEffect(() => {
        viewStudent(filter, filter2)
    }, [filter, filter2])
    const viewStudent = (filter, filter2) => {
        setWarning('')
        var formData = new FormData()
        formData.append('student-list', filter)
        formData.append('branch', filter2)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setWarning(data.warning)
            } else {
                setStudents(data)
            }
        })
    }

    const [branches, setBranches] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-branches', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBranches(data)
            }
        })
    }, [])

    const area = useRef()
    const printit = useReactToPrint({
        content: () => area.current
    })

    var x = 0

    return(
        <><SidebarAdmin />
        <div className='content'>
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-md-6 mb-2'>
                    <FormControl fullWidth variant='standard' >
                        <InputLabel>FILTER</InputLabel>
                        <Select value={filter} onChange={(event) => setFilter(event.target.value)} >
                            <MenuItem value='all' >All</MenuItem>
                            <MenuItem value='verified' >Active</MenuItem>
                            <MenuItem value='issued' >Issued</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='col-md-6'>
                    <FormControl fullWidth variant='standard' >
                        <InputLabel>FILTER 2</InputLabel>
                        <Select value={filter2} onChange={(event) => setFilter2(event.target.value)} >
                            { branches.map((bran, i) =>
                            <MenuItem value={bran.branchid} key={i} >{bran.b_name}</MenuItem> ) }
                        </Select>
                    </FormControl>
                </div>
                <div className='col-12 text-end'>
                    <IconButton onClick={()=> printit()} ><PrintIcon /></IconButton>
                </div>
                <div className='col-md-10 col-12 mx-auto flex mt-3' >
                    <div className='row pp' ref={area} >
                        <div className='d-none d-print-block'>
                            { branches.map((bran, i) =>
                            filter2 === bran.branchid &&
                            <div className='col-12 officialbg p-3 rounded-3 text-light text-center text-break' key={i} >
                                <h3 className='fw-bold' style={{fontFamily: 'calibari'}} >{bran.b_name}</h3>
                                <small style={{fontFamily: 'calibari'}} >{bran.b_address}, {bran.b_state} - {bran.b_code}</small>
                            </div>
                            ) }
                            { filter2 === '' && 
                            <div className='col-12 officialbg p-3 rounded-3 text-light text-center text-break'>
                                <h3 className='fw-bold mt-2' style={{fontFamily: 'calibari'}} >NEXUS COMPUTER</h3>
                            </div> }
                        </div>
                        <div className='col-12 mt-3'>
                            <h6 className='text-muted ms-2'>Number of students - { students.length }</h6>
                        </div>
                        <div className='col-12'>
                            { warning ? <Alert severity='warning'>{warning}</Alert> :
                            <TableContainer sx={{height: '90vh', overflow: 'auto'}} className='height-auto' >
                                <Table size='small' >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='left'>SR. NO.</TableCell>
                                            <TableCell align='left' >NAME</TableCell>
                                            <TableCell align='left' >FATHER/MOTHER'S NAME</TableCell>
                                            <TableCell align='left' >S.CODE</TableCell>
                                            <TableCell align='left' >ADMISSION DATE</TableCell>
                                            <TableCell align='left'>COURSE</TableCell>
                                            <TableCell align='left' >REG. NUM.</TableCell>
                                            <TableCell align='left' >REG. DATE.</TableCell>
                                            <TableCell align='left' >ISSUED ON</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { students.map((stu, i) =>
                                        <TableRow key={i} >
                                            <TableCell align='left' >{x = x+1}</TableCell>
                                            <TableCell align='left' >{stu.s_name}</TableCell>
                                            <TableCell align='left' >{stu.s_guardian}</TableCell>
                                            <TableCell align='left' >{stu.s_code}</TableCell>
                                            <TableCell align='left' >{stu.s_joindate}</TableCell>
                                            <TableCell align='left'>{stu.c_title} - {stu.c_duration}</TableCell>
                                            <TableCell align='left' >{stu.s_enrollment}</TableCell>
                                            <TableCell align='left' >{stu.s_enrollment !== null && stu.s_register}</TableCell>
                                            <TableCell align='left' >{stu.s_enrollment !== null && stu.s_issuedate}</TableCell>
                                        </TableRow> ) }
                                    </TableBody>
                                </Table>
                            </TableContainer> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}
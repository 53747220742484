import React, { useEffect, useState } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import SidebarBranch from './sidebar'
import HeaderBranch from './header'
import Close from './../close'

/* MUI */
import { TextField, IconButton, Button, LinearProgress, Alert, Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Snackbar, Avatar, Divider } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';

export default function PaymentBranch(){
    const api = '/instituteapi/branch/index.php'
    let history = useHistory()
    const { path, url } = useRouteMatch()

    const [students, setStudents] = useState([])
    const [studentwarn, setStudentwarn] = useState('')
    const [loading, setLoading] = useState(true)
    const [query] = useState('')

    useEffect(() => {
        viewstudent(query)
    }, [query])

    const viewstudent = (query) => {
        setStudentwarn('')
        var formData = new FormData()
        formData.append('view-students', query)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setStudentwarn(data.warning)
            } else {
                setStudents(data)
            }
        })
    }

    const searchstudent = (event) => {
        if(event.keyCode === 13){
            viewstudent(event.target.value)
        }
    }

    return(
        <>
        <div className='sidebar'> <SidebarBranch /> </div>
        <div className='content'>
        <HeaderBranch />
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-12 p-0'>
                    <TextField fullWidth type='text' variant='standard' label='Search' placeholder='Search by student name, code...' onKeyUp={(event) => searchstudent(event)} />
                </div>
                <div className='col-12 p-0 text-end'>
                    <Button color='warning' onClick={() => history.push(`${url}/payment-type`)} >Add payment type</Button>
                    <Button color='warning' onClick={() => history.push(`/payment-detail`)} >Payment details</Button>
                </div>
                <div className='col-md-5 col-12 mx-auto mt-4'>
                    {loading ? <LinearProgress color='warning' /> :
                    studentwarn ? <Alert severity='warning'>{studentwarn}</Alert> :
                        students.map((student, i) =>
                        <div className='row bggrey rounded-3 mb-2 overflow-hidden' key={i} >
                            <div className='col-md-9 col-8 p-3' >
                                <h5 className='fw-bold'>{student.s_name}</h5>
                                <p>Code: {student.s_code}</p>
                                <IconButton onClick={() => history.push(`${url}/configure/${student.studentid}`)} ><CreateIcon /></IconButton>
                                <IconButton onClick={() => history.push(`${url}/pay/${student.studentid}`)} ><AddIcon /></IconButton>
                            </div>
                            <div className='col-md-3 col-4 overflow-hidden p-0'>
                                <Avatar src={`/instituteassets/studentimg/${student.s_pic}`} style={{objectFit: 'cover', height: '100%', width: '100%', borderRadius: '0'}} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </div>
        <Route path={`${path}/payment-type`} component={PaymentType} />
        <Route path={`${path}/configure/:studentid`} component={ConfigureFee} />
        <Route path={`${path}/pay/:studentid`} component={UploadPayment} />
        </>
    );
    
    function PaymentType(){

        const [type, setType] = useState('')
        const [count, setCount] = useState(0)

        useEffect(() => {
            viewpaymenttypes()
        }, [])

        const [paymenttypes, setPaymenttypes] = useState([])
        const [typeswarn, setTypeswarn] = useState('')
        const viewpaymenttypes = () => {
            setTypeswarn('')
            var formData = new FormData()
            formData.append('payment-types', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setTypeswarn(data.warning)
                } else {
                    setPaymenttypes(data)
                }
            })
        }

        const [uploading, setUploading] = useState(false)
        const uploadpaymenttype = () => {
            setUploading(true)
            var formData = new FormData()
            formData.append('configure-payment-type', type)
            formData.append('count', count)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                setType('')
                setCount(0)
                if(data.success){
                    viewpaymenttypes()
                }
            })
        }

        const [tableid, setTableid] = useState('')
        const deletetype = (tableid) => {
            setTableid(tableid)
            var formData = new FormData()
            formData.append('delete-payment-type', tableid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setTableid('')
                if(data.success){
                    viewpaymenttypes()
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Payment type' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField fullWidth type='text' variant='standard' label='Payment type' value={type} onChange={(event) => setType(event.target.value)} />
                                <FormControl variant='standard' fullWidth className='mt-4' >
                                    <InputLabel>Count</InputLabel>
                                    <Select value={count} onChange={(event) => setCount(event.target.value)} >
                                        <MenuItem value='0' >No</MenuItem>
                                        <MenuItem value='1' >Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button color='warning' size='large' variant='contained' disabled={uploading} onClick={() => uploadpaymenttype()} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                            </div>
                            <div className='col-12 mt-4'>
                                {typeswarn ? <Alert severity='warning'>{typeswarn}</Alert> :
                                <TableContainer component={Paper} >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Payment type</TableCell>
                                                <TableCell align='center' >Count</TableCell>
                                                <TableCell  align='right'  >Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {paymenttypes.map((type, i) =>
                                            <TableRow key={i} >
                                                <TableCell>{type.pt_type}</TableCell>
                                                <TableCell align='center' >{type.pt_count === '1' ? 'Yes' : 'NO' }</TableCell>
                                                <TableCell align='right' ><IconButton onDoubleClick={() => deletetype(type.pt_table_id)} >{type.pt_table_id === tableid ? <CircularProgress color='inherit' size={24} /> : <DeleteIcon />}</IconButton></TableCell>
                                            </TableRow>)}
                                        </TableBody>
                                    </Table>
                                </TableContainer> }
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function ConfigureFee(){
        const { studentid } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [fee, setFee] = useState('')
        const [discount, setDiscount] = useState('')
        const [uploading, setUploading] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('view-fee', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setFee(data.pf_amount); setDiscount(data.pf_discount)
                }
            })
        }, [studentid])

        const configurefee = () => {
            setUploading(true)
            var formData = new FormData()
            formData.append('configure-fee', studentid)
            formData.append('fee', fee)
            formData.append('discount', discount)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setMessage(data.warning)
                } else {
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Configure fees' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField variant='standard' fullWidth type='number' label='Total fees' value={fee} onChange={(event) => setFee(event.target.value)} />
                                <TextField variant='standard' fullWidth type='number' label='Discount' className='mt-4' value={discount} onChange={(event) => setDiscount(event.target.value)} />
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button color='warning' size='large' variant='contained' disabled={uploading} onClick={() => configurefee()} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function UploadPayment(){
        const { studentid } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [name, setName] = useState('')
        const [code, setCode] = useState('')
        const [payed, setPayed] = useState('')
        const [fee, setFee] = useState('')
        const [amount, setAmount] = useState('')
        const [count, setCount] = useState('')
        const [desc, setDesc] = useState('')
        const [uploading, setUploading] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('left-payment', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setPayed(data.payed); setFee(data.fee); setName(data.s_name); setCode(data.s_code)
                }
            })
        }, [studentid])

        const confiugre = () => {
            setUploading(true)
            var formData = new FormData()
            formData.append('configure-payment', studentid)
            formData.append('amount', amount)
            formData.append('desc', desc)
            formData.append('count', count)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.success){
                    viewFees(studentid); setAmount(''); setDesc(''); setCount('')
                }
            })
        }

        const [paymenttypes, setPaymenttypes] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('payment-types', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning) {
                    setPaymenttypes(data)
                }
            })
        }, [])

        const [paymentwarn, setPaymentwarn] = useState('')
        const [paymenthis, setPaymenthis] = useState([])
        useEffect(() => {
            viewFees(studentid)
        }, [studentid])

        const viewFees = (studentid) => {
            setPaymentwarn('')
            var formData = new FormData()
            formData.append('view-fees', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setPaymentwarn(data.warning)
                } else {
                    setPaymenthis(data)
                }
            })
        }
        
        const [deleting, setDeleting] = useState('')
        const deletepayment = (paymentid) => {
            setDeleting(paymentid)
            var formData = new FormData()
            formData.append('delete-payment', studentid)
            formData.append('paymentid', paymentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.success){
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='md' onClose={() => history.go(-1)} scroll='body' >
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Payment' /></DialogTitle><Divider/>
                <DialogContent>
                    <div className='container-fluid mb-4'>
                        <div className='row'>
                            <div className='col-6 mb-2'>
                                <div className='bggrey p-3 flex text-center text-break'>
                                    <h5 className='fw-bold text-truncate'>{name}</h5>
                                </div>
                            </div>
                            <div className='col-6 mb-2'>
                                <div className='bggrey p-3 flex text-center text-break'>
                                    <h5 className='fw-bold'>{code}</h5>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='bggrey p-3 flex text-center text-break'>
                                    <h5 className='fw-bold' >Payed: {Number(payed).toLocaleString('en-in')} &#8377;</h5>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='bggrey p-3 flex text-center text-break'>
                                    <h5 className='fw-bold' >Left: {(Number(fee) - Number(payed) ).toLocaleString('en-in')} &#8377;</h5>
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div className='bggrey p-3 flex text-center text-break'>
                                    <h5 className='fw-bold' >Fee: {Number(fee).toLocaleString('en-in')} &#8377;</h5>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-12'>
                                <TextField fullWidth type='number' variant='standard' label='Amount' className='mb-4' value={amount} onChange={(event) => setAmount(event.target.value)} />
                                <FormControl variant='standard' fullWidth >
                                    <InputLabel>Description</InputLabel>
                                    <Select value={desc} onChange={(event) => setDesc(event.target.value)} >
                                        {paymenttypes.map((type, i) =>
                                        <MenuItem key={i} value={type.pt_type} onClick={() => setCount(type.pt_count)} >{type.pt_type}</MenuItem> )}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button color='warning' size='large' variant='contained' disabled={uploading} onClick={() => confiugre()} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            {paymentwarn ? <Alert severity='warning'>{paymentwarn}</Alert> :
                            <TableContainer component={Paper} >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>SR.NO.</TableCell>
                                            <TableCell>Payment ID</TableCell>
                                            <TableCell align='center' >Amount</TableCell>
                                            <TableCell align='center' >Description</TableCell>
                                            <TableCell align='center' >Date</TableCell>
                                            <TableCell align='right' >Delete</TableCell>
                                            <TableCell align='right' >View</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paymenthis
                                        .sort((a, b) => new Date(b.p_date) - new Date(a.p_date))
                                        .map((his, i) =>
                                        <TableRow key={i} >
                                            <TableCell>{i+1}</TableCell>
                                            <TableCell>{his.paymentid}</TableCell>
                                            <TableCell align='center' >{his.p_amount}</TableCell>
                                            <TableCell align='center' >{his.p_description}</TableCell>
                                            <TableCell align='center' >{his.p_date}</TableCell>
                                            <TableCell align='right' ><IconButton onDoubleClick={() => deletepayment(his.paymentid)} >{deleting === his.paymentid ? <CircularProgress color='inherit' size={24} /> : <DeleteIcon />}</IconButton></TableCell>
                                            <TableCell align='right' ><IconButton onClick={() => history.push(`/invoice/${his.studentid}/${his.paymentid}`)} ><PrintIcon /></IconButton></TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer> }
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

}
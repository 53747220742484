import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import Bar from './../bar'

/* MUI */
import { Box, Dialog, DialogContent, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

export default function Invoice({api, img}){
    const { studentid } = useParams()
    const { paymentid } = useParams()

    const [view, setView] = useState([])

    useEffect(() => {
        var formData = new FormData()
        formData.append('print-invoice', paymentid)
        formData.append('studentid', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setView(data)
            }
        })
    }, [studentid, paymentid])

    const area = useRef()

    return(
        <Dialog open={true} fullScreen>
            <Bar text='Payment slip' printTrue={true} printArea={area} share={true} />
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} md={7} sx={{background: '#fafafa'}} overflow='scroll' mx='auto' >
                        <Box ref={area} padding={4} pl={6} pr={6} sx={{width: '210mm', height: '295mm'}} >
                            {[1, 1].map((i) =>
                            <Box key={i} mb={5}  >
                                <Box>
                                    <Typography variant='h4' noWrap fontWeight='bold' >{view.b_name}</Typography>
                                    <Typography variant='body1'>{view.b_address}</Typography>
                                    <Typography variant='body2' >{view.b_city} - {view.b_code}</Typography>
                                    <Typography>+91-{view.b_omobile}</Typography>
                                </Box>
                                <Box padding={0.4} sx={{background: '#ef6c00'}} mt={1.5} ></Box>

                                <Box mt={2} >
                                    <Table size='small' >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Student Code</TableCell>
                                                <TableCell>{view.s_code}</TableCell>

                                                <TableCell>Date</TableCell>
                                                <TableCell>{view.p_date}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Invoice Number</TableCell>
                                                <TableCell>{paymentid}</TableCell>

                                                <TableCell>Course</TableCell>
                                                <TableCell>{view.c_title}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Student Name</TableCell>
                                                <TableCell>{view.s_name}</TableCell>

                                                <TableCell>Mobile</TableCell>
                                                <TableCell>{view.s_mobile}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Table sx={{marginTop: 3}} size='small' >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Description</TableCell>
                                                <TableCell align='right' >Amount (&#8377;)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{view.p_description}</TableCell>
                                                <TableCell align='right' >{Number(view.p_amount).toLocaleString('en-in')} (&#8377;)</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                                
                                <Stack direction='row' justifyContent='space-between' alignItems='center' >
                                    <Box>
                                        <Typography color='gray' variant='body2' >*This is a computer generated invoice.</Typography>
                                        <Typography color='gray' variant='body2' >*If you have any queries, kindly call us at the given number..</Typography>
                                    </Box>
                                    <Box mt={3} >
                                        <Box textAlign='right' >
                                            <img src={img+view.b_sig} alt='Center Signature' style={{width: '150px', height: '50px'}} />
                                        </Box>
                                        <Typography variant='body1' align='right' color='gray' mb={1} >Authorized signature</Typography>
                                    </Box>
                                </Stack>

                                <Box mt={12} ></Box>
                            </Box>)}
                        </Box>
                    </Grid>
                </Grid>
                
                {/*}
                <div className='container-fluid'>
                    <div className='row p-3' id='printArea' >
                        <div className='col-12 p-3 rounded-3 text-light' style={{background: '#eeeeee'}} >
                            <h1 className='fw-bold lspace5 officialcolor' >{view.b_name}</h1>
                            <small className='officialcolor' >{view.b_address}, {view.b_city}</small><br/>
                            <small className='officialcolor' >Mobile: {view.b_omobile}</small>
                        </div>
                        <div className='p-3 rounded-3 mt-2' style={{background: '#eeeeee'}} >
                            <div className='col-12 mt-2'>
                                <table className='table table-borderless' >
                                    <tbody>
                                        <tr><td>Student code</td><td>{view.s_code}</td><td>Date</td><td>{view.p_date}</td></tr>
                                        <tr><td>Slip No</td><td>{paymentid}</td><td>Course</td><td>{view.c_title}</td></tr>
                                        <tr><td>Name</td><td>{view.s_name}</td><td>Mobile</td><td>{view.s_mobile}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-12'>
                                <table className='table table-borderless'>
                                    <thead style={{background: '#cccccc'}} >
                                        <tr><th className='fw-bold' >Description</th><th className='fw-bold text-end' >Amount (&#8377;)</th></tr>
                                    </thead>
                                    <tbody >
                                        <tr ><td>{view.p_description}</td><td className='text-end' >{Number(view.p_amount).toLocaleString('en-in')}</td></tr>
                                        <tr ><td></td><td className='text-end' ></td></tr>
                                    </tbody>
                                    <tfoot style={{background: '#cccccc'}} >
                                        <tr ><td className='text-center' ></td><td className='text-end' >Total - {Number(view.p_amount).toLocaleString('en-in')}</td></tr>
                                    </tfoot>
                                </table>
                                <div className='col-12 text-end'>
                                    <h5 className='fw-bold'>Authorized signature</h5>
                                </div>
                            </div>
                        </div><hr/>
                        <div className='col-12 p-3 rounded-3 text-light' style={{background: '#eeeeee'}} >
                            <h1 className='fw-bold lspace5 officialcolor' >{view.b_name}</h1>
                            <small className='officialcolor' >{view.b_address}, {view.b_city}</small><br/>
                            <small className='officialcolor' >Mobile: {view.b_omobile}</small>
                        </div>
                        <div className='p-3 rounded-3 mt-2' style={{background: '#eeeeee'}} >
                            <div className='col-12 mt-2'>
                                <table className='table table-borderless' >
                                    <tbody>
                                        <tr><td>Student code</td><td>{view.s_code}</td><td>Date</td><td>{view.p_date}</td></tr>
                                        <tr><td>Slip No</td><td>{paymentid}</td><td>Course</td><td>{view.c_title}</td></tr>
                                        <tr><td>Name</td><td>{view.s_name}</td><td>Mobile</td><td>{view.s_mobile}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-12'>
                                <table className='table table-borderless'>
                                    <thead style={{background: '#cccccc'}} >
                                        <tr><th className='fw-bold' >Description</th><th className='fw-bold text-end' >Amount (&#8377;)</th></tr>
                                    </thead>
                                    <tbody >
                                        <tr ><td>{view.p_description}</td><td className='text-end' >{Number(view.p_amount).toLocaleString('en-in')}</td></tr>
                                        <tr ><td></td><td className='text-end' ></td></tr>
                                    </tbody>
                                    <tfoot style={{background: '#cccccc'}} >
                                        <tr ><td className='text-center' ></td><td className='text-end' >Total - {Number(view.p_amount).toLocaleString('en-in')}</td></tr>
                                    </tfoot>
                                </table>
                                <div className='col-12 text-end'>
                                    <h5 className='fw-bold'>Authorized signature</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{*/}
            </DialogContent>
        </Dialog>
    );
}
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import QRCode from "react-qr-code";
import { useReactToPrint } from 'react-to-print';

/* MUI */
import { AppBar, Box, Button, Dialog, DialogContent, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import { ArrowBack, WhatsApp, Print } from '@mui/icons-material';

export default function Marksheet({api, bimg}){
    const { studentid } = useParams()
    let history = useHistory()

    const [print, setPrint] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('computer-certificate-detail', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPrint(data)
            }
        })
    }, [studentid])

    const [results, setResults] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('marksheet-result', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setResults(data)
            }
        })
    }, [studentid])

    useEffect(()  => {
        document.title = print.s_name
    }, [print])

    const area = useRef()
    const printit = useReactToPrint({
        content:() => area.current
    })

    const [admin, setAdmin] = useState(true)
    const [alertDia, setAlertDia] = useState(false)
    useEffect(() => {
        fetch('/instituteapi/admin/ui.php')
        .then(res => res.json())
        .then(data => {
            if(data.loggedin === true){
                setAdmin(true); setAlertDia(true)
            } else {
                setAdmin(false)
            }
        })
    }, [])

    var marks = Number((Number(print.marks)*100)/Number(print.fullMarks))

    return(
        <Box>
            <AppBar position="sticky" component='nav' color="warning">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => history.go(-1)}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography sx={{flexGrow: '1'}} >CERTIFICATE</Typography>
                    <Button color='inherit' size="small" onClick={() => history.push(`/certificate/${studentid}`)} >Certificate</Button>
                    <Box><IconButton color="inherit" onClick={() => printit()} ><Print/></IconButton></Box>
                    <a href={`https://wa.me/91${print.s_mobile}/?text=${window.location.href}`} target="_blank" style={{color: 'white'}} ><IconButton color="inherit" ><WhatsApp/></IconButton></a>
                </Toolbar>
            </AppBar>
            <div className='col-md-8 col-12 mx-auto text-center overflow-auto mt-5'>
                <Box id="pdfContent" ref={area} sx={{position: 'relative', width: '210mm', height: '296mm'}} >
                    <img src='/cert/marksheet.webp' alt='Marksheet' style={{width: '210mm', height: '296mm'}} />

                    <QRCode size={104} style={{left: '16mm', top: '30mm', position: 'absolute'}} value={`The certificate issued by 'NEXUS EDUCATION & WELFARE FOUNDATION' from franchise '${print.b_name} (${print.b_code})' of ${print.s_name} (Registration number: ${print.s_enrollment}) on ${print.s_issuedate} of course ${print.c_name}`} />
                    <img src={`/instituteassets/studentimg/${print.s_pic}`} style={{right: '16mm', top: '30mm', position: 'absolute', width: '110px', height: '120px'}} />

                    <Typography variant='body2' sx={{position: 'absolute', left: '58mm', top: '79.2mm'}} >{print.s_name}</Typography>
                    <Typography variant='body2' sx={{position: 'absolute', left: '58mm', top: '86.2mm'}} >{print.s_guardian}</Typography>
                    <Typography variant='body2' sx={{position: 'absolute', left: '58mm', top: '93.5mm'}} >{print.b_name}</Typography>
                    <Typography variant='body2' sx={{position: 'absolute', left: '58mm', top: '100.5mm'}} >{print.b_code}</Typography>

                    <Typography variant='body2' sx={{position: 'absolute', left: '74mm', top: '109.7mm'}} >{print.s_enrollment}</Typography>
                    <Typography variant='body2' sx={{position: 'absolute', left: '165mm', top: '109.7mm'}} >{print.s_issuedate}</Typography>

                    <Typography variant='body2' sx={{position: 'absolute', left: '35mm', top: '117.7mm'}} >{print.c_duration} Months</Typography>

                    <Typography variant='body2' sx={{position: 'absolute', left: '70mm', top: '117.7mm', color: '#ef6c00', fontWeight: 'lighter', fontSize: '17px'}} fontFamily='serif' >Certificate No. :</Typography>
                    <Typography variant='body2' sx={{position: 'absolute', left: '101mm', top: '118.7mm'}} >{print.cert_no}</Typography>

                    {Number(print.s_aadhaar) > 0 &&<>
                    <Typography variant='body2' sx={{position: 'absolute', left: '136mm', top: '117.7mm', color: '#ef6c00', fontWeight: 'light', fontSize: '17px'}} fontFamily='serif' >Aadhaar No. :</Typography>
                    <Typography variant='body2' sx={{position: 'absolute', left: '164mm', top: '118.7mm'}} >{print.s_aadhaar}</Typography></>}
                    
                    <Box sx={{position: 'absolute', top: '133.7mm', width: '210mm'}} >
                        <Typography align='center' variant='body2' >{print.c_name} - {print.c_title}</Typography>
                    </Box>
                    
                    { print.module === '1' ?
                    <Box sx={{position: 'absolute', top: '150mm', left: '15mm'}} >
                    { results.map((data, i) =>
                    <Stack direction='row' alignItems='center' key={i} sx={{width: '179.5mm'}} >
                        <Box sx={{width: '70mm', marginLeft: 1}} mb={1} >
                            <Typography align='left' fontSize={12} variant='body1' fontWeight='bold' >Module {data.s_term}</Typography>
                            <Typography align='left' fontSize={12} >{data.s_subject}</Typography>
                        </Box>
                        <Box sx={{width: '30mm'}} >
                            <Typography align='center' >{data.s_fmark}</Typography>
                        </Box>
                        <Box sx={{width: '37.5mm'}} >
                            <Typography align='center' >{data.r_marks}</Typography>
                        </Box>
                        <Box sx={{width: '41mm'}} >
                            <Typography align='center' >{data.r_marks >= 85 ? 'A+' : data.r_marks >= 70 ? 'A' : data.r_marks >= 60 ? 'B' : data.r_marks >= 45 && 'C' }</Typography>
                        </Box>
                    </Stack>) }
                    </Box> :
                    <Box sx={{position: 'absolute', top: '150mm', left: '15mm'}} >
                    { results.map((data, i) =>
                    <Stack direction='row' alignItems='center' key={i} sx={{width: '179.5mm'}} >
                        <Box sx={{width: '70mm', marginLeft: 1}} mb={1} >
                            <Typography align='left' variant='body2' fontSize={12} >{data.s_subject}</Typography>
                        </Box>
                        <Box sx={{width: '30mm'}} >
                            <Typography align='center' fontSize={12} >{data.s_fmark}</Typography>
                        </Box>
                        <Box sx={{width: '37.5mm'}} >
                            <Typography align='center' fontSize={12} >{data.r_marks}</Typography>
                        </Box>
                        <Box sx={{width: '41mm'}} >
                            <Typography align='center' fontSize={12} >{data.r_marks >= 85 ? 'A+' : data.r_marks >= 70 ? 'A' : data.r_marks >= 60 ? 'B' : data.r_marks >= 45 ? 'C' : 'D' }</Typography>
                        </Box>
                    </Stack>) }
                    </Box>
                    }   

                    <Box sx={{position: 'absolute', left: '85.5mm', top: '230mm', width: '31mm'}} >
                        <Typography align='center' color='white' >{print.fullMarks}</Typography>
                    </Box>
                    <Box sx={{position: 'absolute', left: '116.5mm', top: '230mm', width: '37.5mm'}} >
                        <Typography align='center' color='white' >{print.marks}</Typography>
                    </Box>
                    
                    <Typography sx={{position: 'absolute', left: '151mm', bottom: '44.8mm'}} >{marks.toFixed(2)}%</Typography>
                    <Typography sx={{position: 'absolute', right: '44mm', bottom: '36.8mm'}} >{ marks >= 85 ? 'A+' : marks >= 70 ? 'A' : marks >= 60 ? 'B' : marks >= '45' ? 'C' : 'D' }</Typography>

                    <Typography sx={{position: 'absolute', left: '116.5mm', bottom: '13.5mm'}} variant='body2' >{print.issue_year >= '2023' && 'NGO Darpan Unique ID - BR/2022/0316689'}</Typography>

                    {print.issue_year >= '2023' && admin === false && <><img src={bimg+print.s_center_sig} alt="Center head signature" style={{width: '110px', height: '40px', position: 'absolute', bottom: '10%', left: '7%'}} />
                    <img src="/cert/seal.png" alt="Center head signature" style={{width: '70px', position: 'absolute', bottom: '8.6%', left: '22.5%'}} />
                    <img src="/cert/sig-head.png" alt="Center head signature" style={{width: '100px', position: 'absolute', bottom: '9%', left: '34%'}} />
                    <Box sx={{position: 'absolute', bottom: '15%', left: '7%', width: '108mm'}} >
                        <Typography color='red' align='left' variant='body1' >This is a computer-generated certificate and is digitally signed</Typography>
                    </Box></>}

                </Box>
            </div>
            <Dialog open={alertDia} fullWidth>
                <DialogContent>
                    <Box sx={{padding: {md: 2}}} >
                        <Typography variant='h5' color='red' >Hey! You are logged in as Admin</Typography>
                        <Typography variant='caption' color='gray'>If you want to download the digital certificate, kindly log out of the admin account. Or log in to another browser.</Typography>
                        <Box textAlign='center' mt={3} >
                            <Button variant='outlined' onClick={() => setAlertDia(false)} >OK, GOT IT</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import QRCode from "react-qr-code";

import SidebarBranch from './sidebar'
import HeaderBranch from './header'

/* MUI */
import { Alert, Avatar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { ArrowForwardIos, Assessment, AutoAwesome, CurrencyRupee, Person, QrCode } from '@mui/icons-material'
import axios from 'axios';

export default function DashboardBranch({api, fullscreen}){
    let history = useHistory()
    const [action, setAction] = useState('')

    const date = new Date()

    const preMonthDate = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    const pre_month = preMonthDate.toLocaleString('default', {month: 'long'})

    const [dash, setDash] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('dashboard-preview', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setDash(data)
            }
        })
    }, [api])

    const [qrAttend, setQrAttend] = useState('')
    const [openQr, setOpenQr] = useState(false)
    const qrAttendance = () => {
        setOpenQr(true)
        var formData = new FormData()
        formData.append('view-qr', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setQrAttend(data.qr)
            }
        })
    }

    const [enquiry, setEnquiry] = useState([])
    const [enqWarn, setEnqWarn] = useState('')
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-enquiry', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setEnqWarn(data.warning)
            } else {
                setEnquiry(data)
            }
        })
    }, [api])

    const [batchAttend, setBatchAttend] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('batch-attend', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBatchAttend(data)
            }
        })
    }, [api])

    const [icourse, setIcourse] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('issued-course-student', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setIcourse(data)
            }
        })
    }, [])

    const [cstudent, setCstudent] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('course-student', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setCstudent(data)
            }
        })
    }, [])

    const [batch, setBatch] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-batches', '')
        axios.post(api, formData)
        .then(function(res){
            setBatch(res.data)
        })
    }, [])

    return(
        <>
        <Box className='sidebar'> <SidebarBranch /> </Box>
        <Box className='content'>
        <HeaderBranch />
        <Container sx={{marginBottom: {xs: '4em', md: '2em'}}} >
            <Grid container mt={3} >
                <Grid item sx={{background: '#9EEEFF'}} xs={12} padding={3} borderRadius={2} >
                    <Stack direction='row' alignItems='center' justifyContent='space-between' >
                        <Typography variant='h6' noWrap fontWeight='bold' >Hello, {dash.b_oname}</Typography>
                        <Avatar src={`/instituteassets/branchimg/${dash.b_pic}`} />
                    </Stack>
                </Grid>
            </Grid>
            <Grid mt={2} container sx={{background: '#BBFFFB'}} pt={4} pb={4} borderRadius={2} >
                <Grid item xs={6} md={3} >
                    <Stack justifyContent='center' alignItems='center' >
                        <Person sx={{fontSize: 80}} />
                        <Typography variant='caption' letterSpacing={4} >STUDENTS</Typography>
                        <Typography variant='h6' fontWeight='bold' letterSpacing={3} >{dash.students}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={3} >
                    <Stack justifyContent='center' alignItems='center' >
                        <Person sx={{fontSize: 80}} />
                        <Typography variant='caption' letterSpacing={4} >PENDING</Typography>
                        <Typography variant='h6' fontWeight='bold' letterSpacing={3} >{dash.stu_pending}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={3} >
                    <Stack justifyContent='center' alignItems='center' >
                        <Person sx={{fontSize: 80}} />
                        <Typography variant='caption' letterSpacing={4} >ACTIVE</Typography>
                        <Typography variant='h6' fontWeight='bold' letterSpacing={3} >{dash.stu_active}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={3} >
                    <Stack justifyContent='center' alignItems='center' >
                        <Person sx={{fontSize: 80}} />
                        <Typography variant='caption' letterSpacing={4} >ISSUED</Typography>
                        <Typography variant='h6' fontWeight='bold' letterSpacing={3} >{dash.stu_issued}</Typography>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container mt={0} borderRadius={2} spacing={2} >
                <Grid item xs={12} md={2}>
                    <Box sx={{background: '#FFF8B6'}} p={2} borderRadius={2} height={250} >
                        <Stack direction='row' alignItems='center' justifyContent='space-between' >
                            <Typography variant='caption' noWrap >QR attendace</Typography>
                            <IconButton onClick={() => qrAttendance()} ><QrCode /></IconButton>
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' >
                            <Typography variant='caption' noWrap >Student list</Typography>
                            <IconButton onClick={() => history.push('/student-list-branch')} ><Person /></IconButton>
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' >
                            <Typography variant='caption' noWrap >Payment report</Typography>
                            <IconButton onClick={() => history.push('/month-payment-report')} ><Assessment /></IconButton>
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' >
                            <Typography variant='caption' noWrap >Generate Ques.</Typography>
                            <IconButton onClick={() => history.push(`/question-generator/${dash.branchid}`)} ><AutoAwesome /></IconButton>
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' >
                            <Typography variant='caption' noWrap >Test report</Typography>
                            <IconButton onClick={() => setAction('openfortest')} ><Assessment /></IconButton>
                        </Stack>
                    </Box>

                    <Dialog open={openQr} onClose={() => setOpenQr(false)}>
                        <DialogContent>
                            <QRCode value={qrAttend} />
                            <Typography align='center' variant='body1' mt={1} color='gray' >Scan to make attendance</Typography>
                        </DialogContent>
                    </Dialog>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{background: '#B6FFE5'}} p={2} borderRadius={2} height={250} overflow='scroll' >
                        <Typography variant='caption' >ATTEND TODAY - {batchAttend.reduce((total, i) => total+Number(i.attend), 0)}</Typography>

                        {batchAttend.map((data, i) =>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' mt={2} key={i} >
                            <Typography>{data.start}</Typography>
                            <Typography>{data.attend}</Typography>
                        </Stack>)}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{background: '#B6FFE5'}} p={2} borderRadius={2} height={250} overflow='scroll' >
                        <Typography variant='caption'>PAYMENT</Typography>

                        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={2} >
                            <Typography variant='body1' >{date.toLocaleString('default', {weekday: 'long'})}</Typography>
                            <Typography variant='body1' fontWeight='bold' >{Number(Number(dash.earned_today) + Number(dash.earned_today_ins) ).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={2} >
                            <Typography variant='body1' >{date.toLocaleString('default', {month: 'long'})}</Typography>
                            <Typography variant='body1' fontWeight='bold' >{Number(Number(dash.earned_month) + Number(dash.earned_month_ins) ).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={2} mb={2} >
                            <Typography variant='body1' >{date.toLocaleString('default', {month: 'long'})} - Expance</Typography>
                            <Typography variant='body1' fontWeight='bold' >{Number(dash.expand_month).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack><Divider/>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={2} >
                            <Typography variant='body1' >Calculated</Typography>
                            <Typography variant='body1' fontWeight='bold' >{Number(Number(dash.earned_month) + Number(dash.earned_month_ins) - Number(dash.expand_month) ).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>

            <Grid container mt={0} spacing={2} >
                <Grid item xs={12} md={3}>
                    <Box sx={{background: '#b9f6ca'}} p={2} borderRadius={2} height={350} >
                        <Typography variant='caption' >BALANCE</Typography>

                        <Typography variant='h6' mt={2} >{date.toLocaleString('default', {month: 'long'})}</Typography>
                        <Stack direction='row'justifyContent='space-between' >
                            <Typography color='gray' >Income</Typography>
                            <Typography variant='h6' >{Number(Number(dash.earned_month) + Number(dash.earned_month_ins) ).toLocaleString('en-in')} <CurrencyRupee/> </Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between' >
                            <Typography color='gray' >Expance</Typography>
                            <Typography variant='h6' >{Number(dash.expand_month).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack><Divider/>

                        <Typography variant='h6'>{pre_month}</Typography>
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography color='gray' >Income</Typography>
                            <Typography variant='h6' >{Number(Number(dash.earned_pre_month) + Number(dash.earned_pre_month_ins) ).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography color='gray' >Expance</Typography>
                            <Typography variant='h6' >{Number(dash.expand_pre_month).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography color='gray' >Closing balance</Typography>
                            <Typography variant='h6' >{Number(dash.c_balance).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack><Divider/>

                        <Stack direction='row' mt={1} >
                            <Typography sx={{flexGrow: 1}} >Balance</Typography>
                            <Typography variant='h6' >{Number( Number(dash.c_balance) + Number(dash.earned_month) + Number(dash.earned_month_ins) - Number(dash.expand_month)).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                    </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Box sx={{background: '#ced4da'}} p={2} borderRadius={2} height={350} overflow='scroll' >
                        <Typography variant='caption' >ISSUED COURSE STUDENTS - {icourse.reduce((t, i) => t+Number(i.students), 0)}</Typography>
                        
                        { icourse.map((data, i) =>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={2} key={i} >
                            <Typography variant='body1' >{data.c_title === 'TYPING' ? data.c_name + ' TYPING' : data.c_title } - {data.c_duration} Months</Typography>
                            <Typography variant='body1' fontWeight='bold' >{data.students}</Typography>
                        </Stack>) }
                    </Box>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Box sx={{background: '#ced4da'}} p={2} borderRadius={2} height={350} overflow='scroll' >
                        <Typography variant='caption' >COURSE STUDENTS - {cstudent.reduce((t, i) => t+Number(i.students), 0)}</Typography>
                        
                        { cstudent.map((data, i) =>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={2} key={i} >
                            <Typography variant='body1' >{data.c_title === 'TYPING' ? data.c_name + ' TYPING' : data.c_title } - {data.c_duration} Months</Typography>
                            <Typography variant='body1' fontWeight='bold' >{data.students}</Typography>
                        </Stack>) }
                    </Box>
                </Grid>
                
                <Grid item xs={12} md={12}>
                    <Box sx={{background: '#FFFCBA'}} p={2} borderRadius={2} height={350} overflow='scroll' >
                        <Typography variant='caption' >ENQUIRY</Typography>
                        
                        { enqWarn ? <Alert severity='warning'>{enqWarn}</Alert> :
                        enquiry.map((data, i) =>
                        <Box key={i} mt={1} >
                            <Typography>{data.en_name} taking interest on {data.en_course}.</Typography>
                            <Typography variant='caption' >Mobile Number: {data.en_mobile}</Typography> <Divider/>
                        </Box>)}
                    </Box>
                </Grid>
            </Grid>
        </Container>
        <Dialog open={action === 'openfortest' ? true : false} fullWidth maxWidth='xs' fullScreen={fullscreen} onClose={() => setAction('')} >
            <DialogTitle>Select batch for report</DialogTitle><Divider/>
            <DialogContent>
                { batch.warning ? <Alert severity='warning'>{batch.warning}</Alert> :
                batch.map((row, i) =>
                <List key={i} >
                    <ListItem>
                        <ListItemButton onClick={() => history.push(`/test-report-students/${row.batchid}`)} >
                            <ListItemIcon>{i+1}</ListItemIcon>
                            <ListItemText>{row.bh_start} to {row.bh_end}</ListItemText>
                            <ArrowForwardIos />
                        </ListItemButton>
                    </ListItem><Divider/>
                </List> )}
            </DialogContent><Divider/>
            <DialogActions>
                <Button variant='outlined' color='inherit' onClick={() => setAction('')} >Close</Button>
            </DialogActions>
        </Dialog>
        </Box>
        </>
    );
}
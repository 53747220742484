import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import QRCode from "react-qr-code";
import html2pdf from 'html2pdf.js';

// MUI
import { AppBar, Box, Button, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import { Download, WhatsApp } from "@mui/icons-material";

export default function TypingCertificate({api, bimg}){
    let history = useHistory()
    let { studentid } = useParams()

    const area = useRef()
    const printit = useReactToPrint({
        content:() => area.current
    })

    const handleDownload = () => {
        const content = area.current;
        html2pdf().set({
            filename: print.s_name,
            jsPDF: { orientation: 'landscape' }
        }).from(content).save();
    };

    const [print, setPrint] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('computer-certificate-detail', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPrint(data)
            }
        })
    }, [studentid])

    const [admin, setAdmin] = useState(true)
    const [alertDia, setAlertDia] = useState(false)
    useEffect(() => {
        fetch('/instituteapi/admin/ui.php')
        .then(res => res.json())
        .then(data => {
            if(data.loggedin === true){
                setAdmin(true); setAlertDia(true)
            } else {
                setAdmin(false)
            }
        })
    }, [])

    useEffect(() => {
        document.title = print.s_name
    }, [print])

    return(
        <>
        <AppBar position="sticky" component='nav' color="warning">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => history.go(-1)}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography sx={{flexGrow: '1'}} >CERTIFICATE</Typography>
                <Box sx={{display: {xs: 'none', md: 'block'}}} ><IconButton color="inherit" onClick={() => printit()} ><PrintIcon/></IconButton></Box>
                <a href={`https://wa.me/91${print.s_mobile}/?text=${window.location.href}`} target="_blank" style={{color: 'white'}} ><IconButton color="inherit" ><WhatsApp/></IconButton></a>
            </Toolbar>
        </AppBar>
        <div className="container-fluid mt-5">
            <div className="row">
                <div className="col-md-9 col-12 mx-auto p-0 m-0" style={{overflow: 'auto'}} >
                    <Box id="pdfContent" ref={area} sx={{position: 'relative', width: '295mm', height: '208mm'}} >
                            <img src='/cert/typing-certificate.webp' alt='Typing Certificate'  />
                            <QRCode size={101} style={{position: 'absolute', top: '15.5%', left: '8.7%'}} value={`The certificate issued by 'NEXUS EDUCATION & WELFARE FOUNDATION' from franchise '${print.b_name} (${print.b_code})' of ${print.s_name} (Registration number: ${print.s_enrollment}) on ${print.s_issuedate} of course ${print.c_name}`} />
                            <img className="position-absolute" src={`/instituteassets/studentimg/${print.s_pic}`} alt='Print profile' style={{width: '120px', height: '130px', top: '15.5%', right: '8.5%'}} />
                            <Typography variant='body2' style={{position: 'absolute', top: '35.3%', left: '21.1%'}} >{print.b_code}</Typography>
                            <Typography variant='body2' style={{position: 'absolute', top: '35.3%', left: '56.5%'}} >{print.s_enrollment}</Typography>
                            <Typography variant='body2' style={{position: 'absolute', top: '35.3%', left: '84%'}} >{print.s_issuedate}</Typography>

                            <Typography variant='h6' style={{position: 'absolute', top: '40%', left: '8.6%', color: '#ef6c00'}} fontFamily='serif' >Certificate No. :</Typography>
                            <Typography variant='body1' style={{position: 'absolute', top: '40.8%', left: '21.3%'}} >{print.cert_no}</Typography>
                            { Number(print.s_aadhaar) > 0 && <>
                            <Typography variant='h6' style={{position: 'absolute', top: '40%', left: '71.5%', color: '#ef6c00'}} fontFamily='serif' >Aadhaar No. :</Typography>
                            <Typography variant='body1' style={{position: 'absolute', top: '40.8%', left: '82.5%'}} >{print.s_aadhaar}</Typography></>}
                            <div className="position-absolute col-12 text-center" style={{top: '48%'}}>
                                <Typography variant="h5" sx={{fontWeight: 'bold', fontFamily: 'sans-serif'}} >{print.s_name}</Typography>
                            </div>
                            <Typography variant='body1' style={{position: 'absolute', top: '58.8%', left: '18.4%'}} >{print.s_guardian}</Typography>
                            <Typography variant='body1' style={{position: 'absolute', top: '63.3%', left: '8.6%'}} >{print.c_duration}</Typography>
                            <Typography variant='body1' style={{position: 'absolute', top: '63.3%', left: '32.6%'}} >{print.c_name} ({print.c_title})</Typography>
                            <Typography variant='body1' style={{position: 'absolute', top: '67.8%', left: '22%'}} >{print.b_name}</Typography>
                            <Box sx={{position: 'absolute', top: '72.6%', left: '24.5%', width: '312px', textAlign: 'center'}} >
                                <Typography variant='body1' >{print.r_typing}</Typography>
                            </Box>
                            <Typography variant='subtitle2' style={{position: 'absolute', top: '91.7%', right: '14%'}} >{print.issue_year >= '2023' && 'NGO Darpan Unique ID - BR/2022/0316689'}</Typography>

                            {print.issue_year >= '2023' && admin === false && <><img src={bimg+print.s_center_sig} alt="Center head signature" style={{width: '100px', position: 'absolute', bottom: '12%', left: '8.7%'}} />
                            <img src="/cert/seal.png" alt="Center head signature" style={{width: '70px', position: 'absolute', bottom: '10.5%', left: '25%'}} />
                            <img src="/cert/sig-head.png" alt="Center head signature" style={{width: '100px', position: 'absolute', bottom: '12%', left: '37%'}} />
                            <Typography sx={{position: 'absolute', bottom: '16%', right: '7.5%'}} color='red' variant="body1" >This is a computer-generated certificate and is digitally signed</Typography></>}
                    </Box>
                </div>
            </div>
            <Dialog open={alertDia} fullWidth>
                <DialogContent>
                    <Box sx={{padding: {md: 2}}} >
                        <Typography variant='h5' color='red' >Hey! You are logged in as Admin</Typography>
                        <Typography variant='caption' color='gray'>If you want to download the digital certificate, kindly log out of the admin account. Or log in to another browser.</Typography>
                        <Box textAlign='center' mt={3} >
                            <Button variant='outlined' onClick={() => setAlertDia(false)} >OK, GOT IT</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div></>
    );
}
import React, { useEffect, useState } from "react";
import {  } from 'react-router-dom'
import SidebarBranchV2 from "./sidebar_v2";

// MUI
import { Alert, Box, Button, Chip, CircularProgress, Container, FormControl, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { Delete, Edit, Lock, More } from '@mui/icons-material'
import axios from "axios";

import { useSnackbar } from "notistack";

export default function TestBatchBranch({apib}){
    const { enqueueSnackbar } = useSnackbar()

    const [action, setAction] = useState('')
    const [exambatchid, setExambatchid] = useState('')

    const [subjectid, setSubjectid] = useState('')
    const [batchid, setBatchid] = useState('')
    const [batchnum, setBatchnum] = useState('a')
    const [questions, setQuestions] = useState(15)
    const [timer, setTimer] = useState(10)
    const [status, setStatus] = useState('active')

    const [subjects, setSubjects] = useState([])
    const [batches, setBatches] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('list-batch-and-subjects', '')
        axios.post(apib, formData)
        .then(function(res){
            res.data.batch.status === true && setBatches(res.data.batch.data)
            res.data.subject.status === true && setSubjects(res.data.subject.data)
        })
    }, [])

    const assign_batch = () => {
        setAction('assigning')
        var formData = new FormData()
        formData.append('assigned-subject-to-batch', batchid)
        formData.append('exambatchid', exambatchid)
        formData.append('subjectid', subjectid)
        formData.append('batch-num', batchnum)
        formData.append('questions', questions)
        formData.append('timer', timer)
        formData.append('status', status)
        axios.post(apib, formData)
        .then(function(res){
            setAction('')
            enqueueSnackbar(res.data.msg)
            if(res.data.status === true){
                assigned_batches()
                setBatchid('');
            }
        })
    }

    useEffect(() => {
        assigned_batches()
    }, [])
    const [assigned, setAssigned] = useState([])
    const assigned_batches = () => {
        var formData = new FormData()
        formData.append('assigned-batches', '')
        axios.post(apib, formData)
        .then(function(res){
            res.data.status === false ? setAssigned(res.data) : setAssigned(res.data.data)
        })
    }

    const remove_subject = (assignedsubject) => {
        if(window.confirm('Are you sure?') === true){
        setAction(assignedsubject)
        var formData = new FormData()
        formData.append('remove-subject-from-batch', assignedsubject)
        axios.post(apib, formData)
        .then(function(res){
            setAction('')
            enqueueSnackbar(res.data.msg)
            res.data.status === true && assigned_batches()
        })}
    }

    const get_subject_detail = (exambatchid) => {
        window.scroll(0, 0)
        var formData = new FormData()
        formData.append('get-batch-subject-detail', exambatchid)
        axios.post(apib, formData)
        .then(function(res){
            setAction('disable_edit')
            setSubjectid(res.data.data.subjectid); setBatchid(res.data.data.batchid); setBatchnum(res.data.data.batch_num); setQuestions(res.data.data.questions); setTimer(res.data.data.timer); setStatus(res.data.data.status)
        })
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const openmenu = (event, batchid) => {
        setExambatchid(batchid)
        setAnchorEl(event.currentTarget)
    }

    return(
        <><SidebarBranchV2/>
        <Container className="content-v2" >
            <Stack direction={{xs: 'column', md: 'row'}} spacing={1.5} mt={2} >
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Batch time</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={batchid}
                        onChange={(event) => setBatchid(event.target.value)}
                        label="Batch time"
                        disabled={action === 'disable_edit' ? true : false}
                    >
                        { batches.map((row, i) =>
                        <MenuItem value={row.batchid} key={i} >{row.bh_start} to {row.bh_end}</MenuItem> ) }
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Batch number</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={batchnum}
                        onChange={(event) => setBatchnum(event.target.value)}
                        label="Batch number"
                        disabled={action === 'disable_edit' ? true : false}
                    >
                        <MenuItem value={'a'}>A</MenuItem>
                        <MenuItem value={'b'}>B</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="Subject">Subject</InputLabel>
                    <Select
                        labelId="Subject"
                        id="Subject"
                        value={subjectid}
                        onChange={(event) => setSubjectid(event.target.value)}
                        label="Subject"
                        disabled={action === 'disable_edit' ? true : false}
                    >   
                        { subjects.map((row, i) =>
                        <MenuItem value={row.subjectid} key={i} >{row.subject}</MenuItem>) }
                    </Select>
                </FormControl>
            </Stack>
            <Stack direction={{xs: 'column', md: 'row'}} spacing={1.5} mt={1.5} >
                <TextField fullWidth variant="outlined" label='Questions' type="number" value={questions} onChange={(event) => setQuestions(event.target.value)} />
                <TextField fullWidth variant="outlined" label='Timer (in minutes)' type="number" value={timer} onChange={(event) => setTimer(event.target.value)} />

                <FormControl fullWidth>
                    <InputLabel id="Status">Status</InputLabel>
                    <Select
                        labelId="Status"
                        id="Status"
                        value={status}
                        onChange={(event) => setStatus(event.target.value)}
                        label="Status"
                    >   
                        <MenuItem value='active' >Active</MenuItem>
                        <MenuItem value='closed' >Close</MenuItem>
                    </Select>
                </FormControl>

            </Stack>
            <Box textAlign={'center'} mt={1.5} mb={1.5} >
                <Button variant="contained" color="info" sx={{width: '100%'}} onClick={() => assign_batch()} disabled={action === 'assigning' ? true : false} >{action === 'assigning' ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
            </Box>
            { assigned.status === false ? <Alert severity="warning">{assigned.msg}</Alert> :
            <TableContainer component={Paper} >
                <Table aria-label="simple table" >
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Subject</TableCell>
                            <TableCell align="left">Batch num</TableCell>
                            <TableCell align="left">Questions</TableCell>
                            <TableCell align="left">Timing</TableCell>
                            <TableCell align="right">Edit</TableCell>
                            <TableCell align="right">Remove</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { assigned.map((row, i) =>
                        row.status === 'active' &&
                        <TableRow key={i} >
                            <TableCell align="left">{row.subject}</TableCell>
                            <TableCell align="left" sx={{textTransform: 'uppercase', fontWeight: 'bold'}} >{row.bh_start} ({row.batch_num})</TableCell>
                            <TableCell align="left">{row.questions}</TableCell>
                            <TableCell align="left">{row.timer} Minutes</TableCell>
                            <TableCell align="right" ><IconButton color={row.status === 'active' ? 'success' : row.status === 'closed' && 'inherit'} onClick={() => get_subject_detail(row.exam_batch_id)} >{action === row.exam_batch_id ? <CircularProgress color="inherit" size={24} /> : <Chip label='Active' color="success" icon={<Edit/>} />}</IconButton></TableCell>
                            <TableCell align="right" ><IconButton color={'error'} onClick={() => remove_subject(row.exam_batch_id)} >{action === row.exam_batch_id ? <CircularProgress color="inherit" size={24} /> : <Delete/>}</IconButton></TableCell>
                        </TableRow> ) }
                        { assigned.map((row, i) =>
                        row.status === 'closed' &&
                        <TableRow key={i} >
                            <TableCell align="left">{row.subject}</TableCell>
                            <TableCell align="left" sx={{textTransform: 'uppercase', fontWeight: 'bold'}} >{row.bh_start} ({row.batch_num})</TableCell>
                            <TableCell align="left">{row.questions}</TableCell>
                            <TableCell align="left">{row.timer} Minutes</TableCell>
                            <TableCell align="right" ><IconButton color={row.status === 'active' ? 'success' : row.status === 'closed' && 'default'} onClick={() => get_subject_detail(row.exam_batch_id)} >{action === row.exam_batch_id ? <CircularProgress color="inherit" size={24} /> : <Chip label='Closed' color="default" icon={<Edit/>} />}</IconButton></TableCell>
                            <TableCell align="right" ><IconButton color={'error'} onClick={() => remove_subject(row.exam_batch_id)} >{action === row.exam_batch_id ? <CircularProgress color="inherit" size={24} /> : <Delete/>}</IconButton></TableCell>
                        </TableRow> ) }
                    </TableBody>
                </Table>
            </TableContainer>}
        </Container> </>
    );
}
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BrowserMultiFormatReader } from '@zxing/library';
import Header from './header';

// MUI
import { Alert, Backdrop, Box, CircularProgress, Container, Grid } from '@mui/material';

export default function ScannerStudent({api}){
    let history = useHistory()
    const videoRef = useRef(null);

    const [scan, setScan] = useState(true)
    useEffect(() => {
        const codeReader = new BrowserMultiFormatReader();
        codeReader
        .decodeFromVideoDevice(undefined, videoRef.current, (result) => {
            if (result) {
                scan === true &&
                makeattendance(result.text)
            }
        })
        .catch(err => {
            console.error('Error accessing video stream:', err);
        });
        return () => {
            codeReader.reset();
        };
    }, [])

    const [msg, setMsg] = useState([])
    const [working, setWorking] = useState(false)
    const makeattendance = (qr) => {
        setScan(false)
        setWorking(true)
        var formData = new FormData()
        formData.append('make-attendance', qr)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setScan(true)
            setWorking(false)
            if(data.status === true){
            history.replace('/attendance-history-student')
            } else {
                setMsg(data)
            }
        })
    }

    return(
        <Box><Header api={api} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={working} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                <Grid container mt={5} >
                    <Grid item xs={12} md={5} mx='auto'>
                        <video ref={videoRef} style={{width: '100%', borderRadius: 10}} ></video>
                        {msg.status === true || msg.status === false && <Alert severity={msg.status === true ? 'success' : 'error'}>{msg.msg}</Alert> }
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

/* MUI */
import { AppBar, Box, Button, CircularProgress, Dialog, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar, Typography } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import BoyIcon from '@mui/icons-material/Boy';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentIcon from '@mui/icons-material/Payment';
import AddCardIcon from '@mui/icons-material/AddCard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { MoreVert, PowerSettingsNew, QuestionAnswer } from '@mui/icons-material';

export default function SidebarBranchV2(){
    let history = useHistory()
    const { path, url } = useRouteMatch()

    const links = [
        {name: 'Dashboard', link: '/dashboard-branch', icon: <DashboardIcon/>},
        {name: 'Attendance', link: '/attendance-branch', icon: <BoyIcon/>},
        {name: 'Student', link: '/student-branch', icon: <GroupIcon/>},
        {name: 'Test', link: '/test-branch', icon: <QuestionAnswer/>},
        {name: 'Enquiry', link: '/enquiry-branch', icon: <NotificationsIcon/>},
        {name: 'Teacher', link: '/teacher-branch', icon: <GroupIcon/>},
        {name: 'Batch', link: '/batch-branch', icon: <AccessTimeIcon/>},
        {name: 'Payment', link: '/payment-branch', icon: <PaymentIcon/>},
        {name: 'Entry', link: '/entry-branch', icon: <AddCardIcon/>}
    ]

    const [open, setOpen] = useState(false)

    return(
        <>
        <AppBar color='inherit' position='sticky' elevation={1} >
            <Toolbar>
                <Typography sx={{flexGrow: 1}} fontSize={20} letterSpacing={1} fontWeight={'bold'} >NEXUS COMPUTER</Typography>
                <IconButton edge='end' color='inherit' ><PowerSettingsNew/></IconButton>
            </Toolbar>
        </AppBar>
        <Drawer variant='permanent' sx={{display: {xs: 'none', md: 'block'}}} PaperProps={{style: {background: '#ef6c00', width: '232px'}}} >
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography align='center' color='white' variant='h6' fontWeight='bold' >NEXUS COMPUTER</Typography>
                    </ListItemText>
                </ListItem><Divider/>
                { links.map((data, i) =>
                <ListItem key={i} >
                    <ListItemButton onClick={() => history.push(`${data.link}`)} >
                        <ListItemIcon sx={{color: 'white'}} >{data.icon}</ListItemIcon>
                        <ListItemText sx={{color: 'white'}} >{data.name}</ListItemText>
                    </ListItemButton>
                </ListItem>)}
            </List>
            <Button color='inherit' size='small' onClick={() => window.location.reload()} >UPDATE</Button>
        </Drawer>
        <Drawer open={open} variant='temporary' onClose={() => setOpen(false)} >
            <List>
                { links.map((data, i) =>
                <ListItem key={i} >
                    <ListItemButton onClick={() => history.push(`${data.link}`)} >
                        <ListItemIcon>{data.icon}</ListItemIcon>
                        <ListItemText>{data.name}</ListItemText>
                    </ListItemButton>
                </ListItem>)}
            </List>
            <Button color='inherit' size='small' onClick={() => window.location.reload()} >UPDATE</Button>
        </Drawer>
        <Box sx={{display: {xs: 'block', md: 'none'}}} >
            <Stack direction='row' spacing={3} justifyContent='space-around' sx={{position: 'fixed', bottom: 0, width: '100%', background: '#fafafa', zIndex: 100}} padding={0.5} >
                <IconButton onClick={() => history.push('/dashboard-branch')} ><DashboardIcon/></IconButton>
                <IconButton onClick={() => history.push('/student-branch')} ><GroupIcon/></IconButton>
                <IconButton onClick={() => history.push('/payment-branch')} ><PaymentIcon/></IconButton>
                <IconButton onClick={() => setOpen(true)} ><MoreVert/></IconButton>
            </Stack>
        </Box>
        </>
    );

}
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useRouteMatch, useParams, Route } from 'react-router-dom'

import SidebarAdmin from './sidebar'
import HeaderAdmin from './header'
import Close from './../close'

/* MUI */
import { Alert, FormControl, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, InputLabel, LinearProgress, MenuItem, Select, Snackbar, TextField, DialogActions, Divider, Avatar, Box, Stack, Typography, ButtonGroup, Grid, Table, TableBody, TableRow, TableCell, TableHead, Toolbar } from '@mui/material'
import { CloseRounded, CurrencyRupee, Delete, Print } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';
import { useReactToPrint } from 'react-to-print'

export default function BranchdAdmin(){
    const api = '/instituteapi/admin/index.php';
    let history = useHistory()
    const { path, url } = useRouteMatch()
    const date = new Date()

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    const [branches, setBranches] = useState([])
    const [branchwarn, setBranchwarn] = useState('')
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        viewbranches()
    }, [])

    const viewbranches = () => {
        setBranchwarn(null)
        var formData = new FormData()
        formData.append('view-branches', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setBranchwarn(data.warning)
            } else {
                setBranches(data)
            }
        })
    }


    return(
        <>
        <SidebarAdmin />
        <div className='content'>
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-12 text-center mb-3'>
                    <ButtonGroup size='small' >
                        <Button color='info' variant='outlined' onClick={() => history.push(`${url}/payment-type`)} >Add TYPE</Button>
                        <Button color='warning' variant='outlined' onClick={() => history.push(`${url}/configure/0`)} >Add</Button>
                    </ButtonGroup>
                </div>
                {loading ? <LinearProgress color='warning' /> :
                branchwarn ? <Alert severity='warning'>{branchwarn}</Alert> :
                branches.map((branch, i) =>
                <div className='col-md-4 col-12' key={i} >
                    <div className={`lightshadow p-3 rounded-3 mb-4 ${branch.b_blocked === '1' && 'blockbg'}`}>
                        <Box sx={{background: '#eeeeee'}} padding={1} borderRadius={1} >
                            <Avatar src={`/instituteassets/branchimg/${branch.b_pic}`} alt='Branch pic' sx={{width: '150px', height: '150px', borderRadius: 1}} />
                        </Box>

                        <Box mt={3} >
                            <Stack direction='row' justifyContent='space-between' alignContent='center' mt={2} >
                                <Typography variant='body1' color='gray' >Name</Typography>
                                <Typography variant='body1' >{branch.b_name}</Typography>
                            </Stack><Divider/>
                            <Stack direction='row' justifyContent='space-between' alignContent='center' mt={2} >
                                <Typography variant='body1' color='gray' >Owner</Typography>
                                <Typography variant='body1' >{branch.b_oname}</Typography>
                            </Stack><Divider/>
                            <Stack direction='row' justifyContent='space-between' alignContent='center' mt={2} >
                                <Typography variant='body1' color='gray' >Mobile</Typography>
                                <Typography variant='body1' >{branch.b_omobile}</Typography>
                            </Stack><Divider/>
                            <Stack direction='row' justifyContent='space-between' alignContent='center' mt={2} >
                                <Typography variant='body1' color='gray' >Code</Typography>
                                <Typography variant='body1' >{branch.b_code}</Typography>
                            </Stack><Divider/>
                        </Box>

                        <Box mt={2} >
                            <ButtonGroup variant='contained' size='small' fullWidth={true} color='error' >
                                <Button onClick={() => history.push(`${url}/block/${branch.branchid}`)} >Block</Button>
                                <Button onClick={() => history.push(`${url}/pic/${branch.branchid}`)} >IMG</Button>
                                <Button onClick={() => history.push(`/certificate-branch/${branch.branchid}`)} >CERT</Button>
                                <Button onClick={() => history.push(`${url}/configure/${branch.branchid}`)} >EDIT</Button>
                                <Button onClick={() => history.push(`${url}/payment/${branch.branchid}`)} >PAYM</Button>
                            </ButtonGroup>
                        </Box>
                    </div>
                </div>) }
            </div>
        </div>
        </div>
        <Route path={`${path}/configure/:branchid`} component={ConfigureBranch} />
        <Route path={`${path}/pic/:branchid`} component={ConfigurePic} />
        <Route path={`${path}/block/:branchid`} component={ConfigureBlock} />
        <Route path={`${path}/payment/:branchid`} component={PaymentOfBranch} />
        <Route path={`${path}/payment-type`} component={AddType} />
        </>
    );

    function ConfigureBranch(){
        const { branchid } = useParams()
        const [opensnack, setOpenSnack] = useState(false)
        const [snackwarn, setSnackwarn] = useState('')

        const [oname, setOname] = useState('')
        const [omobile, setOmobile] = useState('')
        const [oemail, setOemail] = useState('')
        const [oaadhaar, setOaadhaar] = useState('')
        const [bname, setBname] = useState('')
        const [baddress, setBaddress] = useState('')
        const [bstate, setBstate] = useState('')
        const [bcity, setBcity] = useState('')
        const [bdist, setBdist] = useState('')
        const [bcode, setBcode] = useState('')
        const [bjoined, setBjoined] = useState('')
        const [bvalid, setBvalid] = useState('')
        const [category, setCategory] = useState('')
        const [uploading, setUploading] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('branch-detail', branchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setOname(data.b_oname); setOmobile(data.b_omobile); setOemail(data.b_oemail); setOaadhaar(data.b_oaadhaar); setBname(data.b_name); setBaddress(data.b_address); setBstate(data.b_state); setBcity(data.b_city); setBdist(data.b_dist); setBcode(data.b_code); setBjoined(data.b_joined0); setBvalid(data.b_valid0); setCategory(data.b_category)
                }
            })
        }, [branchid])

        const configurebranch = () => {
            setUploading(true)
            var formData = new FormData()
            formData.append('configure-branch', branchid)
            formData.append('oname', oname)
            formData.append('omobile', omobile)
            formData.append('oemail', oemail)
            formData.append('oaadhaar', oaadhaar)
            formData.append('bname', bname)
            formData.append('baddress', baddress)
            formData.append('bstate', bstate)
            formData.append('bcity', bcity)
            formData.append('bdist', bdist)
            formData.append('bcode', bcode)
            formData.append('bjoined', bjoined)
            formData.append('bvalid', bvalid)
            formData.append('category', category)
            
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setSnackwarn(data.warning)
                    setOpenSnack(true)
                } else {
                    viewbranches()
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' fullScreen={fullScreen} onClose={() => history.go(-1)} >
                <Snackbar message={snackwarn} open={opensnack} onClose={() => setOpenSnack(false)} />
                <DialogTitle><Close title='Configure ' /></DialogTitle><Divider/>
                <DialogContent>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <h5 className='fw-bold mb-2' >Owner details</h5>
                                <TextField variant='standard' fullWidth type='text' label='Name*' className='mb-4' value={oname} onChange={(event) => setOname(event.target.value)} />
                                <TextField variant='standard' fullWidth type='number' label='Mobile*' className='mb-4' value={omobile} onChange={(event) => setOmobile(event.target.value)} />
                                <TextField variant='standard' fullWidth type='email' label='Email' className='mb-4' value={oemail} onChange={(event) => setOemail(event.target.value)} />
                                <TextField variant='standard' fullWidth type='number' label='Aadhaar' className='mb-4' value={oaadhaar} onChange={(event) => setOaadhaar(event.target.value)} />
                            </div>
                            <div className='col-12 mt-4'>
                                <h5 className='fw-bold mb-2'>Branch details</h5>
                                <TextField variant='standard' fullWidth type='text' label='Name*' className='mb-4' value={bname} onChange={(event) => setBname(event.target.value)} />
                                <TextField variant='standard' fullWidth type='text' label='Address' className='mb-4' value={baddress} onChange={(event) => setBaddress(event.target.value)} />
                                <TextField variant='standard' fullWidth type='text' label='State' className='mb-4' value={bstate} onChange={(event) => setBstate(event.target.value)} />
                                <TextField variant='standard' fullWidth type='text' label='City' className='mb-4' value={bcity} onChange={(event) => setBcity(event.target.value)} />
                                <TextField variant='standard' fullWidth type='text' label='District' className='mb-4' value={bdist} onChange={(event) => setBdist(event.target.value)} />
                                <TextField variant='standard' fullWidth type='text' label='Branch code*' className='mb-4' value={bcode} onChange={(event) => setBcode(event.target.value)} />
                                <TextField variant='standard' fullWidth type='date' helperText='Joined on' className='mb-4' value={bjoined} onChange={(event) => setBjoined(event.target.value)} />
                                <TextField variant='standard' fullWidth type='date' helperText='Valid till' className='mb-4' value={bvalid} onChange={(event) => setBvalid(event.target.value)} />
                                <FormControl variant='standard' fullWidth >
                                    <InputLabel>Category</InputLabel>
                                    <Select value={category} onChange={(event) => setCategory(event.target.value)} >
                                        <MenuItem value='BRANCH' >Branch</MenuItem>
                                        <MenuItem value='FRANCHISE' >Franchise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant='text' color='inherit' onClick={() => history.go(-1)} >CLOSE</Button>
                    <Button variant='contained' color='warning' disabled={uploading} onClick={() => configurebranch()} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                </DialogActions>
            </Dialog>
        );
    }

    function ConfigurePic(){
        const { branchid } = useParams()
        const [opensnack, setOpenSnack] = useState(false)
        const [snackwarn, setSnackwarn] = useState('')
        const [uploading, setUploading] = useState(false)

        useEffect(() => {
            viewProfile(branchid)
        }, [branchid])

        const [profile, setProfile] = useState([])
        const viewProfile = (branchid) => {
            var formData = new FormData()
            formData.append('branch-detail', branchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setProfile(data)
                }
            })
        }

        const uploadpic = (event) => {
            var formData = new FormData()
            formData.append('upload-branch-pic', branchid)
            formData.append('pic', event.target.files[0])
            if(event != 'undefined'){
                setUploading(true)
                fetch(api, {
                    method: 'post',
                    body: formData
                }).then(res => res.json())
                .then(data => {
                    setUploading(false)
                    if(data.warning){
                        setSnackwarn(data.warning)
                    } else if(data.success){
                        viewbranches()
                        viewProfile(branchid)
                    }
                })
            }
        }

        const [working, setWorking] = useState(false)
        const uploadSig = (event) => {
            setWorking(true)
            var formData = new FormData()
            formData.append('upload-branch-sig', branchid)
            formData.append('sig', event.target.files[0])
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setSnackwarn(data.warning); setOpenSnack(true)
                } else {
                    viewProfile(branchid)
                }
            })
        }

        const [addingcenter, setAddingcenter] = useState(false)
        const add_center_sig = (event) => {
            setAddingcenter(true)
            var formData = new FormData()
            formData.append('add-center-signature', branchid)
            formData.append('signature', event.target.files[0])
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setAddingcenter(false)
                if(data.warning){
                    setSnackwarn(data.warning); setOpenSnack(true)
                } else {
                    viewProfile(branchid)
                }
            })
        }

        const [deleteno, setDeleteno] = useState('')
        const delete_image = (name, no) => {
            setDeleteno(no)
            var formData = new FormData()
            formData.append('delete-branch-images', branchid)
            formData.append('image', name)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setDeleteno('')
                if(data.warning){
                    setSnackwarn(data.warning); setOpenSnack(true)
                } else {
                    viewbranches()
                    viewProfile(branchid)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)} >
                <Snackbar message={snackwarn} open={opensnack} onClose={() => setOpenSnack(false)} />
                <DialogTitle>Branch & Signature image</DialogTitle><Divider />
                <DialogContent>
                    <label className='form-label' htmlFor='institute_logo' >Institute logo</label>
                    <input type='file' id='institute_logo' accept='.jpeg, .jpg, .png,' className='form-control mb-3' disabled={uploading} onChange={(event) => uploadpic(event)} />
                    
                    <Avatar src={`/instituteassets/branchimg/${profile.b_pic}`} className='border rounded-3 mx-auto' style={{width: '150px', height: '150px'}} />
                    {profile.b_pic !== null && profile.b_pic !== undefined && (profile.b_pic).length > 0 && <Box textAlign='center' mt={2} >
                        <Button variant='outlined' color='error' size='small' disabled={deleteno === 1 ? true : false} onClick={() => delete_image('pic', 1)} >{deleteno === 1 ? <CircularProgress color='inherit' size={20} /> : 'Delete logo'}</Button>
                    </Box>}
                    <div className='col-12 text-center'>
                        {uploading && <p>Uploading... Please wait</p>}
                    </div>

                    <label className='mt-3 form-label' htmlFor='signature' >Signature <Typography variant='caption' >(Size - 500/250, PNG)</Typography> </label>
                    <input type='file' accept='.jpeg, .jpg, .png'  className='form-control' id='signature' disabled={working} onChange={(event) => uploadSig(event)} />
                    <div className='text-center col-12 mt-3'>
                        <Avatar src={`/instituteassets/branchimg/${profile.b_sig}`} className='border rounded-3 mx-auto' style={{width: '250px', height: '90px'}} />
                        { profile.b_sig !== null && profile.b_sig !== undefined && (profile.b_sig).length > 0 && <Box textAlign='center' mt={2} >
                            <Button variant='outlined' color='error' size='small' disabled={deleteno === 2 ? true : false} onClick={() => delete_image('sig', 2)} >{deleteno === 2 ? <CircularProgress color='inherit' size={20} /> : 'Delete signature'}</Button>
                        </Box>}
                    </div>
                    <div className='col-12 mt-2 text-center'>
                        { working && <CircularProgress color='warning' /> }
                    </div>

                    <label className='mt-3 form-label' htmlFor='signature' >Center head signature <Typography variant='caption' >(Size - 500/250, PNG)</Typography> </label>
                    <input type='file' accept='.png'  className='form-control' id='signature' disabled={addingcenter} onChange={(event) => add_center_sig(event)} />
                    <div className='text-center col-12 mt-3'>
                        <Avatar src={`/instituteassets/branchimg/${profile.b_center_sig}`} className='border rounded-3 mx-auto' style={{width: '250px', height: '90px'}} />
                        { profile.b_center_sig !== null && profile.b_center_sig !== undefined && (profile.b_center_sig).length > 0 && <Box textAlign='center' mt={2} >
                            <Button variant='outlined' color='error' size='small' disabled={deleteno === 3 ? true : false} onClick={() => delete_image('center', 3)} >{deleteno === 3 ? <CircularProgress color='inherit' size={20} /> : 'Delete signature'}</Button>
                        </Box>}
                    </div>
                    { addingcenter && <Typography align='center' color='red' mt={1} >Uploading...</Typography> }


                </DialogContent><Divider />
                <DialogActions>
                    <Button variant='text' color='inherit' onClick={() => history.go(-1)} >CLOSE</Button>
                </DialogActions>
            </Dialog>
        );
    }

    function ConfigureBlock(){
        const { branchid } = useParams()

        const [blocking, setBlocking] = useState(false)

        const block = () => {
            setBlocking(true)
            var formData = new FormData()
            formData.append('block-branch', branchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setBlocking(false)
                if(data.success){
                    viewbranches()
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Block' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12 text-center text-break'>
                                <h1 className='fw-bold' >Are you sure to do this?</h1>
                            </div>
                            <div className='col-12 text-center mt-4'>
                                <Button className='col-md-4 col-6' variant='contained' color='warning' size='large' onClick={() => block()} disabled={blocking} >{ blocking ? <CircularProgress color='inherit' size={24} /> : 'YES' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function PaymentOfBranch(){
        const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

        const { branchid } = useParams()

        const [payments, setPayments] = useState([])
        const [payWarn, setPayWarn] = useState('')
        useEffect(() => {
            loadPayments(branchid)
            descriptions()
        }, [branchid])

        const loadPayments = (branchid) => {
            setPayWarn('')
            var formData = new FormData()
            formData.append('payments-of-branch', branchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setPayWarn(data.warning)
                } else {
                    setPayments(data)
                }
            })
        }

        const [getDesc, setGetDesc] = useState([])
        const descriptions = () => {
            var formData = new FormData()
            formData.append('payment-types', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setGetDesc(data)
                }
            })
        }

        const [amount, setAmount] = useState('')
        const [desc, setDesc] = useState('')
        const [student, setStudent] = useState('')
        const [payDate, setPayDate] = useState(`${date.getFullYear()}-${date.toLocaleString(undefined, {month: '2-digit'})}-${date.toLocaleString(undefined, {day: '2-digit'})}`)
        const [payingWarn, setPayingWarn] = useState('')
        const [paying, setPaying] = useState(false)
        const pay = () => {
            setPaying(true)
            const formData = new FormData()
            formData.append('payed-by-branch', branchid)
            formData.append('amount', amount)
            formData.append('date', payDate)
            formData.append('desc', desc+" "+student)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setPaying(false)
                if(data.warning){
                    setPayingWarn(data.warning)
                } else if(data.success) {
                    setAmount(''); setDesc(''); setPayDate(''); setStudent('')
                    loadPayments(branchid)
                }
            })
        }

        const [deleting, setDeleting] = useState(false)
        const deletePayment = (paymentid) => {
            setDeleting(true)
            var formData = new FormData()
            formData.append('delete-branch-payment', paymentid)
            formData.append('branchid', branchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setDeleting(false)
                if(!data.warning){
                    loadPayments(branchid)
                }
            })
        }

        const area = useRef()
        const print = useReactToPrint({
            content:() => area.current
        })

        const [detail, setDetail] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('branch-detail', branchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setDetail(data)
                }
            })
        }, [branchid])

        const [pdetail, setPdetail] = useState([])
        const paymentDetail = (paymentid) => {
            var formData = new FormData()
            formData.append('branch-payment-detail', paymentid)
            formData.append('branchid', branchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setPdetail(data)
                    print()
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='md' fullScreen={fullScreen} >
                <DialogTitle>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' >
                        <Typography variant='body1'>Payment & History</Typography>
                        <IconButton onClick={() => history.go(-1)} ><CloseRounded/> </IconButton>
                    </Stack>
                </DialogTitle><Divider/>

                <DialogContent>
                    <Grid container spacing={3} pb={3} >
                        <Grid item xs={12} md={6}>
                            <Box sx={{background: '#e9ecef'}} padding={2} height={320} >
                                <Stack spacing={2}>
                                    <TextField fullWidth variant='standard' type='number' label='Amount' value={amount} onChange={(event) => setAmount(event.target.value)} />
                                    <FormControl fullWidth variant='standard' >
                                        <InputLabel>Description</InputLabel>
                                        <Select value={desc} onChange={(event) => setDesc(event.target.value)}>
                                            { getDesc.map((data, i) =>
                                            <MenuItem key={i} value={data.pt_type} >{data.pt_type}</MenuItem>) }
                                        </Select>
                                    </FormControl>

                                    { desc === 'Student' &&
                                    <TextField fullWidth variant='standard' label='Student payment' placeholder='Student name and code. Seperated by coma.' value={student} onChange={(event) => setStudent(event.target.value)} />
                                    }

                                    <TextField fullWidth variant='standard' type='date' helperText='Date' value={payDate} onChange={(event) => setPayDate(event.target.value)} />
                                    { payingWarn && <Alert severity='warning'>{payingWarn}</Alert> }
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            { deleting === true && <Typography variant='caption' color='gray'>Deleting...</Typography> }
                            <Box height={320} overflow='auto' >
                                { payWarn ? <Alert severity='warning'>{payWarn}</Alert> :
                                payments.map((data, i) =>
                                <Box key={i} mb={2} sx={{background: '#e9ecef'}} padding={1} >
                                    <Typography variant='h6' >{Number(data.e_credit).toLocaleString('en-in')}<CurrencyRupee/></Typography>
                                    <Typography variant='body2' noWrap >Description: {data.e_description}</Typography>
                                    <Typography variant='body2' noWrap >Invoice number: {data.e_slip}</Typography>
                                    <Typography variant='caption' >Date - {data.pay_date}</Typography>
                                    <Box>
                                        <IconButton onClick={() => paymentDetail(data.e_table_id)} ><Print/></IconButton>
                                        <IconButton onDoubleClick={() => deletePayment(data.e_table_id)} disabled={deleting} ><Delete/></IconButton>
                                    </Box>
                                </Box>)}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container sx={{display: 'none', displayPrint: 'block'}} >
                        <Grid item xs={12} >
                            <Box ref={area} padding={6} pl={8} pr={8} sx={{position: 'relative', width: '210mm', height: '296mm'}} >
                                <Box sx={{position: 'absolute', bottom: 44, width: '100%', left: 0}} >
                                    <Box padding={1.5} sx={{background: '#000000'}} ></Box>
                                </Box>
                                <Box padding={2.5} sx={{background: '#ef6c00', position: 'absolute', bottom: 0, width: '100%', left: 0}} ></Box>
                                <Box sx={{position: 'absolute', bottom: 100}} >
                                    <Typography variant='h6' fontWeight='bold' >NEXUS EDUCATION & WELFARE FOUNDATION</Typography>
                                    <Typography variant='body1' color='gray' mb={1} >This is a computer generated invoice. If you have any queries, you can reach us at</Typography>
                                    <Typography variant='body2' color='gray' >Gami Tola, Hariganj,  Katihar(854105), Bihar</Typography>
                                    <Typography variant='body2' color='gray' >Mobile Number: +91-9431668289</Typography>
                                    <Typography variant='body2' color='gray' >Email: nexuscomputerkatihar@gmail.com</Typography>
                                </Box>
                                <Stack direction='row' justifyContent='space-between' alignItems='center' >
                                    <Box>
                                        <Typography variant='h5' fontWeight='bold' sx={{color: '#ef6c00'}} >NEXUS EDUCATION & WELFARE FOUNDATION</Typography>
                                        <Typography variant='body1' color='gray' >Gami Tola, Hariganj, Katihar - 854105</Typography>
                                        <Typography variant='body1' color='gray'>Bihar</Typography>
                                    </Box>
                                </Stack>
                                <Box sx={{background: '#ef6c00'}} padding={0.3} mt={1} ></Box>

                                <Box mt={3} >
                                    <Typography variant='h6' >Bill to</Typography>
                                    <Table >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Institute</TableCell>
                                                <TableCell>{detail.b_name}</TableCell>

                                                <TableCell>Director</TableCell>
                                                <TableCell>{detail.b_oname}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Mobile</TableCell>
                                                <TableCell>{detail.b_omobile}</TableCell>

                                                <TableCell>Email</TableCell>
                                                <TableCell>{detail.b_oemail}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Address</TableCell>
                                                <TableCell>{detail.b_address}</TableCell>

                                                <TableCell>Code</TableCell>
                                                <TableCell>{detail.b_code}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Invoice number</TableCell>
                                                <TableCell>{Number(pdetail.e_slip)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>

                                <Box mt={4}>
                                    <Table>
                                        <TableHead sx={{background: '#e9ecef'}} >
                                            <TableRow>
                                                <TableCell>SR.NO.</TableCell>
                                                <TableCell>DESCRIPTION</TableCell>
                                                <TableCell>DATE</TableCell>
                                                <TableCell align='right' >AMOUNT</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>1</TableCell>
                                                <TableCell>{pdetail.e_description}</TableCell>
                                                <TableCell>{pdetail.e_date}</TableCell>
                                                <TableCell align='right' >{Number(pdetail.e_credit)} &#8377;</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Stack direction='row' justifyContent='space-between' mt={3} >
                                        <Typography variant='h5' fontWeight='bold' sx={{flexGrow: 1}} >Invoice Total</Typography>
                                        <Typography variant='h5' fontWeight='bold' >{Number(pdetail.e_credit).toLocaleString('en-in')} &#8377;</Typography>
                                    </Stack>
                                </Box>
                                <Box mt={4} >
                                    <Box textAlign='right' >
                                        <img src='/cert/sig-head.png' alt='Chairman signature' style={{width: '120px'}} />
                                    </Box>
                                    <Typography align='right' color='gray' variant='body2' >Chairman Signature</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button color='error' variant='text' onClick={() => history.go(-1)} >CLOSE</Button>
                    <Button variant='contained' color='primary' disabled={paying} onClick={() => pay()} >{paying ? <CircularProgress color='inherit' size={24} /> : 'UPLOAD'}</Button>
                </DialogActions>
            </Dialog>
        );
    }

    function AddType(){

        useEffect(() => {
            view_types()
        }, [])
        const [types, setTypes] = useState([])
        const [typeWarn, setTypeWarn] = useState('')
        const view_types = () => {
            setTypeWarn('')
            var formData = new FormData()
            formData.append('payment-types', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setTypeWarn(data.warning)
                } else {
                    setTypes(data)
                }
            })
        }

        const [delWarn, setDelWarn] = useState('')
        const [deleting, setDeleting] = useState(false)
        const delete_type = (tableid) => {
            setDeleting(true)
            var formData = new FormData()
            formData.append('delete-payment-type', tableid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setDeleting(false)
                if(data.warning){
                    setDelWarn(data.warning)
                } else {
                    view_types()
                }
            })
        }

        const [type, setType] = useState('')
        const [adding, setAdding] = useState(false)
        const add_type = () => {
            setAdding(true)
            var formData = new FormData()
            formData.append('add-payment-type', type)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setAdding(false)
                if(data.warning){
                    setDelWarn(data.warning)
                } else {
                    setType('')
                    view_types()
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' fullScreen={fullScreen} onClose={() => history.go(-1)} >
                <DialogTitle>Add payment type</DialogTitle><Divider/>
                <DialogContent>
                    <TextField fullWidth variant='outlined' label='Create payment type' sx={{marginBottom: 2}} value={type} onChange={(event) => setType(event.target.value)} />
                    { delWarn && <Alert severity='warning'>{delWarn}</Alert> }
                    
                    {typeWarn ? <Alert severity='warning'>{typeWarn}</Alert> :
                    types.map((data, i) =>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' mt={2} sx={{background: '#e0e0e0'}} padding={1.5} borderRadius={1} key={i} >
                        <Typography noWrap >{data.pt_type}</Typography>
                        <Box>
                            <IconButton onDoubleClick={() => delete_type(data.pt_table_id)} disabled={deleting} ><Delete/></IconButton>
                        </Box>
                    </Stack>)}
                </DialogContent>
                <Divider/><DialogActions>
                    <Button color='error' variant='text' onClick={() => history.go(-1)} >CLOSE</Button>
                    <Button color='warning' variant='contained' onClick={() => add_type()} disabled={adding} >{adding ? <CircularProgress color='inherit' size={24} /> : 'ADD TYPE'}</Button>
                </DialogActions>
            </Dialog>
        );
    }

}
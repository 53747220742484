import React, { useEffect, useState } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import Close from './../close'

/* MUI */
import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow }  from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';

export default function AttendanceMakeBranch(){
    const api = '/instituteapi/branch/index.php'
    const { batchid } = useParams()

    const [message, setMessage] = useState('')
    const [open, setOpen] = useState(false)
    let history = useHistory()
    const { path, url } = useRouteMatch()

    const [students, setStudents] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        viewStudents(batchid)
    }, [batchid])

    const viewStudents = (batchid) => {
        setWarning('');
        var formData = new FormData()
        formData.append('batch-attendance', batchid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setStudents(data)
            }
        })
    }

    const makeAttendance = (studentid) => {
        var formData = new FormData()
        formData.append('configure-attendance', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setMessage(data.warning); setOpen(true)
            } else if(data.success){
                setMessage('Attendance succeed!'); viewStudents(batchid)
            }
        })
    }

    var x = 0

    return(
        <Dialog open={true} fullScreen >
            <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
            <DialogTitle className='bg-light' ><Close title='Make attendance' /></DialogTitle>
            <DialogContent>
                <div className='container-fluid mb-5'>
                    <div className='row p-3'>
                        <div className='col-md-6 col-12 mx-auto'>
                            <div className='row'>
                                <div className='col-12'>
                                    { loading ? <LinearProgress color='warning' /> :
                                    warning ? <Alert severity='warning'>{warning}</Alert> :
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='left'>SR. NO.</TableCell>
                                                    <TableCell align='left' >NAME</TableCell>
                                                    <TableCell align='center' >CODE</TableCell>
                                                    <TableCell align='center' >AT IN</TableCell>
                                                    <TableCell align='center' >AT OUT</TableCell>
                                                    <TableCell align='right' >DELETE</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { students.map((stu, i) =>
                                                <TableRow key={i} >
                                                    <TableCell align='left' >{x = x+1}</TableCell>
                                                    <TableCell align='left' onClick={() => makeAttendance(stu.studentid)} >{stu.s_name}</TableCell>
                                                    <TableCell align='center' onClick={() => makeAttendance(stu.studentid)} >{stu.s_code}</TableCell>
                                                    <TableCell align='center' onClick={() => makeAttendance(stu.studentid)} >{stu.at_in}</TableCell>
                                                    <TableCell align='center' onClick={() => makeAttendance(stu.studentid)} >{stu.at_out}</TableCell>
                                                    <TableCell align='right' ><IconButton onClick={() => history.push(`${url}/delete/${stu.studentid}/${stu.at_table_id}`)} ><DeleteIcon /></IconButton></TableCell>
                                                </TableRow>) }
                                            </TableBody>
                                        </Table>
                                    </TableContainer> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <Route path={`${path}/delete/:studentid/:attendanceid`} component={DeleteAttendance} />
        </Dialog>
    );

    function DeleteAttendance(){

        const { studentid } = useParams()
        const { attendanceid } = useParams()

        const [warning, setWarning] = useState('')
        const [loading, setLoading] = useState(false)

        const deleteAttendance = () => {
            setWarning(''); setLoading(true)
            var formData = new FormData()
            formData.append('delete-attendance', attendanceid)
            formData.append('studentid', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    viewStudents(batchid); history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} onClose={() => history.go(-1)}>
                <DialogContent>
                    <div className='container-fluid mt-4 mb-5'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h2 className='fw-bold'>Do you want to delete this attendance?</h2>
                            </div>
                            <div className='col-12 mt-2'>
                                {warning && <Alert severity='warning'>{warning}</Alert>}
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button color='warning' size='large' variant='contained' disabled={loading} onClick={() => deleteAttendance()} >{loading ? <CircularProgress color='inherit' size={24} /> : 'YES' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

}
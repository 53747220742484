import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import Bar from "../../bar";

// MUI
import { Box, Button, Card, CardContent, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function CreateAccountStudent({api}){
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')

    const [branches, setBranches] = useState([])
    const [courses, setCourses] = useState([])

        useEffect(() => {
            var formData = new FormData()
            formData.append('courses', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setCourses(data)
                }
            })
        }, [])

        useEffect(() => {
            var formData = new FormData()
            formData.append('places', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setBranches(data)
                }
            })
        }, [])

        const [email, setEmail] = useState('')
        const [name, setName] = useState('')
        const [guardian, setGuardian] = useState('')
        const [address, setAddress] = useState('')
        const [po, setPo] = useState('')
        const [ps, setPs] = useState('')
        const [city, setCity] = useState('')
        const [dist, setDist] = useState('')
        const [state, setState] = useState('')
        const [postel, setPostel] = useState('')
        const [aadhaar, setAadhaar] = useState('')
        const [mobile, setMobile] = useState('')
        const [birth, setBirth] = useState('')
        const [gender, setGender] = useState('')
        const [nation, setNation] = useState('INDIA')
        const [courseid, setCourseid] = useState('')
        const [branchid, setBranchid] = useState('')
        const [otp, setOtp] = useState('')
        const [password, setPassword] = useState('')
        const [otpsended, setOtpsended] = useState(false)
        const [process, setProcess] = useState(false)

        const verify = () => {
            setProcess(true)
            var formData = new FormData()
            formData.append('verify-email-for-create', email)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setProcess(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.otpsended === true){
                    setOtpsended(true)
                }
            })
        }

        const create = () => {
            setProcess(true)
            var formData = new FormData()
            formData.append('create-account', email)
            formData.append('name', name)
            formData.append('guardian', guardian)
            formData.append('address', address)
            formData.append('po', po)
            formData.append('ps', ps)
            formData.append('city', city)
            formData.append('dist', dist)
            formData.append('state', state)
            formData.append('postel', postel)
            formData.append('aadhaar', aadhaar)
            formData.append('mobile', mobile)
            formData.append('birth', birth)
            formData.append('gender', gender)
            formData.append('nation', nation)
            formData.append('courseid', courseid)
            formData.append('branchid', branchid)
            formData.append('otp', otp)
            formData.append('password', password)

            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setProcess(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else {
                    // REDIRECT HERE
                    history.replace('/dashboard-student')
                }
            })
        }

        return(
            <Box ><Snackbar open={open} message={message} onClose={() => setOpen(false)} />
            <Bar text='Create account' />
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={6} mx='auto' >
                            <Card elevation={0} >
                                <CardContent sx={{mt: 3, mb: 4}} >
                                    <Stack spacing={2} >
                                        <TextField fullWidth variant='standard' label='Email*' type='email' className='mt-4' disabled={otpsended} value={email} onChange={(event) => setEmail(event.target.value)} />
                                        {otpsended && <>
                                        <TextField fullWidth variant='standard' label='Name*' type='text' className='mt-4' value={name} onChange={(event) => setName(event.target.value)} />
                                        <TextField fullWidth variant='standard' label="Father/Mother's name*" type='text' className='mt-4' value={guardian} onChange={(event) => setGuardian(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Address' type='text' className='mt-4' value={address} onChange={(event) => setAddress(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Post office' type='text' className='mt-4' value={po} onChange={(event) => setPo(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Police station' type='text' className='mt-4' value={ps} onChange={(event) => setPs(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='City' type='text' className='mt-4' value={city} onChange={(event) => setCity(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='District' type='text' className='mt-4' value={dist} onChange={(event) => setDist(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='State' type='text' className='mt-4' value={state} onChange={(event) => setState(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Postel code' type='number' className='mt-4' value={postel} onChange={(event) => setPostel(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Aadhaar number' type='number' className='mt-4' value={aadhaar} onChange={(event) => setAadhaar(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Mobile' type='number' className='mt-4' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                                        <TextField fullWidth variant='standard' helperText='Birth*' type='date' className='mt-4' value={birth} onChange={(event) => setBirth(event.target.value)} />
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Gender*</InputLabel>
                                            <Select value={gender} onChange={(event) => setGender(event.target.value)} >
                                                <MenuItem value='MALE' >Male</MenuItem>
                                                <MenuItem value='FEMALE' >Female</MenuItem>
                                                <MenuItem value='OTHERS' >Others</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField fullWidth variant='standard' label='Nation' type='text' className='mt-4' value={nation} onChange={(event) => setNation(event.target.value)} />
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Institute*</InputLabel>
                                            <Select value={branchid} onChange={(event) => setBranchid(event.target.value)} >
                                                {branches.map((branch, i) => <MenuItem value={branch.branchid} key={i} >{branch.b_name}</MenuItem> )}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Course*</InputLabel>
                                            <Select value={courseid} onChange={(event) => setCourseid(event.target.value)} >
                                                {courses.map((course, i) => <MenuItem value={course.courseid} key={i} >{course.c_title} ({course.c_name}) (Duration: {course.c_duration} Months)</MenuItem> )}
                                            </Select>
                                        </FormControl>
                                        <TextField fullWidth variant='standard' className='mt-4' label='Create password*' type='text' value={password} onChange={(event) => setPassword(event.target.value)} />
                                        <TextField fullWidth variant='standard' className='mt-4' label='OTP*' type='number' value={otp} onChange={(event) => setOtp(event.target.value)} /> </> }
                                        
                                        {otpsended ? 
                                        <Button color='warning' size='large' variant='contained' disabled={process} onClick={() => create()} sx={{width: '100%', mt: 5}} >{process ? <CircularProgress color='inherit' size={24} /> : 'Join Nexus' }</Button> :
                                        <Button color='warning' size='large' variant='contained' disabled={process} onClick={() => verify()} sx={{width: '100%', mt: 5}} >{process ? <CircularProgress color='inherit' size={24} /> : 'Validate Email' }</Button> }
                                        <Button sx={{width: '100%', mt: 2}} variant="outlined" color="info" onClick={() => history.go(-1)} >Login instead</Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        );
}
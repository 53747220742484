import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import QRCode from "react-qr-code";

// MUI
import { AppBar, Box, Button, IconButton, Toolbar, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';

export default function CertNewAdmin({api}){
    let history = useHistory()
    let { studentid } = useParams()

    const area = useRef()
    const printit = useReactToPrint({
        content:() => area.current
    })

    const [print, setPrint] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('computer-certificate-detail', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPrint(data)
            }
        })
    }, [studentid])

    useEffect(() => {
        document.title = print.s_name
    }, [print])

    return(
        <>
        <AppBar position="sticky" component='nav' color="warning">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => history.go(-1)}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography sx={{flexGrow: '1'}} ></Typography>
                <Typography color="inherit">CERTIFICATE</Typography>
            </Toolbar>
        </AppBar>
        <div className="container-fluid mt-5">
            <div className="row">
                <div className="col-12 text-center mb-4">
                    <Button color="warning" variant="outlined" startIcon={<PrintIcon />} onClick={() => printit()} >PRINT</Button>
                </div>
                <div className="col-md-9 col-12 mx-auto position-relative p-0 m-0" style={{overflow: 'auto'}} >
                    <div ref={area}>
                        <img src='/cert/certificate.webp' alt='New certificate' />
                        <Box sx={{position: 'absolute', top: '-12%', left: '-28.2%'}} >
                            <QRCode size={101} style={{left: '400px', top: '220px', position: 'absolute'}} value={`The certificate has issued by 'NEXUS EDUCATION & WELFARE FOUNDATION' from franchise ' ${print.b_name}(${print.b_code})' of ${print.s_name}( Registration number: ${print.s_enrollment}) on ${print.s_issuedate}`} />
                        </Box>
                        <div className="position-absolute" style={{top: '15.5%', right: '8.5%'}} >
                            <img src={`/instituteassets/studentimg/${print.s_pic}`} alt='Print profile' style={{width: '110px', height: '120px'}} />
                        </div>
                        <Typography variant='body2' style={{position: 'absolute', top: '35.2%', left: '21%'}} >{print.b_code}</Typography>
                        <Typography variant='body2' style={{position: 'absolute', top: '34.7%', left: '55.7%'}} >{print.s_enrollment}</Typography>
                        <Typography variant='body2' style={{position: 'absolute', top: '34.5%', left: '85%'}} >{print.s_issuedate}</Typography>
                        <Typography variant='body2' style={{position: 'absolute', top: '40%', left: '21.5%'}} >{print.s_aadhaar}</Typography>
                        <Typography variant='body2' style={{position: 'absolute', top: '39.6%', left: '82.5%'}} >{print.s_aadhaar}</Typography>
                        <div className="position-absolute col-12 text-center" style={{top: '48%'}}>
                            <Typography variant="h5" sx={{fontWeight: 'bold'}} >{print.s_name}</Typography>
                        </div>
                        <Typography variant='body1' style={{position: 'absolute', top: '58.8%', left: '18.4%'}} >{print.s_guardian}</Typography>
                        <Typography variant='body1' style={{position: 'absolute', top: '63.3%', left: '8.6%'}} >{print.c_duration}</Typography>
                        <Typography variant='body1' style={{position: 'absolute', top: '63.3%', left: '32.6%'}} >{print.c_duration}</Typography>
                        <Typography variant='body1' style={{position: 'absolute', top: '67.8%', left: '22%'}} >{print.c_duration}</Typography>
                        <Typography variant='body1' style={{position: 'absolute', top: '72.3%', left: '23%'}} >A+</Typography>
                    </div>
                </div>
            </div>
        </div></>
    );
}
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import Bar from '../../bar'

// MUI
import { Box, Button, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function QuestionGenerator({apipublic}){
    const [action, setAction] = useState('')
    const { branchid } = useParams()

    const [batches, setBatches] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        var formData = new FormData()
        formData.append('view-batches', branchid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setBatches(data)
            }
        })
    }, [])

    const generate_question = (batchid, batchnum) => {
        setAction(batchid)
        var formData = new FormData()
        formData.append('generate-question', batchid)
        formData.append('batchnum', batchnum)
        axios.post(apipublic, formData)
        .then(function(res){
            setAction('')
            alert(res.data.msg)
        })
    }

    return(
        <Box mb={5} ><Bar text="Question generator" />
        <Container>
            <Grid container mt={3} >
                <Grid item xs={12} md={6} mx='auto' >
                    { batches.map((row, i) =>
                    <Card key={i} sx={{mt: 1}} >
                        <CardContent>
                            <Stack mt={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography>Batch time</Typography>
                                <Typography>{row.bh_start} - {row.bh_end}</Typography>
                            </Stack>
                            <Stack mt={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography>Generate for</Typography>
                                <Stack direction={'row'} spacing={1} >
                                    <Button variant="outlined" color='success' onClick={() => generate_question(row.batchid, 'a')} disabled={action === row.batchid ? true : false} >Batch A</Button>
                                    <Button variant="outlined" color='primary' onClick={() => generate_question(row.batchid, 'b')} disabled={action === row.batchid ? true : false} >Batch B</Button>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card> ) }
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
}
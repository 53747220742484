import React, { useEffect, useState } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import HeaderBranch from './header'
import SidebarBranch from './sidebar'
import Close from './../close'

/* MUI */
import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Select, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function EnquiryBranch(){
    const api = '/instituteapi/branch/index.php'
    let history = useHistory()
    const { path, url } = useRouteMatch()
    
    useEffect(() => {
        viewEnquiry()
    }, [])
    const [enquiries, setEnquiries] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)
    const viewEnquiry = () => {
        setLoading(true)
        var formData = new FormData()
        formData.append('view-enquiry', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWarning(''); setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setEnquiries(data)
            }
        })
    }


    return(
        <>
        <div className='sidebar'> <SidebarBranch /> </div>
        <div className='content'>
        <HeaderBranch />
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-12 text-center'>
                    <Button color='warning' variant='contained' onClick={() => history.push(`${url}/configure/0`)} >Add Enquiry</Button>
                </div>
            </div>
            <div className='row p-3 mt-4'>
                <div className='col-md-7 col-12 mx-auto'>
                    <div className='row'>
                        { loading ? <LinearProgress color='warning' /> :
                        warning ? <Alert severity='warning'>{warning}</Alert> :
                        enquiries.map((enq, i) =>
                        <div className='col-12 lightshadow p-3 rounded-3 mb-4' key={i} >
                            <div className='text-center'>
                                <h5 className='fw-bold lspace3' >{enq.en_name}</h5>
                                <small className='text-muted' >{enq.en_date}</small>
                            </div>
                            <div className='mt-3'>
                                <table className='table table-borderless table-sm'>
                                    <tbody>
                                        <tr><td>STUDENT NAME</td><td>{enq.en_name}</td></tr>
                                        <tr><td>GUARDIAN NAME</td><td>{enq.en_guardian}</td></tr>
                                        <tr><td>MOBILE NO.</td><td>{enq.en_mobile}</td></tr>
                                        <tr><td>GENDER</td><td>{enq.en_gender}</td></tr>
                                        <tr><td>KNOWN FROM</td><td>{enq.en_known}</td></tr>
                                        <tr><td>INTERESTED COURSE</td><td>{enq.en_course}</td></tr>
                                        <tr><td>ADMISSION PREDICTION</td><td>{enq.en_admission}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='text-end'>
                                <IconButton onClick={() => history.push(`${url}/delete/${enq.enquiryid}`)} ><DeleteIcon /></IconButton>
                                <IconButton onClick={() => history.push(`${url}/configure/${enq.enquiryid}`)} ><EditIcon/></IconButton>
                            </div>
                        </div> )}
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Route path={`${path}/configure/:enquiryid`} component={Configure} />
        <Route path={`${path}/delete/:enquiryid`} component={DeleteEnquiry} />
        </>
    );

    function Configure(){
        let { enquiryid } = useParams()
        const [name, setName] = useState('')
        const [guardian, setGuardian] = useState('')
        const [mobile, setMobile] = useState('')
        const [gender, setGender] = useState('')
        const [known, setKnown] = useState('')
        const [course, setCourse] = useState('')
        const [admission, setAdmission] = useState('')
        const [warning, setWarning] = useState('')
        const [uploading, setUploading] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('enquiry-detail', enquiryid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setName(data.en_name); setGuardian(data.en_guardian); setMobile(data.en_mobile); setGender(data.en_gender); setKnown(data.en_known); setCourse(data.en_course); setAdmission(data.en_admission)
                }
            })
        }, [enquiryid])

        const configure = () => {
            setUploading(true); setWarning('')
            var formData = new FormData()
            formData.append('configure-enquiry', enquiryid)
            formData.append('name', name)
            formData.append('guardian', guardian)
            formData.append('mobile', mobile)
            formData.append('gender', gender)
            formData.append('known', known)
            formData.append('course', course)
            formData.append('admission', admission)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.success) {
                    viewEnquiry(); history.go(-1)
                }
            })
        }

        const [courses, setCourses] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-course', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setCourses(data)
                }
            })
        }, [])

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Enquiry details' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField variant='standard' fullWidth type='text' label='Student name' value={name} onChange={(event) => setName(event.target.value)} className='mb-4' />
                                <TextField variant='standard' fullWidth type='text' label='Guardian name' value={guardian} onChange={(event) => setGuardian(event.target.value)} className='mb-4' />
                                <TextField variant='standard' fullWidth type='number' label='Mobile' value={mobile} onChange={(event) => setMobile(event.target.value)} className='mb-4' />
                                <FormControl fullWidth variant='standard' className='mb-4' >
                                    <InputLabel>Gender</InputLabel>
                                    <Select value={gender} onChange={(event) => setGender(event.target.value)} >
                                        <MenuItem value='MALE' >Male</MenuItem>
                                        <MenuItem value='FEMALE' >Female</MenuItem>
                                        <MenuItem value='OTHERS' >Others</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth variant='standard' className='mb-4' >
                                    <InputLabel>Known from</InputLabel>
                                    <Select value={known} onChange={(event) => setKnown(event.target.value)} >
                                        <MenuItem value='BANNER' >Banner</MenuItem>
                                        <MenuItem value='FRIEND' >Friend</MenuItem>
                                        <MenuItem value='POSTER' >Poster</MenuItem>
                                        <MenuItem value='HOARDINGS' >Hoardings</MenuItem>
                                        <MenuItem value='NEWS PAPER' >News paper</MenuItem>
                                        <MenuItem value='OTHERS' >Others</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth variant='standard' className='mb-4' >
                                    <InputLabel>Interested course</InputLabel>
                                    <Select value={course} onChange={(event) => setCourse(event.target.value)}>
                                        { courses.map((cour, i) =>
                                        <MenuItem value={cour.c_title+' - '+cour.c_duration+' MONTHS '+'('+cour.c_name+')'} key={i} >{cour.c_title} - {cour.c_duration} Months ({cour.c_name})</MenuItem> ) }
                                    </Select>
                                </FormControl>
                                <TextField fullWidth variant='standard' type='date' helperText='Admission prediction' value={admission} onChange={(event) => setAdmission(event.target.value)} />
                            </div>
                            <div className='col-12 mt-2'>
                                { warning && <Alert severity='warning'>{warning}</Alert> }
                            </div>
                            <div className='col-12 text-center mt-4'>
                                <Button variant='contained' size='large' color='warning' disabled={uploading} onClick={() => configure()} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'UPLOAD' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function DeleteEnquiry(){
        let { enquiryid } = useParams()
        const [warning, setWarning] = useState('')
        const [deleting, setDeleting] = useState(false)

        const deleteEnquiry = () => {
            setDeleting(true); setWarning('')
            var formData = new FormData()
            formData.append('delete-enquiry', enquiryid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setDeleting(false)
                if(data.warning){
                    setWarning(data.warning);
                } else if(data.success){
                    viewEnquiry(); history.go(-1)
                }
            })
        }
        
        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogContent>
                    <div className='container-fluid mb-5 mt-4'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h2 className='fw-bold' >Do you want to delete this enquiry?</h2>
                            </div>
                            <div className='col-12 mt-2'>
                                { warning && <Alert severity='warning'>{warning}</Alert> }
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button variant='contained' size='large' color='warning' disabled={deleting} onClick={() => deleteEnquiry()} >{deleting ? <CircularProgress color='inherit' size={24} /> : 'YES' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

}
import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

import Close from './../close'

/* MUI */
import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material'

export default function AdminLogin(){
    const api = '/instituteapi/login/admin/index.php';
    let history = useHistory()
    const { path, url } = useRouteMatch()

    useEffect(() => {
        fetch('/instituteapi/admin/ui.php')
        .then(res => res.json())
        .then(data => {
            if(data.loggedin){
                history.replace('/dashboard-admin')
            }
        })
    }, [])

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [logging, setLogging] = useState(false)
    const [logwarn, setLogwarn] = useState('')

    const login = () => {
        setLogging(true)
        setLogwarn(null)
        var formData = new FormData()
        formData.append('login', email)
        formData.append('password', password)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLogging(false)
            if(data.warning){
                setLogwarn(data.warning)
            } else if(data.success){
                history.replace('/dashboard-admin')
            }
        })
    }

    return(
        <>
        <div className='container-fluid flex' style={{height: '90vh'}} >
            <div className='row p-3'>
                <div className='col-md-6 col-12 mx-auto'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <h1 className='fw-bold' >ADMIN LOGIN</h1>
                        </div>
                        <div className='col-12 mt-5'>
                            <TextField variant='standard' fullWidth type='email' label='Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                            <TextField variant='standard' fullWidth type='password' label='Password' value={password} onChange={(event) => setPassword(event.target.value)} className='mt-4' />
                        </div>
                        <div className='col-12 mt-2'>
                            { logwarn && <Alert severity='warning'>{logwarn}</Alert> }
                        </div>
                        <div className='col-12 mt-4 text-center'>
                            <Button className='col-md-4 col-6' color='warning' size='large' variant='contained' disabled={logging} onClick={() => login()} >{logging ? <CircularProgress color='inherit' size={24} /> : 'Login' }</Button>
                        </div>

                        <div className='col-12 mt-5 text-end'>
                            <Button color='secondary' onClick={() => history.push(`${url}/reset`)} >Forgot password</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Route path={`${path}/reset`} component={Reset} />
        </>
    );

    function Reset(){

        const [email, setEmail] = useState('')
        const [password, setPassword] = useState('')
        const [otp, setOtp] = useState('')
        const [reseting, setReseting] = useState(false)
        const [resetwarn, setResetwarn] = useState('')
        const [otpsended, setOtpsended] = useState(false)

        const verify = () => {
            setReseting(true)
            setResetwarn(null)
            var formData = new FormData()
            formData.append('verifyEmail', email)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setReseting(false)
                if(data.warning){
                    setResetwarn(data.warning)
                } else if(data.success){
                    setOtpsended(true)
                }
            })
        }

        const reset = () => {
            setReseting(true)
            setResetwarn(null)
            var formData = new FormData()
            formData.append('update-password', email)
            formData.append('new-password', password)
            formData.append('otp', otp)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setReseting(false)
                if(data.warning){
                    setResetwarn(data.warning)
                } else if(data.success){
                    history.replace('/dashboard-admin')
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Reset password' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField variant='standard' fullWidth type='email'  label='Registered email' disabled={otpsended} value={email} onChange={(event) => setEmail(event.target.value)} />
                            </div>
                            { otpsended &&
                            <div className='col-12'>
                                <TextField variant='standard' fullWidth type='text'  label='New password' className='mt-4' value={password} onChange={(event) => setPassword(event.target.value)} />
                                <TextField variant='standard' fullWidth type='number'  label='OTP' className='mt-4' value={otp} onChange={(event) => setOtp(event.target.value)} />
                            </div> }
                            <div className='col-12 mt-2'>
                                {resetwarn && <Alert severity='warning'>{resetwarn}</Alert>}
                            </div>
                            <div className='col-12 text-center mt-4'>
                                {otpsended ?
                                    <Button className='col-md-4 col-6' variant='contained' color='warning' size='large' disabled={reseting} onClick={() => reset()} >{reseting ? <CircularProgress color='inherit' size={24}/> : 'Reset' }</Button> :
                                    <Button className='col-md-4 col-6' variant='contained' color='warning' size='large' disabled={reseting} onClick={() => verify()} >{reseting ? <CircularProgress color='inherit' size={24} /> : 'Verify' }</Button>
                                } 
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}
import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'

import Bar from "../bar";

// MUI
import { Avatar, Box, Container, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function StudentHistoryBranch({api, imgpath}){
    const { studentid } = useParams()
    const area = useRef()

    const [view, setView] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('student-history', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setView(data)
            }
        })
    }, [])

    const [payment, setPayment] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-fees', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPayment(data)
            }
        })
    }, [studentid])

    while(payment.length < 12){
        payment.push([])
    }

    return(
        <Box>
            <Bar printTrue={true} printArea={area} text='Student History' />

            <Container>
                <Grid container mt={3} >
                    <Grid item md={9} margin='auto' sx={{background: '#f2f2f2'}} overflow='auto' >
                        <Box ref={area} padding={3} pt={3} pl={9} sx={{width: '210mm', height: '296mm'}} >
                            <Stack direction='row' justifyContent='space-between' alignItems='center' >
                                <Box>
                                    <Typography variant="h6" fontWeight='bold' letterSpacing={1} noWrap >{view.b_name}</Typography>
                                    <Typography variant="body2" noWrap >{view.b_city}</Typography>
                                    <Typography variant="body2" noWrap >{view.b_code}</Typography>
                                </Box>
                                <img src="/img/logo.png" alt="Nexus Logo" style={{width: '100px'}} />
                            </Stack>

                            <Box mt={1} sx={{background: '#ef6c00'}} padding={0.2} ></Box>

                            <Box mt={1} >
                                <Grid container>
                                    <Grid item xs={10} >
                                    <Typography variant="h6" fontWeight='bold' >Details from center,</Typography>
                                        <Table size="small" >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Admission Date</TableCell>
                                                    <TableCell>{view.s_joindate}</TableCell>

                                                    <TableCell>Registration Date</TableCell>
                                                    <TableCell>{view.s_enrollment !== null && view.s_register}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Student Code</TableCell>
                                                    <TableCell>{view.s_code}</TableCell>

                                                    <TableCell>Reg./Enroll. Number</TableCell>
                                                    <TableCell>{view.s_enrollment}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Avatar src={imgpath+view.s_pic} sx={{width: 110, height: 120, borderRadius: 0, float: 'right'}} />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box>
                                <Typography variant="h6" fontWeight='bold' >Student Details,</Typography>
                                <Table size="small" >
                                    <TableBody >
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>{view.s_name}</TableCell>

                                            <TableCell>Father/Mother Name</TableCell>
                                            <TableCell>{view.s_guardian}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Address</TableCell>
                                            <TableCell>{view.s_address}</TableCell>

                                            <TableCell>Aadhaar Number</TableCell>
                                            <TableCell>{view.s_aadhaar}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Post office</TableCell>
                                            <TableCell>{view.s_po}</TableCell>

                                            <TableCell>Police station</TableCell>
                                            <TableCell>{view.s_ps}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>City/ Postal code</TableCell>
                                            <TableCell>{view.s_city}/ {view.s_postel}</TableCell>

                                            <TableCell>District/ State</TableCell>
                                            <TableCell>{view.s_dist}/ {view.s_state}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Birth Date</TableCell>
                                            <TableCell>{view.s_birth}</TableCell>

                                            <TableCell>Contact Number</TableCell>
                                            <TableCell>{view.s_mobile}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Gender</TableCell>
                                            <TableCell>{view.s_gender}</TableCell>

                                            <TableCell>Highest Qualification</TableCell>
                                            <TableCell>{view.high_quali}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>

                            <Box mt={1}>
                                <Typography variant="h6" fontWeight='bold'>Course Details,</Typography>

                                <Table size="small" >
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Course/ Duration</TableCell>
                                            <TableCell>{view.c_title === 'TYPING' ? view.c_name+' TYPING' : view.c_title}/ {view.c_duration} Months</TableCell>

                                            <TableCell>Course Fee/ Discount</TableCell>
                                            <TableCell>{Number(view.pf_amount).toLocaleString('en-in')}/ {Number(view.pf_discount).toLocaleString('en-in')} Rupees</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>

                            <Box mt={1}>
                                <Typography variant="h6" fontWeight='bold'>Payment History,</Typography>

                                <Table size="small" sx={{ borderCollapse: 'separate', '& td, & th': { borderRight: '1px solid #ccc' } }} >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.R. NO.</TableCell>
                                            <TableCell>Payment ID</TableCell>
                                            <TableCell>DESCRIPTION</TableCell>
                                            <TableCell>AMOUNT(Rupee)</TableCell>
                                            <TableCell>DATE</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {payment.map((data, i) =>
                                        <TableRow key={i} >
                                            <TableCell>{i+1}</TableCell>
                                            <TableCell>{data.paymentid}</TableCell>
                                            <TableCell>{data.p_description}</TableCell>
                                            <TableCell>{Number(data.p_amount > 0) ? Number(data.p_amount).toLocaleString('en-in') : ''}</TableCell>
                                            <TableCell>{data.p_date}</TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                </Table>
                            </Box>

                            <Box sx={{background: '#ef6c00', width: '300px'}} padding={0.3} borderRadius={10} margin='auto' mt={3} ></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

        </Box>
    );
}
import React from 'react'
import {  } from 'react-router-dom'

/* HEADER */
import PagesHeader from './header';
import PagesFooter from './footer'

/* MUI */
import { Table, TableBody, TableCell, TableContainer, TableRow, IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function Documents(){

    return(
        <>
        <PagesHeader />
        <div className='container-fluid mt-5 mb-5'>
            <div className='row p-3'>
                <div className='col-md-6 col-12 mx-auto'>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='left' >Admission Form</TableCell>
                                    <TableCell align='right' ><a target="_blank" href='/instituteassets/documents/ADMISSION_FORM.pdf'><IconButton><VisibilityIcon /></IconButton></a></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left' >Computer Certificate</TableCell>
                                    <TableCell align='right' ><a target="_blank" href='/instituteassets/documents/computer-certificate.webp'><IconButton><VisibilityIcon /></IconButton></a></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left' >Typing Certificate</TableCell>
                                    <TableCell align='right' ><a target="_blank" href='/instituteassets/documents/typing-certificate.webp'><IconButton><VisibilityIcon /></IconButton></a></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left' >Franchise Certificate</TableCell>
                                    <TableCell align='right' ><a target="_blank" href='/instituteassets/documents/Franchise-certif.webp'><IconButton><VisibilityIcon /></IconButton></a></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left' >Marksheet</TableCell>
                                    <TableCell align='right' ><a target="_blank" href='/instituteassets/documents/marksheet.webp'><IconButton><VisibilityIcon /></IconButton></a></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left' >Nity Aayog Darpan Reg. Certificate</TableCell>
                                    <TableCell align='right' ><a target="_blank" href='/instituteassets/documents/nity-aayog.jpg'><IconButton><VisibilityIcon /></IconButton></a></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left' >Company PAN</TableCell>
                                    <TableCell align='right' ><a target="_blank" href='/instituteassets/documents/PAN.jpg'><IconButton><VisibilityIcon /></IconButton></a></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left' >ISO Certificate</TableCell>
                                    <TableCell align='right' ><a target="_blank" href='/instituteassets/documents/certificate.pdf'><IconButton><VisibilityIcon /></IconButton></a></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left' >UDYAM Certificate</TableCell>
                                    <TableCell align='right' ><a target="_blank" href='/instituteassets/documents/udyam.pdf'><IconButton><VisibilityIcon /></IconButton></a></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
        <PagesFooter />
        </>
    );
}
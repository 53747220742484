import React, { useEffect, useState } from 'react'

import Close from './../close'

/* MUI */
import { Alert, Dialog, DialogContent, DialogTitle, LinearProgress } from '@mui/material'

export default function PaymentHistoryStudent(){
    const api = '/instituteapi/student/index.php'

    const [payments, setPayments] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        var formData = new FormData()
        formData.append('view-payments', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setPayments(data)
            }
        })
    }, [])

    return(
        <Dialog open={true} fullScreen >
        <DialogTitle><Close title='Payment history' /></DialogTitle>
            <DialogContent>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6 col-12 mx-auto mt-3'>
                            <div className='row'>
                                {loading ? <LinearProgress color='warning' /> :
                                warning ? <Alert severity='warning'>{warning}</Alert> :
                                payments.map((pay, i) =>
                                <div className='col-12 mb-4' key={i} >
                                    <div className='lightshadow p-3 rounded-3'>
                                        <h5 className='fw-bold paymentfont'>Amount: {Number(pay.p_amount).toLocaleString('en-in')} &#8377; </h5>
                                        <p className='paymentfont' >Description: {pay.p_description}</p>
                                        <p className='paymentfont' >Date: {pay.p_date}</p>
                                    </div>
                                </div> )}
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
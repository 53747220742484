import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

import Close from './../close'

/* MUI */
import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField } from '@mui/material'

export default function StudentLogin(){
    const api = '/instituteapi/login/student/index.php'
    let history = useHistory()
    const { path, url } = useRouteMatch()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [warning, setWarning] = useState('')
    const [logging, setLogging] = useState(false)

    useEffect(() => {
        fetch('/instituteapi/student/ui.php', {
            method: 'post'
        }).then(res => res.json())
        .then(data => {
            if(data.loggedin === true){
                // REDIRECT HERE
                history.replace('/dashboard-student')
            }
        })
    }, [])

    const login = () => {
        setWarning('')
        setLogging(true)
        var formData = new FormData()
        formData.append('login', email)
        formData.append('password', password)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLogging(false)
            setWarning(data.msg)
            data.status === true && history.replace('/dashboard-student')
        })
    }

    return(
        <>
        <div className='container-fluid flex' style={{height: '90vh'}} >
            <div className='row p-3'>
                <div className='col-md-6 col-12 mx-auto'>
                    <div className='row'>
                        <div className='col-12 text-center mb-4'>
                            <h1 className='fw-bold' >Student Login</h1>
                        </div>
                        <div className='col-12'>
                            <TextField fullWidth variant='standard' type='email' label='Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                            <TextField fullWidth variant='standard' type='password' label='Password' className='mt-4' value={password} onChange={(event) => setPassword(event.target.value)} />
                        </div>
                        <div className='col-12 mt-2'>
                            {warning && <Alert severity='warning'>{warning}</Alert> }
                        </div>
                        <div className='col-12 mt-4 text-center'>
                            <Button className='col-md-4 col-6' color='warning' size='large' variant='contained' disabled={logging} onClick={() => login()} >{logging ? <CircularProgress color='inherit' size={24} /> : 'Login' }</Button>
                        </div>
                        <div className='col-12 text-end mt-4'>
                            <Button color='error' onClick={() => history.push(`/forget-password-student`)} >Forgot password?</Button><br/>
                            <Button color='primary' onClick={() => history.push('/create-account-student')} >Join Institute.</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Route path={`${path}/create`} component={Create} />
        </>
    );

    function Create(){
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [branches, setBranches] = useState([])
        const [courses, setCourses] = useState([])

        useEffect(() => {
            var formData = new FormData()
            formData.append('courses', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setCourses(data)
                }
            })
        }, [])

        useEffect(() => {
            var formData = new FormData()
            formData.append('places', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setBranches(data)
                }
            })
        }, [])

        const [email, setEmail] = useState('')
        const [name, setName] = useState('')
        const [guardian, setGuardian] = useState('')
        const [address, setAddress] = useState('')
        const [po, setPo] = useState('')
        const [ps, setPs] = useState('')
        const [city, setCity] = useState('')
        const [dist, setDist] = useState('')
        const [state, setState] = useState('')
        const [postel, setPostel] = useState('')
        const [aadhaar, setAadhaar] = useState('')
        const [mobile, setMobile] = useState('')
        const [birth, setBirth] = useState('')
        const [gender, setGender] = useState('')
        const [nation, setNation] = useState('INDIA')
        const [courseid, setCourseid] = useState('')
        const [branchid, setBranchid] = useState('')
        const [otp, setOtp] = useState('')
        const [password, setPassword] = useState('')
        const [otpsended, setOtpsended] = useState(false)
        const [process, setProcess] = useState(false)

        const verify = () => {
            setProcess(true)
            var formData = new FormData()
            formData.append('verify-email-for-create', email)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setProcess(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.otpsended === true){
                    setOtpsended(true)
                }
            })
        }

        const create = () => {
            setProcess(true)
            var formData = new FormData()
            formData.append('create-account', email)
            formData.append('name', name)
            formData.append('guardian', guardian)
            formData.append('address', address)
            formData.append('po', po)
            formData.append('ps', ps)
            formData.append('city', city)
            formData.append('dist', dist)
            formData.append('state', state)
            formData.append('postel', postel)
            formData.append('aadhaar', aadhaar)
            formData.append('mobile', mobile)
            formData.append('birth', birth)
            formData.append('gender', gender)
            formData.append('nation', nation)
            formData.append('courseid', courseid)
            formData.append('branchid', branchid)
            formData.append('otp', otp)
            formData.append('password', password)

            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setProcess(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else {
                    // REDIRECT HERE
                    history.replace('/dashboard-student')
                }
            })
        }

        return(
            <Dialog open={true} fullScreen onClose={() => history.go(-1)} >
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Join us' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-md-5 col-12 mx-auto'>
                                <div className='row'>
                                    <div className='col-12'>  
                                        <TextField fullWidth variant='standard' label='Email' type='email*' className='mt-4' disabled={otpsended} value={email} onChange={(event) => setEmail(event.target.value)} />
                                    </div>
                                    {otpsended &&
                                    <div className='col-12'>
                                        <TextField fullWidth variant='standard' label='Name*' type='text' className='mt-4' value={name} onChange={(event) => setName(event.target.value)} />
                                        <TextField fullWidth variant='standard' label="Father/Mother's name*" type='text' className='mt-4' value={guardian} onChange={(event) => setGuardian(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Address' type='text' className='mt-4' value={address} onChange={(event) => setAddress(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Post office' type='text' className='mt-4' value={po} onChange={(event) => setPo(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Police station' type='text' className='mt-4' value={ps} onChange={(event) => setPs(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='City' type='text' className='mt-4' value={city} onChange={(event) => setCity(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='District' type='text' className='mt-4' value={dist} onChange={(event) => setDist(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='State' type='text' className='mt-4' value={state} onChange={(event) => setState(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Postel code' type='number' className='mt-4' value={postel} onChange={(event) => setPostel(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Aadhaar number' type='number' className='mt-4' value={aadhaar} onChange={(event) => setAadhaar(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Mobile' type='number' className='mt-4' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                                        <TextField fullWidth variant='standard' helperText='Birth*' type='date' className='mt-4' value={birth} onChange={(event) => setBirth(event.target.value)} />
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Gender*</InputLabel>
                                            <Select value={gender} onChange={(event) => setGender(event.target.value)} >
                                                <MenuItem value='MALE' >Male</MenuItem>
                                                <MenuItem value='FEMALE' >Female</MenuItem>
                                                <MenuItem value='OTHERS' >Others</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField fullWidth variant='standard' label='Nation' type='text' className='mt-4' value={nation} onChange={(event) => setNation(event.target.value)} />
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Institute*</InputLabel>
                                            <Select value={branchid} onChange={(event) => setBranchid(event.target.value)} >
                                                {branches.map((branch, i) =>
                                                    <MenuItem value={branch.branchid} key={i} >{branch.b_name}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Course*</InputLabel>
                                            <Select value={courseid} onChange={(event) => setCourseid(event.target.value)} >
                                                {courses.map((course, i) =>
                                                    <MenuItem value={course.courseid} key={i} >{course.c_title} ({course.c_name}) (Duration: {course.c_duration} Months)</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <TextField fullWidth variant='standard' className='mt-4' label='Create password*' type='text' value={password} onChange={(event) => setPassword(event.target.value)} />
                                        <TextField fullWidth variant='standard' className='mt-4' label='OTP*' type='number' value={otp} onChange={(event) => setOtp(event.target.value)} />
                                    </div> }
                                    <div className='col-12 mt-4 text-center'>
                                        {otpsended ? 
                                        <Button className='col-md-4 col-6' color='warning' size='large' variant='contained' disabled={process} onClick={() => create()} >{process ? <CircularProgress color='inherit' size={24} /> : 'Join' }</Button> :
                                        <Button className='col-md-4 col-6' color='warning' size='large' variant='contained' disabled={process} onClick={() => verify()} >{process ? <CircularProgress color='inherit' size={24} /> : 'Verify' }</Button> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

}
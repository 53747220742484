import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import SidebarAdmin from './sidebar'
import HeaderAdmin from './header'

/* MUI */
import { Alert, Box, Chip, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import { CurrencyRupee, People } from '@mui/icons-material'

export default function DashboardAdmin({api}){
    let history = useHistory()

    const [preview, setPreview] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('dashboard-preview', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPreview(data)
            }
        })
    }, [])

    const date = new Date()

    const preMonthDate = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    const pre_month = preMonthDate.toLocaleString('default', {month: 'long'})

    const [offerWarn, setOfferWarn] = useState('')
    const [offers, setOffers] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('offers', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setOfferWarn(data.warning)
            } else {
                setOffers(data)
            }
        })
    }, [])

    const [bWarn, setBWarn] = useState('')
    const [bStudent, setBStudent] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('branch-students', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setBWarn(data.warning)
            } else {
                setBStudent(data)
            }
        })
    }, [])

    const [cWarn, setCWarn] = useState('')
    const [cStudent, setCStudent] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('course-student', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setCWarn(data.warning)
            } else {
                setCStudent(data)
            }
        })
    }, [])

    const [icourse, setIcourse] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('issued-course', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setIcourse(data)
            }
        })
    }, [])

    return(
        <Box><SidebarAdmin />
        <div className='content'>
        <Container>
            <Grid direction='row' mt={3} container sx={{background: '#f0f4c3', borderRadius: 1}} p={3} justifyContent='space-between' >
                <Grid item xs={12}>
                    <Typography color='gray' variant='caption' noWrap >STUDENTS (Active by Branch/Franchise)</Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Stack direction='row' alignItems='center' >
                        <People sx={{fontSize: 70}} />
                        <Box>
                            <Typography variant='caption' ml={2} color='gray' >Students</Typography>
                            <Typography variant='h5' ml={2} fontWeight='bold' letterSpacing={5} >{preview.students}</Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item md={4} xs={12} >
                    <Stack direction='row' alignItems='center' justifyContent={{xs: 'left', md: 'center'}} >
                        <People sx={{fontSize: 70}} />
                        <Box>
                            <Typography variant='caption' ml={2} color='gray' >Active</Typography>
                            <Typography variant='h5' ml={2} fontWeight='bold' letterSpacing={5} >{preview.student_active}</Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Stack direction='row' alignItems='center' justifyContent={{xs: 'left', md: 'end'}} >
                        <People sx={{fontSize: 70}} />
                        <Box>
                            <Typography variant='caption' ml={2} color='gray' >Issued</Typography>
                            <Typography variant='h5' ml={2} fontWeight='bold' letterSpacing={5} >{preview.student_issued}</Typography>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container mt={2} justifyContent='space-between' >
                <Chip label='Student list' onClick={() => history.push('/student-list-admin')} sx={{borderRadius: 1}} />
                <Chip label='Student' onClick={() => history.push('/student-admin')} sx={{borderRadius: 1}} />
                <Chip label='Payment' onClick={() => history.push('/payment-admin')} sx={{borderRadius: 1}} />
            </Grid>
            
            <Grid container mt={0} spacing={2} >
                <Grid item xs={12} md={6} >
                    <Box sx={{background: '#b2ebf2', borderRadius: 1}} padding={3} height={300} >
                        <Typography variant='caption' color='gray' >PAYMENT</Typography>
                        <Stack direction='row' mt={2} >
                            <Typography sx={{flexGrow: 1}} >{date.toLocaleString('default', {weekday: 'long'})}</Typography>
                            <Typography variant='h6' >{Number(preview.earned_today).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                        <Stack direction='row' mt={3} >
                            <Typography sx={{flexGrow: 1}} >{date.toLocaleString('default', {month: 'long'})}</Typography>
                            <Typography variant='h6' >{Number(preview.earned_month).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                        <Stack direction='row' mt={3} >
                            <Typography sx={{flexGrow: 1}} >{date.toLocaleString('default', {month: 'long'})} - Expance</Typography>
                            <Typography variant='h6' >{Number(preview.expand_month).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack><Divider/>
                        <Stack direction='row' mt={3} >
                            <Typography sx={{flexGrow: 1}} >{ pre_month }</Typography>
                            <Typography variant='h6' >{Number(preview.earned_pre_month).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{background: '#d7ccc8', borderRadius: 1}} padding={3} height={300} overflow='scroll' >
                        <Typography variant='caption' color='gray' >ISSUED COURSE STUDENTS - {icourse.reduce((t, i) => t+Number(i.students), 0)}</Typography>
                        {icourse.map((data, i) =>
                        <Stack direction='row' mt={1} key={i} >
                            <Typography sx={{flexGrow: 1}} >{data.c_title === 'TYPING' ? data.c_name + ' TYPING' : data.c_title } - {data.c_duration} Months</Typography>
                            <Typography variant='h6' >{Number(data.students)}</Typography>
                        </Stack>)}
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} >
                    <Box sx={{background: '#fffde7', borderRadius: 1}} padding={3} height={232} overflow='scroll' >
                        <Typography variant='caption' color='gray' >LATEST OFFER</Typography>
                        
                        {offerWarn ? <Alert severity='warning' >No new offer yet!</Alert> :
                        offers.map((data, i) =>
                        <Box key={i} >
                            <Typography variant='h6' noWrap >{data.of_head}</Typography>
                            <Typography variant='caption' color='gray' >{data.of_date}</Typography>

                            <Typography variant='body1' mt={1} >
                                {data.of_offer}
                            </Typography>
                        </Box>)}
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1} mb={2}>
                <Grid item xs={12} md={4}>
                    <Box sx={{background: '#d7ccc8', borderRadius: 1}} padding={3} height={350} overflow='scroll' >
                        <Typography variant='caption' color='gray' >COURSE STUDENTS  - {cStudent.reduce((t, i) => t+Number(i.students), 0)}</Typography>
                        { cWarn ? <Alert severity='warning'>{cWarn}</Alert> :
                        cStudent.map((data, i) =>
                        <Stack direction='row' mt={1} key={i} >
                            <Typography sx={{flexGrow: 1}} >{data.c_title === 'TYPING' ? data.c_name + ' TYPING' : data.c_title} - {data.c_duration} Months</Typography>
                            <Typography variant='h6' >{Number(data.students)}</Typography>
                        </Stack>)}
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{background: '#dcedc8', borderRadius: 1}} padding={3} height={350} overflow='scroll' >
                        <Typography variant='caption' color='gray' >BRANCH/ FRANCHISE STUDENTS</Typography>

                        { bWarn ? <Alert severity='warning'>{bWarn}</Alert> :
                        bStudent.map((data, i) =>
                        <Stack direction='row' mt={1} key={i} >
                            <Typography sx={{flexGrow: 1}} >{data.b_name}</Typography>
                            <Typography variant='h6' >{Number(data.students)}</Typography>
                        </Stack>)}
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{background: '#ffecb3', borderRadius: 1}} padding={3} height={350}>
                        <Typography variant='caption' color='gray' >BALANCE</Typography>

                        <Typography variant='h6' >{date.toLocaleString('default', {month: 'long'})}</Typography>
                        <Stack direction='row'justifyContent='space-between' >
                            <Typography color='gray' >Income</Typography>
                            <Typography variant='h6' >{Number(preview.earned_month).toLocaleString('en-in')} <CurrencyRupee/> </Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between' >
                            <Typography color='gray' >Expance</Typography>
                            <Typography variant='h6' >{Number(preview.expand_month).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack><Divider/>

                        <Typography variant='h6'>{pre_month}</Typography>
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography color='gray' >Income</Typography>
                            <Typography variant='h6' >{Number(preview.earned_pre_month).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography color='gray' >Expance</Typography>
                            <Typography variant='h6' >{Number(preview.expand_pre_month).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography color='gray' >Closing balance</Typography>
                            <Typography variant='h6' >{Number(preview.c_balance).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack><Divider/>

                        <Stack direction='row' mt={1} >
                            <Typography sx={{flexGrow: 1}} >Balance</Typography>
                            <Typography variant='h6' >{Number( Number(preview.c_balance)+Number(preview.earned_month)-Number(preview.expand_month) ).toLocaleString('en-in')} <CurrencyRupee/></Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </div>
        </Box>
    );
}
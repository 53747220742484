import React, { useEffect, useState } from 'react'

import Close from './../close'

/* MUI */
import { Alert, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, LinearProgress, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function AttendanceHistoryStudent(){
    const api = '/instituteapi/student/index.php';
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [attendance, setAttendance] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        attendancehistory(year, month)
    }, [year, month])

    const attendancehistory = (year, month) => {
        setWarning('')
        var formData = new FormData()
        formData.append('view-attendance', year)
        formData.append('month', month)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setAttendance(data)
            }
        })
    }

    return(
        <Dialog open={true} fullScreen>
            <DialogTitle><Close title='Attendance history' /></DialogTitle>
            <DialogContent>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6 col-12 mx-auto'>
                            <div className='row rounded-3 p-3 lightshadow mt-4'>
                                <div className='col'>
                                    <FormControl fullWidth >
                                        <InputLabel>Year</InputLabel>
                                        <Select label='Year' value={year} onChange={(event) => setYear(event.target.value)} >
                                            <MenuItem value='2022' >2022</MenuItem>
                                            <MenuItem value='2023' >2023</MenuItem>
                                            <MenuItem value='2024' >2024</MenuItem>
                                            <MenuItem value='2025' >2025</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col'>
                                    <FormControl fullWidth >
                                        <InputLabel>Month</InputLabel>
                                        <Select label='Month' value={month} onChange={(event) => setMonth(event.target.value)} >
                                            <MenuItem value='01' >January</MenuItem>
                                            <MenuItem value='02' >February</MenuItem>
                                            <MenuItem value='03' >March</MenuItem>
                                            <MenuItem value='04' >April</MenuItem>
                                            <MenuItem value='05' >May</MenuItem>
                                            <MenuItem value='06' >June</MenuItem>
                                            <MenuItem value='07' >July</MenuItem>
                                            <MenuItem value='08' >August</MenuItem>
                                            <MenuItem value='09' >September</MenuItem>
                                            <MenuItem value='10' >October</MenuItem>
                                            <MenuItem value='11' >November</MenuItem>
                                            <MenuItem value='12' >December</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='row lightshadow p-3 rounded-3 mt-4'>
                                <div className='col-12'>
                                    {loading ? <LinearProgress color='warning' /> :
                                    warning ? <Alert severity='warning'>{warning}</Alert> :
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell align='center' >IN</TableCell>
                                                    <TableCell align='right' >OUT</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {attendance.map((atten, i) =>
                                                <TableRow key={i} >
                                                    <TableCell>{atten.payDate}</TableCell>
                                                    <TableCell align='center' >{atten.at_in}</TableCell>
                                                    <TableCell align='right' >{atten.at_out}</TableCell>
                                                </TableRow>)}
                                            </TableBody>
                                        </Table>
                                    </TableContainer> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
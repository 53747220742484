import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch, Route, useParams } from 'react-router-dom'

import SidebarBranch from './sidebar'
import HeaderBranch from './header'
import Close from '../close'

/* MUI */
import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function TeacherBranch(){
    const api = '/instituteapi/branch/index.php'
    let history = useHistory()
    const {path, url} = useRouteMatch()

    const [teachers, setTeachers] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        viewTeachers()
    }, [])

    const viewTeachers = () => {
        setWarning('')
        var formData = new FormData()
        formData.append('view-teachers', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setTeachers(data)
            }
        })
    }

    return(
        <>
        <div className='sidebar'> <SidebarBranch /> </div>
        <div className='content'>
        <HeaderBranch />
        <div className='container-fluid'>
            <div className='row p-3'>
                <div className='text-end mb-3'>
                    <Button color='warning' onClick={() => history.push(`${url}/configure/0`)} >ADD TEACHER</Button>
                </div>
                { loading ? <LinearProgress color='warning' /> :
                warning ? <Alert severity='warning'>{warning}</Alert> :
                teachers.map((teacher, i) =>
                <div className='col-md-4 col-12' key={i} >
                    <div className={`lightshadow p-3 rounded-3 mb-4 ${teacher.t_status === 'PENDING' && 'blockbg'}`}>
                        <h3 className='lspace2 fw-bold mb-3' >{teacher.t_name}</h3>
                        <p>Code: {teacher.t_code}</p>
                        <p>Mobile: {teacher.t_mobile}</p>
                        <p>Status: {teacher.t_status}</p>
                        <p>Join date: {teacher.t_joindate}</p>
                        <div className='text-end'>
                            <IconButton onClick={() => history.push(`${url}/delete/${teacher.teacherid}`)} ><DeleteIcon /></IconButton>
                            <IconButton onClick={() => history.push(`${url}/configure/${teacher.teacherid}`)} ><EditIcon /></IconButton>
                        </div>
                    </div>
                </div> ) }
            </div>
        </div>
        </div>
        <Route path={`${path}/configure/:teacherid`} component={Configure} />
        <Route path={`${path}/delete/:teacherid`} component={DeleteTeacher} />
        </>
    );

    function Configure(){

        const { teacherid } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [code, setCode] = useState('')
        const [name, setName] = useState('')
        const [address, setAddress] = useState('')
        const [mobile, setMobile] = useState('')
        const [email, setEmail] = useState('')
        const [status, setStatus] = useState('')
        const [password, setPassword] = useState('')
        const [joindate, setJoindate] = useState('')
        const [uploading, setUploading] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('teacher-detail', teacherid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setCode(data.t_code); setName(data.t_name); setAddress(data.t_address); setMobile(data.t_mobile); setEmail(data.t_email); setStatus(data.t_status); setPassword(data.t_password); setJoindate(data.t_joindate)
                }
            })
        }, [teacherid])

        const configureTeacher = () => {
            setUploading(true)
            var formData = new FormData()
            formData.append('configure-teacher', teacherid)
            formData.append('code', code)
            formData.append('name', name)
            formData.append('address', address)
            formData.append('mobile', mobile)
            formData.append('email', email)
            formData.append('status', status)
            formData.append('password', password)
            formData.append('joindate', joindate)

            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.success){
                    viewTeachers()
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Configure teacher' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField fullWidth variant='standard' type='text' label='Code*' className='mb-4' value={code} onChange={(event) => setCode(event.target.value)} />
                                <TextField fullWidth variant='standard' type='text' label='Name*' className='mb-4' value={name} onChange={(event) => setName(event.target.value)} />
                                <TextField fullWidth variant='standard' type='text' label='Address' className='mb-4' value={address} onChange={(event) => setAddress(event.target.value)} />
                                <TextField fullWidth variant='standard' type='text' label='Mobile' className='mb-4' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                                <TextField fullWidth variant='standard' type='text' label='Email' className='mb-4' value={email} onChange={(event) => setEmail(event.target.value)} />
                                <FormControl fullWidth variant='standard' className='mb-4' >
                                    <InputLabel>Status*</InputLabel>
                                    <Select value={status} onChange={(event) => setStatus(event.target.value)} >
                                        <MenuItem value='PENDING' >Pending</MenuItem>
                                        <MenuItem value='ACTIVE' >Active</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField fullWidth variant='standard' type='text' label='Password*' className='mb-4' value={password} onChange={(event) => setPassword(event.target.value)} />
                                <TextField fullWidth variant='standard' type='date' helperText='Join date*' value={joindate} onChange={(event) => setJoindate(event.target.value)} />
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button color='warning' size='large' variant='contained' disabled={uploading} onClick={() => configureTeacher()} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'UPLOAD' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function DeleteTeacher(){
        const { teacherid } = useParams()

        const [warning, setWarning] = useState('')
        const [deleting, setDeleting] = useState(false)

        const deleteProfile = () => {
            setDeleting(true); setWarning('')
            var formData = new FormData()
            formData.append('delete-teacher-profile', teacherid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.success){
                    viewTeachers(); history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogContent>
                    <div className='container-fluid mt-4 mb-5'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h2 className='fw-bold'>Do you want to remove this teacher profile?</h2>
                            </div>
                            <div className='col-12 mt-2'>
                                {warning && <Alert severity='warning'>{warning}</Alert> }
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button variant='contained' size='large' color='warning' disabled={deleting} onClick={() => deleteProfile()} >{deleting ? <CircularProgress color='inherit' size={24} /> : 'YES' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

}
import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import QRCode from "react-qr-code";

/* MUI */
import { AppBar, Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Toolbar, Typography } from '@mui/material'
import { ArrowBack, Print, WhatsApp } from '@mui/icons-material'

export default function BranchFranchiseCertificate({api}){
    const { branchid } = useParams()
    let history = useHistory()

    const [preview, setPreview] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('branch-detail', branchid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPreview(data)
            }
        })
    }, [branchid])

    useEffect(() => {
        document.title = preview.b_name
    }, [preview])

    const area = useRef()
    const printit = useReactToPrint({
        content: () => area.current
    })

    const [admin, setAdmin] = useState(true)
    const [alertDia, setAlertDia] = useState(false)
    useEffect(() => {
        fetch('/instituteapi/admin/ui.php')
        .then(res => res.json())
        .then(data => {
            if(data.loggedin === true){
                setAdmin(true); setAlertDia(true)
            } else {
                setAdmin(false)
            }
        })
    }, [])

    return(
        <><Dialog open={true} fullScreen>
            <DialogTitle>
                <AppBar color='warning'>
                    <Toolbar>
                        <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => history.go(-1)}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography sx={{flexGrow: '1'}} >CERTIFICATE</Typography>
                        <Box ><IconButton color="inherit" onClick={() => printit()} ><Print/></IconButton></Box>
                        <a href={`https://wa.me/91${preview.b_omobile}/?text=${window.location.href}`} target="_blank" style={{color: 'white'}} ><IconButton color="inherit" ><WhatsApp/></IconButton></a>
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent>
                <div className='col-md-6 col-12 mx-auto mt-5 overflow-auto'>
                    <div className='position-relative' ref={area} id="pdfContent" style={{width: '210mm', height: '296mm'}} >
                        <img src='/cert/fran.webp' alt='Franchise certificate' style={{width: '210mm', height: '296mm'}} />
                        <Box sx={{position: 'absolute', top: '43%', width: '624px', left: '170px', textAlign: 'center'}} >
                            <Typography variant='h5' noWrap sx={{fontWeight: 'bold', letterSpacing: 2}} >{preview.b_name}</Typography>
                        </Box>
                        <Box sx={{position: 'absolute', top: '58%', width: '624px', left: '170px', textAlign: 'center'}} >
                            <Typography variant='h6' noWrap sx={{letterSpacing: 2}} >{preview.b_city}</Typography>
                        </Box>
                        <Typography sx={{position: 'absolute', top: '190.7mm', left: '293px'}} variant='subtitle2' >{preview.b_code}</Typography>
                        <Typography sx={{position: 'absolute', top: '190.6mm', left: '122mm'}} variant='subtitle2' >{preview.b_joined}</Typography>
                        <Typography sx={{position: 'absolute', top: '190.6mm', left: '178mm'}} variant='subtitle2' >{preview.b_valid}</Typography>
                        <Typography sx={{position: 'absolute', top: '212.3mm', left: '80mm'}} variant='subtitle1' >{preview.b_oaadhaar}</Typography>
                        <Box sx={{position: 'absolute', bottom: '5mm', left: '3mm', background: 'white', padding: 1}} >
                            <QRCode size={105} value={`This ${preview.b_name}'s certificate (Center code - ${preview.b_code}, Validation - ${preview.b_joined} to ${preview.b_valid}) is issued by Nexus Education & Welfare foundation.`} />
                        </Box>

                        { preview.b_joined0 >= '2022-01-01' && admin === false && <><img src="/cert/seal.png" alt="Center head signature" style={{width: '80px', position: 'absolute', bottom: '17.7%', left: '27%'}} />
                        <img src="/cert/sig-head.png" alt="Center head signature" style={{width: '130px', position: 'absolute', bottom: '18%', right: '3%'}} />
                        <Box style={{width: '300px', position: 'absolute', bottom: '20%', left: '40%'}} >
                            <Typography color='red' align='left' variant='body1' >This is a computer-generated certificate and is digitally signed</Typography>
                        </Box></>}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
        <Dialog open={alertDia} fullWidth>
            <DialogContent>
                <Box sx={{padding: {md: 2}}} >
                    <Typography variant='h5' color='red' >Hey! You are logged in as Admin</Typography>
                    <Typography variant='caption' color='gray'>If you want to download the digital certificate, kindly log out of the admin account. Or log in to another browser.</Typography>
                    <Box textAlign='center' mt={3} >
                        <Button variant='outlined' onClick={() => setAlertDia(false)} >OK, GOT IT</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
        </>
    );
}
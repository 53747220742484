import React, { useEffect, useState } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import SidebarBranch from './sidebar'
import HeaderBranch from './header'
import Close from './../close'

/* MUI */
import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Snackbar, TextField } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function BranchBranch(){
    const api = '/instituteapi/branch/index.php'
    let history = useHistory()
    const { path, url } = useRouteMatch()

    const [batches, setBatches] = useState([])
    const [batchwarn, setBatchwarn] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        viewbatches()
    }, [])

    const viewbatches = () => {
        setBatchwarn('')
        var formData = new FormData()
        formData.append('view-batches', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setBatchwarn(data.warning)
            } else {
                setBatches(data)
            }
        })
    }

    return(
        <>
        <div className='sidebar'> <SidebarBranch /> </div>
        <div className='content'>
        <HeaderBranch />
        <div className='container-fluid'>
            <div className='row p-3'>
                <div className='col-12 text-end mb-2'>
                    <Button color='warning' onClick={() => history.push(`${url}/configure/0`)} >Create batch</Button>
                </div>
                {loading ? <LinearProgress color='warning' /> :
                batchwarn ? <Alert severity='warning'>{batchwarn}</Alert> :
                batches.map((batch, i) =>
                <div className='col-md-4 col-12 mb-4' key={i} >
                    <div className='lightshadow p-3 rounded-3 text-center'>
                        <AccessTimeIcon style={{fontSize: '4em'}} />
                        <h5 className='fw-bold mt-3'>{batch.bh_start} To {batch.bh_end}</h5>
                        <IconButton onClick={() => history.push(`${url}/delete/${batch.batchid}`)} ><DeleteIcon /></IconButton>
                        <IconButton onClick={() => history.push(`${url}/configure/${batch.batchid}`)} ><EditIcon /></IconButton>
                    </div>
                </div>) }
            </div>
        </div>
        </div>
        <Route path={`${path}/configure/:batchid`} component={Configure} />
        <Route path={`${path}/delete/:batchid`} component={Delete} />
        </>
    );

    function Configure(){
        const { batchid } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [start, setStart] = useState('')
        const [end, setEnd] = useState('')
        const [uploading, setUploading] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('batch-detail', batchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setStart(data.bh_start); setEnd(data.bh_end)
                }
            })
        }, [batchid])

        const upload = () => {
            setUploading(true)
            var formData = new FormData()
            formData.append('configure-batch', batchid)
            formData.append('start', start)
            formData.append('end', end)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else {
                    // GO BACK
                    viewbatches()
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Configure' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField variant='standard' type='time' fullWidth helperText='Start time' value={start} onChange={(event) => setStart(event.target.value)} />
                                <TextField variant='standard' type='time' fullWidth helperText='End time' value={end} className='mt-4' onChange={(event) => setEnd(event.target.value)} />
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button color='warning' size='large' variant='contained' disabled={uploading} onClick={() => upload()} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function Delete(){
        const { batchid } = useParams()

        const [deleting, setDeleting] = useState(false)
        const deletebatch = () => {
            setDeleting(true)
            var formData = new FormData()
            formData.append('delete-batch', batchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setDeleting(false)
                if(data.success){
                    viewbatches()
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)} >
                <DialogTitle><Close title='Delete batch' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h3 className='fw-bold' >Do you want to delete this batch?</h3>
                            </div>
                            <div className='col-12 text-center mt-4'>
                                <Button className='col-md-4 col-6' color='warning' size='large' variant='contained' disabled={deleting} onClick={() => deletebatch()} >{deleting ? <CircularProgress color='inherit' size={24} /> : 'Yes' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

}
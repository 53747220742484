import React, { useEffect, useState } from "react";
import {} from "react-router-dom";

import SidebarAdmin from "./sidebar";
import BackNew from "./../back";

// MUI
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useSnackbar } from "notistack";

export default function QuestionSubject({ api }) {
	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => {
		getSubjects();
	}, []);

	const [subjects, setSubjects] = useState([]);
	const getSubjects = () => {
		var formData = new FormData();
		formData.append("subjects", "");
		fetch(api, {
			method: "post",
			body: formData,
		})
		.then((res) => res.json())
		.then((data) => {
			if (data.status === false) {
				setSubjects(data);
			} else {
				setSubjects(data.data);
			}
		});
	};

	const [subject, setSubject] = useState('')
	const [subjectid, setSubjectid] = useState('')
	const uploadSubject = (event) => {
		if(event.key === 'Enter'){
			var formData = new FormData();
			formData.append("add-subject", subjectid);
			formData.append("subject", subject);
			fetch(api, {
				method: "post",
				body: formData,
			})
				.then((res) => res.json())
				.then((data) => {
					setSubject(''); 
					enqueueSnackbar(data.msg)
					data.status === true && getSubjects();
				});
		}
	}

	const getSubjectsDetail = (subject, subjectid) => {
		window.scroll(0, 0)
		setSubject(subject); setSubjectid(subjectid);
	}

	const readytodelete = (subjectid) => {
		setSubjectid(subjectid); setDeleteDialog(true);
	}

	const [deleteDialog, setDeleteDialog] = useState(false)
	const [deleting, setDeleting] = useState(false)
	const [getdelete, setGetdelete] = useState('')
	const deleteSubject = () => {
		setDeleting(true)
		var formData = new FormData();
		formData.append("delete-subject", subjectid);
		fetch(api, {
			method: "post",
			body: formData,
		})
		.then((res) => res.json())
		.then((data) => {
			setDeleting(false)
			if(data.status === true){
				setGetdelete(''); getSubjects(); enqueueSnackbar(data.msg); setDeleteDialog(false);
			}
		});
	}

	return (
		<Box>
			<SidebarAdmin />
			<Box className="content" mb={7} >
				<Container>
					<BackNew name="Subjects" />
					<Grid item xs={12}>
						<TextField fullWidth variant="outlined" type="text" label="Subject" value={subject} onChange={(event) => setSubject(event.target.value)} onKeyPress={(event) => uploadSubject(event)} />
					</Grid>
					<Grid item xs={12} mt={3}>
						{subjects.status === false ? (
							<Alert severity="warning">{subjects.msg}</Alert>
						) : (
							<TableContainer component={Paper}>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>#</TableCell>
											<TableCell align="left">Subject</TableCell>
											<TableCell align="right">Delete</TableCell>
											<TableCell align="right">Edit</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{subjects.map((row, i) => (
											<TableRow key={i}>
												<TableCell align="left">{subjects.length - i}</TableCell>
												<TableCell align="left">{row.subject}</TableCell>
												<TableCell align="right">
													<IconButton onClick={() => readytodelete(row.subjectid)}>
														<Delete />
													</IconButton>
												</TableCell>
												<TableCell align="right">
													<IconButton onClick={() => getSubjectsDetail(row.subject, row.subjectid)}>
														<Edit />
													</IconButton>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Grid>
				</Container>
				<Dialog open={deleteDialog} fullWidth maxWidth="xs" onClose={() => setDeleteDialog(false)}>
					<DialogContent>
						<Box pb={3}>
							<Typography fontSize={30} color="orange" fontWeight="bold">
								Do you want to delete this subject?
							</Typography>
							<Typography fontSize={12} color="darkred" mb={2}>
								Note: All data releted to this subject will be deleted. Like Questions, results etc...
							</Typography>

							<Typography fontSize={14} color="gray" mb={1}>
								To delete this subject type <i>'delete'</i> below
							</Typography>
							<TextField fullWidth variant="standard" placeholder="delete" value={getdelete} onChange={(event) => setGetdelete(event.target.value)} onKeyPress={(event) => getdelete === 'delete' && event.key === 'Enter' && deleteSubject()} />
						</Box>
					</DialogContent>
					<Divider />
					<DialogActions>
						<Button onClick={() => setDeleteDialog(false)} >Close</Button>
						<Button variant="contained" disabled={getdelete === 'delete' ? false : true} onClick={() => deleteSubject()} >{deleting ? <CircularProgress color="inherit" size={24} /> : 'Delete'}</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</Box>
	);
}

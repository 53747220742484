import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

/* MUI */
import { AppBar, Button, CircularProgress, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import { PowerSettingsNew, Refresh } from '@mui/icons-material'

export default function Header({api}){
    let history = useHistory()
    
    useEffect(() => {
        fetch('/instituteapi/student/ui.php').then(res => res.json())
        .then(data => {
            if(data.loggedin === false){
                history.replace('/login-student')
            }
        })
    }, [])

    const [preview, setPreview] = useState('')
    useEffect(() => {
        var formData = new FormData()
        formData.append('dashboard-preview', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPreview(data)
            }
        })
    }, [])

    const [open, setOpen] = useState(false)
    const [loggingout, setLoggingout] = useState(false)
    const logout = () => {
        setOpen(true)
        setLoggingout(true)
        var formData = new FormData()
        formData.append('logout', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoggingout(false)
            if(data.success){
                history.push('/login-student')
            }
        })
    }

    return(
        <><AppBar position='sticky' >
            <Toolbar>
                <Typography flexGrow={1} fontWeight='bold' letterSpacing={1} noWrap >{preview.b_name}</Typography>

                <IconButton color='inherit' onClick={() => window.location.reload()} ><Refresh /></IconButton>
                <IconButton edge='end' color='inherit' onClick={() => setOpen(true)} ><PowerSettingsNew/></IconButton>
            </Toolbar>
        </AppBar>
        <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
            <DialogContent>
                <div className='col-12 text-center mt-5'>
                    <h1 className='fw-bold'>Do you want to logout?</h1>
                </div>
                <div className='col-12 text-center mb-5 mt-4'>
                    <Button color='warning' className='col-md-4 col-6' size='large' variant='contained' disabled={loggingout} onClick={() => logout()} >{loggingout ? <CircularProgress color='inherit' size={24} /> : 'Yes' }</Button>
                </div>
            </DialogContent>
        </Dialog>
        </>
    );
}
import React, { useEffect, useState, useRef } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import SidebarBranch from './sidebar'
import HeaderBranch from './header'
import Close from './../close'
import PrintHeaderBranch from './print-header'

import QRCode from "react-qr-code";

/* MUI */
import { Alert, Box, Button, Container, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PrintIcon from '@mui/icons-material/Print';

export default function AttendanceBranch(){
    const api = '/instituteapi/branch/index.php'
    let history = useHistory()
    const { path, url } = useRouteMatch()
    let date = new Date()

    const [year, setYear] = useState(date.toLocaleDateString('en-in', {year: 'numeric'}))
    const [month, setMonth] = useState(date.toLocaleDateString('en-in', {month: '2-digit'}))
    const [day, setDay] = useState(date.toLocaleDateString('en-in', {day: '2-digit'}))
    
    const [attendance, setAttendance] = useState([])
    const [attendwarn, setAttendwarn] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        viewattendance(year, month, day)
    }, [year, month, day])

    const [branchid, setBranchid] = useState('')
    const viewattendance = (year, month, day) => {
        setAttendwarn('')
        var formData = new FormData()
        formData.append('view-attendance', year)
        formData.append('month', month)
        formData.append('day', day)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            setBranchid(data.branchid)
            if(data.status === false){
                setAttendwarn(data.msg)
            } else {
                setAttendance(data.data)
            }
        })
    }

    const [open, setOpen] = useState(false)
    const [batches, setBatches] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-batches', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBatches(data)
            }
        })
    }, [])

    const area = useRef()
    const printnow = useReactToPrint({
        content: () => area.current
    })

    return(
        <>
        <Box className='sidebar'> <SidebarBranch /> </Box>
        <Box className='content'>
        <HeaderBranch />
        <Box mt={3} mb={2} >
            <Container>
                <Grid container>
                    <Grid item xs={12} md={8} mx='auto'>
                        <Stack component={Paper} padding={2} direction={{xs: 'column', md: 'row'}} spacing={1} justifyContent='space-between' >
                            <Button color='success' variant='outlined' sx={{width: '100%'}} onClick={() => history.push(`/attendance-scanner/${branchid}`)} >Scanner</Button>
                            <Button color='info' variant='outlined' sx={{width: '100%'}} onClick={() => history.push(`${url}/qr`)} >Attendance QR</Button>
                            <Button color='primary' variant='outlined' sx={{width: '100%'}} onClick={() => setOpen(true)} >Manually</Button>
                            <Button color='error' variant='outlined' sx={{width: '100%'}} onClick={() => history.push('/attendance-report-branch')} >Attendance report</Button>
                        </Stack>
                        <Paper sx={{padding: 2, mt: 1}} >
                            <FormControl fullWidth variant='standard' >
                                <InputLabel>YEAR</InputLabel>
                                <Select disableUnderline value={year} onChange={(event) => setYear(event.target.value)} >
                                    <MenuItem value='2022' >2022</MenuItem>
                                    <MenuItem value='2023' >2023</MenuItem>
                                    <MenuItem value='2024' >2024</MenuItem>
                                    <MenuItem value='2025' >2025</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant='standard' sx={{mt: 1}} >
                                <InputLabel>MONTH</InputLabel>
                                <Select disableUnderline value={month} onChange={(event) => setMonth(event.target.value)} >
                                    <MenuItem value='01' >January</MenuItem>
                                    <MenuItem value='02' >February</MenuItem>
                                    <MenuItem value='03' >March</MenuItem>
                                    <MenuItem value='04' >April</MenuItem>
                                    <MenuItem value='05' >May</MenuItem>
                                    <MenuItem value='06' >June</MenuItem>
                                    <MenuItem value='07' >July</MenuItem>
                                    <MenuItem value='08' >August</MenuItem>
                                    <MenuItem value='09' >September</MenuItem>
                                    <MenuItem value='10' >October</MenuItem>
                                    <MenuItem value='11' >November</MenuItem>
                                    <MenuItem value='12' >December</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant='standard' sx={{mt: 1}} >
                                        <InputLabel>DAY</InputLabel>
                                        <Select disableUnderline value={day} onChange={(event) => setDay(event.target.value)} >
                                            <MenuItem value='01' >1</MenuItem>
                                            <MenuItem value='02' >2</MenuItem>
                                            <MenuItem value='03' >3</MenuItem>
                                            <MenuItem value='04' >4</MenuItem>
                                            <MenuItem value='05' >5</MenuItem>
                                            <MenuItem value='06' >6</MenuItem>
                                            <MenuItem value='07' >7</MenuItem>
                                            <MenuItem value='08' >8</MenuItem>
                                            <MenuItem value='09' >9</MenuItem>
                                            <MenuItem value='10' >10</MenuItem>
                                            <MenuItem value='11' >11</MenuItem>
                                            <MenuItem value='12' >12</MenuItem>
                                            <MenuItem value='13' >13</MenuItem>
                                            <MenuItem value='14' >14</MenuItem>
                                            <MenuItem value='15' >15</MenuItem>
                                            <MenuItem value='16' >16</MenuItem>
                                            <MenuItem value='17' >17</MenuItem>
                                            <MenuItem value='18' >18</MenuItem>
                                            <MenuItem value='19' >19</MenuItem>
                                            <MenuItem value='20' >20</MenuItem>
                                            <MenuItem value='21' >21</MenuItem>
                                            <MenuItem value='22' >22</MenuItem>
                                            <MenuItem value='23' >23</MenuItem>
                                            <MenuItem value='24' >24</MenuItem>
                                            <MenuItem value='25' >25</MenuItem>
                                            <MenuItem value='26' >26</MenuItem>
                                            <MenuItem value='27' >27</MenuItem>
                                            <MenuItem value='28' >28</MenuItem>
                                            <MenuItem value='29' >29</MenuItem>
                                            <MenuItem value='30' >30</MenuItem>
                                            <MenuItem value='31' >31</MenuItem>
                                        </Select>
                            </FormControl>
                        </Paper>
                        <Box textAlign='end'>
                            <IconButton onClick={() => printnow()} ><PrintIcon /></IconButton>
                        </Box>
                        {loading ? <LinearProgress color='warning' /> :
                        attendwarn ? <Alert severity='warning'>{attendwarn}</Alert> :
                        <Box className='col-12 pp' ref={area} >
                            <Box><PrintHeaderBranch /></Box>
                            <List className='bgGrey mb-3' >
                                <ListItem>
                                    <ListItemText>Day: {day} | Month: {month} | Year: {year} | Students: {attendance.length}</ListItemText>
                                </ListItem>
                            </List>
                            <TableContainer >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='left' >SR.NO.</TableCell>
                                            <TableCell align='left' >NAME</TableCell>
                                            <TableCell align='center' >CODE</TableCell>
                                            <TableCell align='center' >IN</TableCell>
                                            <TableCell align='center' >OUT</TableCell>
                                            <TableCell align='right' >ATTEND BY</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {attendance.map((attend, i) =>
                                        <TableRow key={i} >
                                            <TableCell align='left' >{i+1}</TableCell>
                                            <TableCell align='left' >{attend.s_name}</TableCell>
                                            <TableCell align='center' >{attend.s_code}</TableCell>
                                            <TableCell align='center' >{attend.at_in}</TableCell>
                                            <TableCell align='right' >{attend.at_out}</TableCell>
                                            <TableCell align='right' >{attend.t_name ? attend.t_name : attend.at_by === null ? 'SELF/OFFICE' : attend.at_by }</TableCell>
                                        </TableRow> )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>}
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogContent>
                <List>
                    { batches.map((batch, i) =>
                    <ListItem key={i} className='border-top' >
                        <ListItemButton onClick={() => history.push(`/attendance-make-branch/${batch.batchid}`)} >
                            <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                            <ListItemText>{batch.bh_start} TO {batch.bh_end}</ListItemText>
                        </ListItemButton>
                    </ListItem> ) }
                </List>
            </DialogContent>
        </Dialog>
        </Box>
        <Route path={`${path}/qr`} component={QR} />
        <Route path={`${path}/manually`} component={Manually} />
        </>
    );

    function QR(){
        const [qr, setQr] = useState('')
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-qr', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setQr(data.qr)
                }
            })
        }, [])
        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogContent>
                    <div className='container-fluid mt-5 mb-5'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <QRCode value={qr} />
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function Manually(){
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [students, setStudents] = useState([])
        const [studentwarn, setStudentwarn] = useState('')
        const [stuloading, setStuloading] = useState(false)

        const searchstudent = (event) => {
            if(event.keyCode === 13){
                setStuloading(true)
                var formData = new FormData()
                formData.append('view-students', event.target.value)
                fetch(api, {
                    method: 'post',
                    body: formData
                }).then(res => res.json())
                .then(data => {
                    setStuloading(false)
                    if(data.warning){
                        setStudentwarn(data.warning)
                    } else {
                        setStudents(data)
                    }
                })
            }
        }

        const attend = (studentid) => {
            var formData = new FormData()
            formData.append('configure-attendance', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.success){
                    setMessage('Attendance succedd'); setOpen(true)
                    viewattendance('', '', '')
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Manully attendance' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField fullWidth type='text' variant='standard' label='Search by student code/name' onKeyUp={(event) => searchstudent(event)} />
                            </div>

                            <div className='col-12 mt-4'>
                                {stuloading ? <LinearProgress color='warning' /> :
                                studentwarn ? <Alert severity='warning'>{studentwarn}</Alert> :
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align='right' >Code</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {students.map((stu, i) =>
                                            <TableRow key={i} onClick={() => attend(stu.studentid)} >
                                                <TableCell>{stu.s_name}</TableCell>
                                                <TableCell align='right' >{stu.s_code}</TableCell>
                                            </TableRow> )}
                                        </TableBody>
                                    </Table>
                                </TableContainer> }
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

}
import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'

import Bar from "../bar";

// MUI
import { Avatar, Box, Container, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function StudentHistoryAdmin({api, imgpath}){
    const area = useRef()
    const { studentid } = useParams()

    const [view, setView] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-student-detail', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setView(data)
            }
        })
    }, [studentid])

    const [result, setResult] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('exam-result', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setResult(data)
            }
        })
    }, [studentid])

    const [tresult, setTresult] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('typing-result', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setTresult(data)
            }
        })
    }, [studentid])

    var obtained_marks = result.reduce((t, i) => t+Number(i.r_marks), 0)
    var full_marks = result.reduce((t, i) => t+Number(i.s_fmark), 0)
    const marks = obtained_marks*100/full_marks

    return(
        <Box>
            <Bar printTrue={true} printArea={area} text='Student History' />
            <Container >
                <Grid container mt={3} >
                    <Grid item xs={12} md={9} overflow='auto' mx='auto' sx={{background: '#fafafa'}} >
                        <Box ref={area} padding={3} pt={4} pl={9} sx={{width: '210mm', height: '295mm'}} >
                            <Box>
                                <Typography variant="h5" fontWeight='bold' sx={{color: '#ef6c00'}} >NEXUS EDUCATION & WELFARE FOUNDATION</Typography>
                                <Typography>Gami Tola, Hariganj, Katihar - 854105</Typography>
                                <Typography>Bihar</Typography>
                            </Box>
                            <Box mt={1} sx={{background: '#ef6c00'}} padding={0.2} ></Box>

                            <Box mt={1} >
                                <Stack direction='row' justifyContent='space-between' alignItems='center' >
                                    <Typography>Center Name: {view.b_name}</Typography>
                                    <Typography>Center Code: {view.b_code}</Typography>
                                </Stack>
                            </Box>

                            <Box mt={1} >
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight='bold' >Detail from center,</Typography>
                                        <Table size="small" >
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Admission Date</TableCell>
                                                    <TableCell>{view.s_joindate}</TableCell>

                                                    <TableCell>Student Code</TableCell>
                                                    <TableCell>{view.s_code}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Registration Date</TableCell>
                                                    <TableCell>{view.s_enrollment && view.s_register}</TableCell>

                                                    <TableCell>Issue Date</TableCell>
                                                    <TableCell>{view.s_enrollment && view.s_issuedate}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Enr./Reg. Number</TableCell>
                                                    <TableCell>{view.s_enrollment}</TableCell>

                                                    <TableCell>Certificate Number</TableCell>
                                                    <TableCell>{view.cert_no}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Avatar src={imgpath+view.s_pic} sx={{width: 110, height: 120, borderRadius: 0, float: 'right'}} />
                                    </Grid>
                                </Grid>

                                <Typography variant="h6" fontWeight='bold' mt={1}>Student Detail,</Typography>
                                <Table size="small" >
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>{view.s_name}</TableCell>

                                            <TableCell>Father/Mother Name</TableCell>
                                            <TableCell>{view.s_guardian}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Address</TableCell>
                                            <TableCell>{view.s_address}</TableCell>

                                            <TableCell>Aadhaar Number</TableCell>
                                            <TableCell>{view.s_aadhaar}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Post office</TableCell>
                                            <TableCell>{view.s_po}</TableCell>

                                            <TableCell>Police station</TableCell>
                                            <TableCell>{view.s_ps}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>City/ Postal code</TableCell>
                                            <TableCell>{view.s_city}/ {view.s_postel}</TableCell>

                                            <TableCell>District/ State</TableCell>
                                            <TableCell>{view.s_dist}/ {view.s_state}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Gender</TableCell>
                                            <TableCell>{view.s_gender}</TableCell>

                                            <TableCell>Birth Date</TableCell>
                                            <TableCell>{view.s_birth}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Contact Number</TableCell>
                                            <TableCell>{view.s_mobile}</TableCell>

                                            <TableCell>Highest Qualification</TableCell>
                                            <TableCell>{view.qualification}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                                <Typography variant="h6" fontWeight='bold' mt={1} >Result History,</Typography>
                                
                                { view.c_title === 'TYPING' ?
                                    <>
                                        <Typography variant="body1" color='gray'>Achieved the speed in {view.c_name} Typing</Typography>
                                        <Typography variant="h6" fontWeight='bold'>{tresult.r_typing}</Typography>
                                    </>
                                :
                                <>
                                <Typography variant="body1" fontWeight='bold' noWrap align="center">{view.c_name} - {view.c_title}</Typography>
                                <Table size="small" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>TERM/MODULE</TableCell>
                                            <TableCell>SUBJECT</TableCell>
                                            <TableCell>FULL MARKS</TableCell>
                                            <TableCell>PASS MARKS</TableCell>
                                            <TableCell>OBTAINED MARKS</TableCell>
                                            <TableCell>Grade</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { result.map((data, i) =>
                                        <TableRow key={i} >
                                            <TableCell sx={{fontSize: 12}} >{data.s_term}</TableCell>
                                            <TableCell sx={{fontSize: 12}} >{data.s_subject}</TableCell>
                                            <TableCell sx={{fontSize: 12}} >{data.s_fmark}</TableCell>
                                            <TableCell sx={{fontSize: 12}} >{data.s_pmark}</TableCell>
                                            <TableCell sx={{fontSize: 12}} >{data.r_marks}</TableCell>
                                            <TableCell sx={{fontSize: 12}} >{data.r_marks >= 85 ? 'A+' : data.r_marks >= 70 ? 'A' : data.r_marks >= 60 ? 'B' : data.r_marks >= 45 ? 'C' : 'D' }</TableCell>
                                        </TableRow>) }
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell sx={{fontWeight:'bold'}} >Total</TableCell>
                                            <TableCell sx={{fontWeight:'bold'}} >{result.reduce((t, i) => t+Number(i.s_fmark), 0)}</TableCell>
                                            <TableCell sx={{fontWeight:'bold'}} >{result.reduce((t, i) => t+Number(i.s_pmark), 0)}</TableCell>
                                            <TableCell sx={{fontWeight:'bold'}} >{result.reduce((t, i) => t+Number(i.r_marks), 0)}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table> 
                                <Stack direction='row' justifyContent='space-between' mt={2} >
                                    <Typography fontWeight='bold' >Obtained Grade - { marks >= 85 ? 'A+' : marks >= 70 ? 'A' : marks >= 60 ? 'B' : marks >= '45' ? 'C' : 'D' }</Typography>
                                    <Typography fontWeight='bold' >Grade Percentage - {marks.toFixed(2)}%</Typography>
                                </Stack>
                                </>}
                            </Box>
                            
                            <Box sx={{background: '#ef6c00', width: '300px'}} padding={0.3} borderRadius={10} margin='auto' mt={3} ></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
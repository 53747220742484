import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

import Close from './../close'

/* MUI */
import { Alert, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, LinearProgress, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'

export default function ProfileStudent(){
    const api = '/instituteapi/student/index.php'
    let history = useHistory()
    const { path, url } = useRouteMatch()

    const [profile, setProfile] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-profile', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setProfile(data)
            }
        })
    }, [])

    useEffect(() => {
        viewqualification()
    }, [])

    const [qualifications, setQualifications] = useState([])
    const [quawarning, setQuawarning] = useState('')
    const [loading, setLoading] = useState(true)
    const viewqualification = () => {
        var formData = new FormData()
        formData.append('view-qualifications', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setQuawarning(data.warning)
            } else {
                setQualifications(data)
            }
        })
    }

    return(
        <Dialog open={true} fullScreen>
            <DialogTitle ><Close title='Profile' /></DialogTitle>
            <DialogContent>
                <div className='container-fluid mb-5 mt-3'>
                    <div className='row'>
                        <div className='col-md-6 col-12 mx-auto'>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <img src={`/instituteassets/studentimg/${profile.s_pic}`} className='rounded-circle globalimgsize' />
                                </div>
                                <div className='mt-4 col-12'>
                                    <TableContainer className='mb-4' >
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>{profile.s_name}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Father/Monther's name</TableCell>
                                                    <TableCell>{profile.s_guardian}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Address</TableCell>
                                                    <TableCell>{profile.s_address}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Post office</TableCell>
                                                    <TableCell>{profile.s_po}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Police station</TableCell>
                                                    <TableCell>{profile.s_ps}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>City</TableCell>
                                                    <TableCell>{profile.s_city}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>District</TableCell>
                                                    <TableCell>{profile.s_dist}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>State</TableCell>
                                                    <TableCell>{profile.s_state}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Postel</TableCell>
                                                    <TableCell>{profile.s_postel}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Aadhaar</TableCell>
                                                    <TableCell>{profile.s_aadhaar}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Mobile</TableCell>
                                                    <TableCell>{profile.s_mobile}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Birth</TableCell>
                                                    <TableCell>{profile.s_birth}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Gender</TableCell>
                                                    <TableCell>{profile.s_gender}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Nation</TableCell>
                                                    <TableCell>{profile.s_nation}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    
                                    {loading ? <LinearProgress color='warning' /> :
                                    quawarning ? <Alert severity='warning'>{quawarning}</Alert> :
                                    <TableContainer >
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Examination</TableCell>
                                                    <TableCell align='center' >Board</TableCell>
                                                    <TableCell align='center' >Pass year</TableCell>
                                                    <TableCell align='right' >Full marks</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {qualifications.map((qua, i) =>
                                                <TableRow key={i} >
                                                    <TableCell>{qua.exam}</TableCell>
                                                    <TableCell align='center' >{qua.board}</TableCell>
                                                    <TableCell align='center' >{qua.pass}</TableCell>
                                                    <TableCell align='right' >{qua.marks}</TableCell>
                                                </TableRow>)}
                                            </TableBody>
                                        </Table>
                                    </TableContainer> }
                                    
                                    <div className='mt-4 text-center'>
                                        <Button color='warning' onClick={() => history.push(`${url}/configure`)} >Add qualification</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Box textAlign='center'>
                    <Button color='inherit' size='small' onClick={() => window.location.reload()} >UPDATE APP</Button>
                </Box>
            </DialogContent>
            <Route path={`${path}/configure`} component={ConfigureQualification} />
        </Dialog>
    );

    function ConfigureQualification(){
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [exam, setExam] = useState('')
        const [board, setBoard] = useState('')
        const [pass, setPass] = useState('')
        const [marks, setMarks] = useState('')
        const [adding, setAdding] = useState(false)
        const configure = () => {
            setAdding(true)
            var formData = new FormData()
            formData.append('configure-qualification', exam)
            formData.append('board', board)
            formData.append('pass', pass)
            formData.append('marks', marks)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setAdding(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else {
                    setExam(''); setBoard(''); setPass(''); setMarks('')
                    setMessage('Add succedd.'); setOpen(true)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Add qualification' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField fullWidth variant='standard' label='Examnination' type='text' className='mb-4' value={exam} onChange={(event) => setExam(event.target.value)} />
                                <TextField fullWidth variant='standard' label='Board' type='text' className='mb-4' value={board} onChange={(event) => setBoard(event.target.value)} />
                                <TextField fullWidth variant='standard' label='Passed year' type='number' className='mb-4' value={pass} onChange={(event) => setPass(event.target.value)} />
                                <TextField fullWidth variant='standard' label='Marks obtained' type='number' className='mb-4' value={marks} onChange={(event) => setMarks(event.target.value)} />
                            </div>
                            <div className='col-12 text-center mt-4'>
                                <Button className='col-md-4 col-6' color='warning' size='large' variant='contained' disabled={adding} onClick={() => configure()} >{adding ? <CircularProgress color='warning' size={24} /> : 'Add' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

}
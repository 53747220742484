import React, { useEffect, useState } from "react";
import {  } from 'react-router-dom'
import Bar from '../bar'

// MUI
import { Alert, Box, Card, CardContent, Container, Divider, Grid, Stack, TextField, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function TestAnswerStudent({apistudent2}){
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    
    const [testdate, setTestdate] = useState(formattedDate)
    const [testresult, setTestresult] = useState([])
    const [detail, setDetail] = useState([])
    useEffect(() => {
        setTestresult([])
        var formData = new FormData()
        formData.append('get-test-answer', testdate)
        axios.post(apistudent2, formData)
        .then(function(res){
            if(res.data.result.status === false){
                setTestresult(res.data.result)
            } else {
                setTestresult(res.data.result.data)
            }

            if(res.data.detail.status === true){
                setDetail(res.data.detail.data)
            }
        })
    }, [testdate])

    const correactanswer = testresult.status !== false && testresult.length > 0 && testresult.reduce((t, i) => {
        if(i.answer === i.s_ans){
            t += 1;
        } return t
    }, 0)


    return(
        <Box mb={5} ><Bar text = 'Test series result' />
            <Container>
                <Grid container mt={3} >
                    <Grid item xs={12} md={7} mx={'auto'} >
                        <Box>
                            <TextField fullWidth helperText="Test date" variant="filled" type="date" value={testdate} onChange={(event) => setTestdate(event.target.value)} />
                        </Box>
                        <Box mb={3} mt={2} >
                            { detail.status !== false && 
                            <Card>
                                <CardContent>
                                    <Typography mb={1} fontWeight={'bold'} >MCQ test details</Typography><Divider/>
                                    <Stack direction={'row'} justifyContent={'space-between'} mt={1} >
                                        <Typography>Subject</Typography>
                                        <Typography fontWeight={'bold'} >{detail.subject}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} mt={1} >
                                        <Typography>Questions</Typography>
                                        <Typography fontWeight={'bold'} >{detail.questions}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} mt={1} mb={1} >
                                        <Typography>Time scheduled</Typography>
                                        <Typography fontWeight={'bold'} color={'red'} >{detail.timer} Minutes</Typography>
                                    </Stack><Divider/>
                                    <Stack direction={'row'} justifyContent={'space-between'} mt={1} >
                                        <Typography>Correct answer</Typography>
                                        <Typography fontWeight={'bold'} color={'green'} >{correactanswer}</Typography>
                                    </Stack>
                                </CardContent>
                            </Card> }
                        </Box>
                        <Box>
                            { testresult.status === false ? <Alert severity="error">{testresult.msg}</Alert> :
                            testresult.map((row, i) =>
                            <Card sx={{mt: 1.5}} key={i} >
                                <CardContent>
                                    <Typography mb={1} mt={1} >{i+1}. {row.question}</Typography>
                                    <Stack mt={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography fontSize={14} >Correct answer</Typography>
                                        <Typography fontSize={14} color={'green'} fontWeight={'bold'} >{row.answer}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography fontSize={14} >Your answer</Typography>
                                        <Typography fontSize={14} color={row.answer === row.s_ans ? 'green': 'red'} fontWeight={'bold'} >{row.s_ans}</Typography>
                                    </Stack>
                                </CardContent>
                            </Card> )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
import React, { useState, useRef, useEffect } from 'react'
import {  } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import Bar from './../bar'
import PrintHeaderBranch from './print-header'

/* MUI */
import { Alert, Dialog, DialogContent, FormControl, InputLabel, LinearProgress, MenuItem, Select } from '@mui/material'

export default function AttendanceReportBranch(){
    const api = '/instituteapi/branch/index.php'
    const date = new Date()

    const [year, setYear] = useState(date.toLocaleDateString('default', {year: 'numeric'}))
    const [month, setMonth] = useState(date.toLocaleDateString('default', {month: '2-digit'}))

    const area = useRef()

    useEffect(() => {
        attendanceReport(month, year)
    }, [month, year])
    const [reports, setReports] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)
    const attendanceReport = (month, year) => {
        var formData = new FormData()
        formData.append('attendance-report', month)
        formData.append('year', year)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false); setWarning('')
            if(data.warning){
                setWarning(data.warning)
            } else {
                setReports(data)
            }
        })
    }

    const [branch, setBranch] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('branch-detail', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBranch(data)
            }
        })
    }, [])

    return(
        <Dialog open={true} fullScreen>
            <Bar text='Attendance report' printTrue={true} printArea={area} />
            <DialogContent>
                <div className='container-fluid mt-4 mb-5'>
                    <div className='row'>
                        <div className='col-6 p-0'>
                            <FormControl fullWidth variant='standard' >
                                <InputLabel>FILTER YEAR</InputLabel>
                                <Select value={year} onChange={(event) => setYear(event.target.value)} >
                                    <MenuItem value='2022' >2022</MenuItem>
                                    <MenuItem value='2023' >2023</MenuItem>
                                    <MenuItem value='2024' >2024</MenuItem>
                                    <MenuItem value='2025' >2025</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-6 p-0'>
                            <FormControl fullWidth variant='standard' >
                                <InputLabel>FILTER MONTH</InputLabel>
                                <Select value={month} onChange={(event) => setMonth(event.target.value)} >
                                    <MenuItem value='01' >January</MenuItem>
                                    <MenuItem value='02' >February</MenuItem>
                                    <MenuItem value='03' >March</MenuItem>
                                    <MenuItem value='04' >April</MenuItem>
                                    <MenuItem value='05' >May</MenuItem>
                                    <MenuItem value='06' >June</MenuItem>
                                    <MenuItem value='07' >July</MenuItem>
                                    <MenuItem value='08' >August</MenuItem>
                                    <MenuItem value='09' >September</MenuItem>
                                    <MenuItem value='10' >October</MenuItem>
                                    <MenuItem value='11' >November</MenuItem>
                                    <MenuItem value='12' >December</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    { loading ? <LinearProgress color='warning' /> :
                    warning ? <Alert severity='warning'>{warning}</Alert> :
                    <div className='row pp' ref={area} >
                        <PrintHeaderBranch/>
                        <div className='col-12' >
                            <div className='mt-2' >
                                <p className='ms-1' >Students attendance report</p>
                                <table className='table table-borderless' style={{marginLeft: '-4px', marginTop: '-20px'}} >
                                    <thead>
                                        <tr><td>Students</td><td>{reports.length}</td><td>Month/ Year</td><td>{month === '01' ? 'Janyary' : month === '02' ? 'February' : month === '03' ? 'March' : month === '04' ? 'April' : month === '05' ? 'May' : month === '06' ? 'June' : month === '07' ? 'July' : month === '08' ? 'August' : month === '09' ? 'September' : month === '10' ? 'October' : month === '11' ? 'November' : month === '12' && 'December' }/ {year}</td></tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                        <div className='col-12 table-responsive' >
                            <table className='table table-borderless table-sm report-table border-top'>
                                <thead>
                                    <tr>
                                        <td>S.N.</td>
                                        <td>NAME</td>
                                        <td>CODE</td>
                                        <td>D1</td>
                                        <td>D2</td>
                                        <td>D3</td>
                                        <td>D4</td>
                                        <td>D5</td>
                                        <td>D6</td>
                                        <td>D7</td>
                                        <td>D8</td>
                                        <td>D9</td>
                                        <td>D10</td>
                                        <td>D11</td>
                                        <td>D12</td>
                                        <td>D13</td>
                                        <td>D14</td>
                                        <td>D15</td>
                                        <td>D16</td>
                                        <td>D17</td>
                                        <td>D18</td>
                                        <td>D19</td>
                                        <td>D20</td>
                                        <td>D21</td>
                                        <td>D22</td>
                                        <td>D23</td>
                                        <td>D24</td>
                                        <td>D25</td>
                                        <td>D26</td>
                                        <td>D27</td>
                                        <td>D28</td>
                                        <td>D29</td>
                                        <td>D30</td>
                                        <td>D31</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    { reports.map((report, i) =>
                                    <tr key={i} >
                                        <td>{i+1}</td>
                                        <td>{report.s_name}</td>
                                        <td>{report.s_code}</td>
                                        <td>{report.d1 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d2 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d3 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d4 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d5 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d6 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d7 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d8 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d9 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d10 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d11 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d12 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d13 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d14 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d15 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d16 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d17 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d18 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d19 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d20 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d21 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d22 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d23 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d24 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d25 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d26 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d27 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d28 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d29 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d30 === '0' ? 'A' : 'P'}</td>
                                        <td>{report.d31 === '0' ? 'A' : 'P'}</td>
                                    </tr> ) }
                                </tbody>
                            </table>
                        </div>
                    </div> }
                </div>
            </DialogContent>
        </Dialog>
    );
}
import React, { useEffect, useState } from 'react'
import {  } from 'react-router-dom'

/* HEADER */
import PagesHeader from './header';
import PagesFooter from './footer'

/* MUI */
import { Alert, Dialog, LinearProgress } from '@mui/material';

export default function Gallery(){

    const [warning, setWarning] = useState('')
    const [gallery, setGallery] = useState([])
    const [loading, setLoading] = useState(true) 
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-gallery', '')
        fetch('/instituteapi/public/index.php', {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setGallery(data)
            }
        })
    }, [])

    const [viewimg, setViewimg] = useState(false)
    const [img, setImg] = useState('')

    const fullView = (img) => {
        setImg(img); setViewimg(true)
    }

    return(
        <><PagesHeader />
        <div className='container-fluid mt-3 mb-5'>
            <div className='row'>
                {loading ? <LinearProgress color='warning' /> :
                warning ? <Alert severity='warning'>{warning}</Alert> :
                gallery.map((g, i) => 
                    <div className='col-md-4 col-6 mb-3' key={i} >
                        <img src={`/instituteassets/gallery/${g.g_images}`} alt={g.g_images} onClick={() => fullView(g.g_images)} />
                    </div>
                ) }
            </div>
            <Dialog open={viewimg} fullWidth onClose={() => setViewimg(false)} >
                <img src={`/instituteassets/gallery/${img}`} alt='Full view' />
            </Dialog>
        </div><PagesFooter /></>
    );
}
import React, { useEffect, useRef, useState } from 'react'
import {  } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import SidebarBranch from './../branch/sidebar'
import HeaderBranch from './../branch/header'
import PrintHeader from './../branch/print-header'

/* MUI */
import { IconButton, Typography } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print';

export default function PrintPayment(){
    const api = '/instituteapi/branch/index.php'
    
    const area = useRef()
    const printnow = useReactToPrint({
        content: () => area.current
    })

    const [payments, setPayments] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('payment-detail-print', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPayments(data)
            }
        })
    }, [])

    var x = 0;

    return(
        <>
        <div className='sidebar'> <SidebarBranch /> </div>
        <div className='content'>
        <HeaderBranch />
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-md-10 col-12 mx-auto text-center mb-3'>
                    <div className='text-end'><IconButton onClick={() => printnow()} ><PrintIcon /></IconButton></div>
                    <Typography color='gray' variant='caption' >To better view, print this page in landscape</Typography>
                </div>
                <div className='col-md-10 col-12 mx-auto pp' ref={area} >
                    <PrintHeader />
                    <div className='col-12 table-responsive'>
                        <table className='table table-sm' style={{fontSize: '14px'}} >
                            <thead>
                                <tr>
                                    <td>SR. NO.</td>
                                    <td>NAME</td>
                                    <td>CODE</td>
                                    <td>BATCH</td>
                                    <td>COURSE</td>
                                    <td>FEES</td>
                                    <td>PAID</td>
                                    <td>DUES</td>
                                    <td>LAST PAYED</td>
                                    <td>REMARK</td>
                                </tr>
                            </thead>
                            <tbody>
                                { payments.map((pay, i) =>
                                Number(pay.fees) !== Number(pay.payed) &&
                                <tr key={i} >
                                    <td>{ x = x + 1 }</td>
                                    <td>{pay.s_name}</td>
                                    <td>{pay.s_code}</td>
                                    <td>{pay.bh_start} To {pay.bh_end}</td>
                                    <td>{pay.c_title === 'TYPING' ? pay.c_name : pay.c_title }</td>
                                    <td>{Number(pay.fees).toLocaleString('en-in')} &#8377;</td>
                                    <td>{Number(pay.payed).toLocaleString('en-in')} &#8377;</td>
                                    <td>{Number(Number(pay.fees) - Number(pay.payed)).toLocaleString('en-in')} &#8377;</td>
                                    <td>{pay.last_payed}</td>
                                    <td></td>
                                </tr> ) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}
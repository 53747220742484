import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

/* MUI */
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import { ArrowBack, Print } from '@mui/icons-material'

export default function AdmissionForm({api, img, imgBranch}){
    const { studentid } = useParams()
    const history = useHistory()

    const area = useRef()
    const printit = useReactToPrint({
        content:() => area.current
    })
    
    const [view, setView] = useState([])

    useEffect(() => {
        var formData = new FormData()
        formData.append('student-admission-form', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setView(data)
            }
        })
    }, [studentid])

    const [qualifications, setQualifications] = useState([])
    
    useEffect(() => {
        var formData = new FormData()
        formData.append('student-qualification', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setQualifications(data)
            }
        })
    }, [studentid])

    useEffect(() => {
        document.title = view.s_name + " ADMISSION FORM"
    }, [view])

    return(
        <Dialog open={true} fullScreen >
            <AppBar position="sticky" component='nav' color="warning">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => history.go(-1)}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography sx={{flexGrow: '1'}} >ADMISSION FORM</Typography>
                    <IconButton onClick={() => printit()} ><Print/></IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <div className='container-fluid'>
                    <div className='col-md-8 col-12 mx-auto'>
                        <div id='printArea' style={{position: 'relative'}} ref={area} >
                            <img src='/cert/form.webp' alt='New Form' style={{height: '1137px'}} />
                            <div style={{position: 'absolute', top: '100px'}} className='ms-3' >
                                <div className='col-12 text-center'>
                                    <h3 className='fw-bold lspace3'>ADMISSION FORM</h3>
                                </div>
                                <div className='row mt-4 border-bottom' >
                                    <div className='col-10'>
                                        <h6 className='fw-bold ms-2'>From center: {view.b_name} ({view.b_code})</h6>
                                        <table className='table table-borderless col-12'>
                                            <tbody>
                                                <tr><td>Student's code</td><td>{view.s_code}</td><td>Date of admission</td><td>{view.s_joindate}</td></tr>
                                                <tr><td>Registration no</td><td>{view.s_enrollment}</td><td>Date of Reg.</td><td>{view.s_enrollment !== null && view.s_register}</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-2 flex text-center'>
                                        <small>Place a hard copy here.</small>
                                    </div>
                                </div>
                                <div className='row border-bottom'>
                                    <div className='col-12'>
                                        <table className='table table-borderless col-12'>
                                            <tbody>
                                                <tr><td>Candidate name</td><td>{view.s_name}</td></tr>
                                                <tr><td>Father's name</td><td>{view.s_guardian}</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='row border-bottom'>
                                    <div className='col-12 mt-3'>
                                        <h5 className='fw-bold ms-2'>Permanent address</h5>
                                    </div>
                                    <div className='col-12'>
                                        <table className='table table-borderless'>
                                            <tbody>
                                                <tr><td>Village/Town/City</td><td>{view.s_address}</td></tr>
                                            </tbody>
                                        </table>
                                        <table className='table table-borderless'>
                                            <tbody>
                                                <tr><td>Thana</td><td>{view.s_ps}</td><td>District</td><td>{view.s_dist}</td><td>State</td><td>{view.s_state}</td></tr>

                                                <tr><td>Post office</td><td>{view.s_po}</td><td>Gender</td><td>{view.s_gender}</td></tr>
                                                <tr><td>Aadhaar No.</td><td>{view.s_aadhaar}</td><td>Mobile</td><td>{view.s_mobile}</td><td>PIN</td><td>{view.s_postel}</td></tr>
                                                <tr><td>Date of birth</td><td>{view.s_birth}</td><td>Nationality</td><td>{view.s_nation}</td><td>Email</td><td>{view.s_email}</td></tr>
                                                <tr><td>Course</td><td>{view.c_title === 'TYPING' ? view.c_name+' TYPING' : view.c_title }</td><td>Duration</td><td>{view.c_duration} Months</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                <div className='row border-bottom' >
                                    <div className='col-12 mt-3'>
                                        <h5 className='fw-bold ms-2'>Academic Qualification</h5>
                                    </div>
                                    <div className='col-12'>
                                        <table className='table table-borderless col-12'>
                                            <thead>
                                                <tr>
                                                    <th>Examination</th>
                                                    <th>Board/University</th>
                                                    <th>Year of passing</th>
                                                    <th>Marks obtained</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {qualifications.map((qua, i) =>
                                                <tr>
                                                    <td>{qua.exam}</td>
                                                    <td>{qua.board}</td>
                                                    <td>{qua.pass}</td>
                                                    <td>{qua.marks}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='row ms-1' style={{position: 'absolute', bottom: 60}} >
                                <div className='col-12'>
                                    <h5 className='fw-bold ms-2 mt-3'>Declaration</h5>
                                </div>
                                <div className='col-12'>
                                    <p className='ms-2' >I hereby declare that all above details are true to the best of my knowledge. I also will abide by the rules of the institute.</p>
                                </div>
                            </div>
                            
                            {view.b_sig &&  <img src={imgBranch+view.b_sig} alt='Center signature' style={{width: '160px', height: '40px', position: 'absolute', bottom: '8mm', left: '8.5mm'}} />}
                            {view.s_sig && <img src={img+view.s_sig} alt='Student signature' style={{width: '160px', height: '40px', position: 'absolute', bottom: '8mm', right: '8.5mm'}} />}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
import React, { useEffect, useState, useRef } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import HeaderBranch from './header'
import SidebarBranch from './sidebar'
import Close from './../close'

import PrintHeaderBranch from './print-header'

/* MUI */
import { Alert, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import { CloseRounded } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

export default function EntryBranch({api}){
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const { path, url } = useRouteMatch()
    const history = useHistory()
    const date = new Date()

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'))

    const [desc, setDesc] = useState('')
    const [type, setType] = useState('')
    const [amount, setAmount] = useState('')
    const [paydate, setPaydate] = useState('')

    const [debit, setDebit] = useState(0)
    const [credit, setCredit] = useState(0)
    const [insbalance, setInsbalance] = useState(0)
    const [view, setView] = useState([])

    useEffect(() => {
        viewentry()
    }, [])
    const viewentry = () => {
        var formData = new FormData()
        formData.append('total-entry', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setDebit(data.debit); setCredit(data.credit); setInsbalance(data.ins_income);
                setView(data)
            }
        })
    }

    const upload = (event) => {
        setType(event)
        var formData = new FormData()
        formData.append('configure-entry', event)
        formData.append('amount', amount)
        formData.append('desc', desc)
        formData.append('paydate', paydate)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setMessage(data.warning); setOpen(true)
            } else if(data.success) {
                viewentry(); setDesc(''); setType(''); setAmount('')
            }
        })
    }

    return(
        <>
        <div className='sidebar'> <SidebarBranch /> </div>
        <div className='content'>
        <HeaderBranch />
        <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-md-8 col-12 mx-auto flex mt-5' >
                    <div className='row p-3 rounded-3 lightshadow'>
                        <div className='col-8 p-0 mb-2'>
                            <TextField fullWidth variant='outlined' type='text' label='Description' value={desc} onChange={(event) => setDesc(event.target.value)} />
                        </div>
                        <div className='col-4 p-0 mb-2'>
                            <FormControl fullWidth >
                                <InputLabel>Default</InputLabel>
                                <Select label='Default' value={desc} onChange={(event) => setDesc(event.target.value)} >
                                    <MenuItem value='Electriciy bill' >Electricity bill</MenuItem>
                                    <MenuItem value='Oil' >Oil</MenuItem>
                                    <MenuItem value='Recharge' >Rechage</MenuItem>
                                    <MenuItem value='Institute' >Institute</MenuItem>
                                    <MenuItem value='Grocery' >Grocery</MenuItem>
                                    <MenuItem value='Water' >Water</MenuItem>
                                    <MenuItem value='Ink' >Ink</MenuItem>
                                    <MenuItem value='Marker' >Marker</MenuItem>
                                    <MenuItem value='Register' >Register</MenuItem>
                                    <MenuItem value='A4 paper' >A4 paper</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-4 p-0 mb-2'>
                            <TextField fullWidth type='number' label='Amount &#8377;' value={amount} onChange={(event) => setAmount(event.target.value)} />
                        </div>
                        <div className='col-md-4 p-0 mb-2'>
                            <TextField fullWidth type='date' label=' ' value={paydate} onChange={(event) => setPaydate(event.target.value)} />
                        </div>
                        <div className='col-md-4 p-0 mb-2'>
                            <FormControl fullWidth >
                                <InputLabel>Type</InputLabel>
                                <Select label='Type' value={type} onChange={(event) => upload(event.target.value)} >
                                    <MenuItem value='DEBIT' >EXPANCE</MenuItem>
                                    <MenuItem value='CREDIT' >INCOME</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='row mt-4 rounded-3 p-3 lightshadow pointer' >
                        <div className='col-md-4 col-12' onClick={() => history.push(`${url}/history`)} >
                            <div className='rounded-3 flex text-center' style={{border: 'thin dashed #cccccc', height: '200px'}} >
                                <h5 className='lspace3' >EXPANCE</h5>
                                <h3 className='fw-bold'>{Number(debit).toLocaleString('en-in')} &#8377;</h3>
                            </div>
                        </div>
                        <div className='col-md-4 col-12' onClick={() => history.push(`${url}/history`)} >
                            <div className='rounded-3 flex text-center' style={{border: 'thin dashed #cccccc', height: '200px'}} >
                                <h5 className='lspace3' >INCOME</h5>
                                <h3 className='fw-bold'>{Number(credit).toLocaleString('en-in')} &#8377;</h3>
                            </div>
                        </div>
                        <div className='col-md-4 col-12' onClick={() => history.push(`${url}/ins-history`)} >
                            <div className='rounded-3 flex text-center' style={{border: 'thin dashed #cccccc', height: '200px'}} >
                                <h5 className='lspace3' >INSTITUTE</h5>
                                <h3 className='fw-bold'>{Number(insbalance).toLocaleString('en-in')} &#8377;</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Route path={`${path}/history`} component={Entryhistory} />
        <Route path={`${path}/ins-history`} component={InstituteHistory} />
        </>
    );

    function Entryhistory(){

        const close_dialog = () => {
            viewentry(); history.go(-1)
        }

        const [year, setYear] = useState(date.toLocaleDateString('en-in', {year: 'numeric'}))
        const [month, setMonth] = useState(date.toLocaleDateString('en-in', {month: '2-digit'}))
        const [warning, setWarning] = useState('')

        const [loading, setLoading] = useState(true)

        const [cbalance, setCbalance] = useState(0)
        const [credit, setCredit] = useState(0)
        const [debit, setDebit] = useState(0)
        const [inshis, setInshis] = useState([])
        const [histories, setHistories] = useState([])

        useEffect(() => {
            entry_history(year, month)
        }, [year, month])

        const entry_history = (year, month) => {
            var formData = new FormData()
            formData.append('monthly-entry-history', year)
            formData.append('month', month)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setCbalance(data.cbalance); setCredit(data.credit); setDebit(data.debit)
                    setInshis(data.inshistory); setHistories(data.manualhistory)
                }
            })
        }

        const [deleting, setDeleting] = useState('')
        const deleteentry = (tableid) => {
            setDeleting(tableid)
            var formData = new FormData()
            formData.append('delete-entry', tableid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.success){
                    entry_history(year, month)
                }
            })
        }

        const area = useRef()
        const printit = useReactToPrint({
            content: () => area.current
        })

        let x = 0
        let y = 0

        return(
            <Dialog open={true} fullWidth={true} maxWidth='md' fullScreen={fullScreen} onClose={() => close_dialog()} >
                <DialogTitle>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6'>Entry history</Typography>
                        <Box>
                            <IconButton onClick={() => printit()} ><PrintIcon /></IconButton>
                            <IconButton onClick={() => close_dialog()} ><CloseRounded/></IconButton>
                        </Box>
                    </Stack>
                </DialogTitle><Divider/>
                <DialogContent>
                    <Box className='container-fluid mb-5' ref={area} sx={{padding: 3}} >
                        <PrintHeaderBranch />
                        <Box className='row' sx={{displayPrint: 'none'}} mb={3} >
                            <div className='col'>
                                <FormControl fullWidth variant='standard' >
                                    <InputLabel>Year</InputLabel>
                                    <Select value={year} onChange={(event) => setYear(event.target.value)} >
                                        <MenuItem value='2019' >2019</MenuItem>
                                        <MenuItem value='2020' >2020</MenuItem>
                                        <MenuItem value='2021' >2021</MenuItem>
                                        <MenuItem value='2022' >2022</MenuItem>
                                        <MenuItem value='2023' >2023</MenuItem>
                                        <MenuItem value='2024' >2024</MenuItem>
                                        <MenuItem value='2025' >2025</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col'>
                                <FormControl fullWidth variant='standard' >
                                    <InputLabel>Month</InputLabel>
                                    <Select value={month} onChange={(event) => setMonth(event.target.value)} >
                                        <MenuItem value='01' >January</MenuItem>
                                        <MenuItem value='02' >February</MenuItem>
                                        <MenuItem value='03' >March</MenuItem>
                                        <MenuItem value='04' >April</MenuItem>
                                        <MenuItem value='05' >May</MenuItem>
                                        <MenuItem value='06' >June</MenuItem>
                                        <MenuItem value='07' >July</MenuItem>
                                        <MenuItem value='08' >August</MenuItem>
                                        <MenuItem value='09' >September</MenuItem>
                                        <MenuItem value='10' >October</MenuItem>
                                        <MenuItem value='11' >November</MenuItem>
                                        <MenuItem value='12' >December</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Box>
                        <Box mb={3} >
                            <Table size='small' >
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Month</TableCell>
                                        <TableCell>{new Date(2000, month-1).toLocaleString(undefined, {month: 'long'})} {year}</TableCell>

                                        <TableCell>Closing balance ({new Date(2000, month-2).toLocaleString(undefined, {month: 'long'})})</TableCell>
                                        <TableCell>{Number(cbalance).toLocaleString('en-in')} &#8377;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Income</TableCell>
                                        <TableCell>{Number(credit).toLocaleString('en-in')} &#8377;</TableCell>

                                        <TableCell>Expance</TableCell>
                                        <TableCell>{Number(debit).toLocaleString('en-in')} &#8377;</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{new Date(2000, month-1).toLocaleString(undefined, {month: 'long'})} balance</TableCell>
                                        <TableCell>{Number(credit-debit).toLocaleString('en-in')} &#8377;</TableCell>

                                        <TableCell>Total</TableCell>
                                        <TableCell>{Number(Number(cbalance)+Number(credit-debit)).toLocaleString('en-in')} &#8377;</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                        <div className='row mt-3'>
                            <div className='col-12 mb-5'>
                                <Typography color='gray' variant='body2' >Income</Typography>
                                    {loading ? <LinearProgress color='warning' /> :
                                    <TableContainer>
                                        <Table size='small' >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Sr.No.</TableCell>
                                                    <TableCell align='left' >Description</TableCell>
                                                    <TableCell align='left' >Date</TableCell>
                                                    <TableCell align='right' >Income</TableCell>
                                                    <TableCell align='right' className='d-print-none' >Delete</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {histories.map((his, i) =>
                                                Number(his.e_credit) > 0 &&
                                                <TableRow key={i} >
                                                    <TableCell align='left' >{x = x+1}</TableCell>
                                                    <TableCell align='left' >{his.e_description}</TableCell>
                                                    <TableCell align='left' >{his.e_date}</TableCell>
                                                    <TableCell align='right' >{Number(his.e_credit).toLocaleString('en-in')} &#8377;</TableCell>
                                                    <TableCell align='right' className='d-print-none' ><IconButton onDoubleClick={() => deleteentry(his.e_table_id)} >{his.e_table_id === deleting ? <CircularProgress color='inherit' size={24} /> : <DeleteIcon />}</IconButton></TableCell>
                                                </TableRow> )}
                                                { inshis.map((ins, i) => {
                                                    return(
                                                    <TableRow key={i} >
                                                        <TableCell align='left' >{x = x+1}</TableCell>
                                                        <TableCell align='left' >{ins.s_name}, {ins.s_code}, {ins.p_description}</TableCell>
                                                        <TableCell align='left' >{ins.p_date}</TableCell>
                                                        <TableCell align='right' >{Number(ins.p_amount).toLocaleString('en-in')} &#8377;</TableCell>
                                                    </TableRow>);
                                                }) }
                                            </TableBody>
                                        </Table>
                                    </TableContainer> }
                            </div>
                            <div className='col-12 mt-3'>
                                    <Typography color='gray' variant='body2' >Expance</Typography>
                                    {loading ? <LinearProgress color='warning' /> :
                                    warning ? <Alert severity='warning'>{warning}</Alert> :
                                    <TableContainer>
                                        <Table size='small' >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Sr.No.</TableCell>
                                                    <TableCell align='left' >Description</TableCell>
                                                    <TableCell align='left' >Date</TableCell>
                                                    <TableCell align='right' >Expance</TableCell>
                                                    <TableCell align='right' className='d-print-none' >Delete</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {histories.map((his, i) =>
                                                Number(his.e_debit) > 0 &&
                                                <TableRow key={i} >
                                                    <TableCell align='left' >{y = y+1}</TableCell>
                                                    <TableCell align='left' >{his.e_description}</TableCell>
                                                    <TableCell align='left' >{his.e_date}</TableCell>
                                                    <TableCell align='right' >{Number(his.e_debit).toLocaleString('en-in')} &#8377;</TableCell>
                                                    <TableCell align='right' className='d-print-none' ><IconButton onDoubleClick={() => deleteentry(his.e_table_id)} >{his.e_table_id === deleting ? <CircularProgress color='inherit' size={24} /> : <DeleteIcon />}</IconButton></TableCell>
                                                </TableRow> )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer> }
                            </div>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    function InstituteHistory(){
        
        const [year, setYear] = useState(date.toLocaleDateString('en-in', {year: 'numeric'}))
        const [month, setMonth] = useState(date.toLocaleDateString('en-in', {month: '2-digit'}))
        const [inshis, setInshis] = useState([])
        const [warning, setWarning] = useState('')
        const [loading, setLoading] = useState(true)

        useEffect(() => {
            inshistory(year, month)
        }, [year, month])

        const inshistory = (year, month) => {
            setWarning('')
            var formData = new FormData();
            formData.append('institute-entry', year)
            formData.append('month', month)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setInshis(data)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='md' onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Institute payment history' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mt-3 mb-5'>
                        <div className='row'>
                            <div className='col'>
                                <FormControl fullWidth>
                                    <InputLabel>Year</InputLabel>
                                    <Select label='Year' value={year} onChange={(event) => setYear(event.target.value)} >
                                        <MenuItem value='2022' >2022</MenuItem>
                                        <MenuItem value='2023' >2023</MenuItem>
                                        <MenuItem value='2024' >2024</MenuItem>
                                        <MenuItem value='2025' >2025</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col'>
                                <FormControl fullWidth>
                                    <InputLabel>Month</InputLabel>
                                    <Select label='Month' value={month} onChange={(event) => setMonth(event.target.value)} >
                                        <MenuItem value='01' >January</MenuItem>
                                        <MenuItem value='02' >February</MenuItem>
                                        <MenuItem value='03' >March</MenuItem>
                                        <MenuItem value='04' >April</MenuItem>
                                        <MenuItem value='05' >May</MenuItem>
                                        <MenuItem value='06' >June</MenuItem>
                                        <MenuItem value='07' >July</MenuItem>
                                        <MenuItem value='08' >August</MenuItem>
                                        <MenuItem value='09' >September</MenuItem>
                                        <MenuItem value='10' >October</MenuItem>
                                        <MenuItem value='11' >November</MenuItem>
                                        <MenuItem value='12' >December</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div className='col-12 mt-3'>
                                {loading ? <LinearProgress color='warning' /> :
                                warning ? <Alert severity='warning'>{warning}</Alert> :
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Student name</TableCell>
                                                <TableCell align='center' >Code</TableCell>
                                                <TableCell align='center' >Payment ID</TableCell>
                                                <TableCell align='center' >Amount</TableCell>
                                                <TableCell align='center' >Description</TableCell>
                                                <TableCell align='right' >Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {inshis.map((ins, i) =>
                                            <TableRow key={i} >
                                                <TableCell>{ins.s_name}</TableCell>
                                                <TableCell align='center' >{ins.s_code}</TableCell>
                                                <TableCell>{ins.paymentid}</TableCell>
                                                <TableCell align='center' >{ins.p_amount}</TableCell>
                                                <TableCell align='center' >{ins.p_description}</TableCell>
                                                <TableCell align='right' >{ins.p_date}</TableCell>
                                            </TableRow>)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>}
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom'
import Bar from '../../bar'

// MUI
import { Alert, Box, Container, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'
import axios from "axios";

export default function TestReportStudents({apipublic}){
    let history = useHistory()
    const { batchid } = useParams()

    const [batch, setBatch] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('batch-students', batchid)
        axios.post(apipublic, formData)
        .then(function(res){
            if(res.data.status === false){
                setBatch(res.data)
            } else {
                setBatch(res.data.data)
            }
        })
    }, [batchid])

    return(
        <Box mb={5} ><Bar text={'Batch students'} />
            <Container>
                <Grid container mt={3} >
                    <Grid item xs={12} md={7} mx={'auto'}>
                        <Box>
                            <Typography mb={2} textAlign={'center'} >Select student for test report</Typography>
                            <Divider/>
                            { batch.status === false ? <Alert severity="warning">{batch.msg}</Alert> :
                            <List>
                                <Typography>Batch A</Typography>
                                { batch.map((row, i) =>
                                row.batchnum === 'a' &&
                                <ListItem key={i} >
                                    <ListItemButton onClick={() => history.push(`/test-report/${row.studentid}`)} >
                                        <ListItemIcon>{i+1}</ListItemIcon>
                                        <ListItemText>{row.s_name} ({row.s_code}) - {row.batchnum}</ListItemText>
                                        <ArrowForwardIos/>
                                    </ListItemButton>
                                </ListItem>) }
                                <Typography>Batch B</Typography>
                                { batch.map((row, i) =>
                                row.batchnum === 'b' &&
                                <ListItem key={i} >
                                    <ListItemButton onClick={() => history.push(`/test-report/${row.studentid}`)} >
                                        <ListItemIcon>{i+1}</ListItemIcon>
                                        <ListItemText>{row.s_name} ({row.s_code}) - {row.batchnum}</ListItemText>
                                        <ArrowForwardIos/>
                                    </ListItemButton>
                                </ListItem>) }
                            </List> }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from 'react-router-dom'

import Bar from "../bar";
import PrintHeaderBranch from './print-header'

// MUI
import { Box, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

export default function SingleAttendanceReportBranch({api}){
    let history = useHistory()
    const date = new Date()
    const { studentid } = useParams()

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    const area = useRef()

    const [year, setYear] = useState(date.toLocaleDateString('default', {year: 'numeric'}))
    const [month, setMonth] = useState(date.toLocaleDateString('default', {month: '2-digit'}))

    function getAllDaysInMonth(year, month) {
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const days = [];
        
        for (let i = 1; i <= daysInMonth; i++) {
          const date = new Date(year, month, i);
          const dayNumber = date.getDate();
          const dayName = date.toLocaleString('default', { weekday: 'long' });
          
          days.push({ dayNumber, dayName });
        }
        
        return days;
    }
      
    const daysinmonth = getAllDaysInMonth(year, month-1);

  useEffect(() => {
    view_attend(year, month)
  }, [year, month])

    const [getAttend, setAttend] = useState([])
    const attend = getAttend.map(att => Number(att.attend))

    const view_attend = (year, month) => {
        setAttend([])
        var formData = new FormData()
        formData.append('daily-attend', studentid)
        formData.append('year', year)
        formData.append('month', month)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setAttend(data)
            }
        })
    }

    const [student, setStudent] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('student-history', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setStudent(data)
            }
        })
    }, [studentid])
      

    return(
        <Dialog open={true} fullWidth fullScreen={fullScreen} onClose={() => history.go(-1)}>
            <Bar text='Attendance report' printTrue={true} printArea={area} />
            <DialogContent>
                <Stack direction='row' spacing={2} mb={3} >
                    <FormControl fullWidth variant="standard" >
                        <InputLabel>Year</InputLabel>
                        <Select value={year} onChange={(event) => setYear(event.target.value)} >
                            <MenuItem value='2022' >2022</MenuItem>
                            <MenuItem value='2023' >2023</MenuItem>
                            <MenuItem value='2024' >2024</MenuItem>
                            <MenuItem value='2025' >2025</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="standard" >
                        <InputLabel>Month</InputLabel>
                        <Select value={month} onChange={(event) => setMonth(event.target.value)} >
                            <MenuItem value='01' >January</MenuItem>
                            <MenuItem value='02' >February</MenuItem>
                            <MenuItem value='03' >March</MenuItem>
                            <MenuItem value='04' >April</MenuItem>
                            <MenuItem value='05' >May</MenuItem>
                            <MenuItem value='06' >June</MenuItem>
                            <MenuItem value='07' >July</MenuItem>
                            <MenuItem value='08' >August</MenuItem>
                            <MenuItem value='09' >September</MenuItem>
                            <MenuItem value='10' >October</MenuItem>
                            <MenuItem value='11' >November</MenuItem>
                            <MenuItem value='12' >December</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>

                <Box ref={area} sx={{'@media print' : {padding: '2em 3em 0em 3em'}}} >
                    <PrintHeaderBranch />

                    <Typography variant="body1" mb={0} fontFamily='serif' letterSpacing={1} >Attendance report</Typography>
                    <Table size="small" >
                        <TableBody>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>{student.s_name}</TableCell>


                                <TableCell>Code</TableCell>
                                <TableCell>{student.s_code}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Month</TableCell>
                                <TableCell>{new Date(2000, month-1).toLocaleString(undefined, {month: 'long'})} {year}</TableCell>


                                <TableCell>Days attend</TableCell>
                                <TableCell>{getAttend.reduce((t) => t+1, 0)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Box mt={1} >
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontSize: '9px'}} >Date</TableCell>
                                    <TableCell sx={{fontSize: '9px'}} >Date Name</TableCell>
                                    <TableCell align="right" sx={{fontSize: '9px'}} >Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {daysinmonth.map((data, i) =>
                                <TableRow key={i} >
                                    <TableCell sx={{fontSize: '9px'}} >{data.dayNumber}</TableCell>
                                    <TableCell sx={{fontSize: '9px'}} >{data.dayName}</TableCell>
                                    <TableCell align="right" sx={{fontSize: '9px'}} >{data.dayName === 'Sunday' ? 'Holiday' : attend.includes(data.dayNumber) ? 'Present' : 'Absent' }</TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </Box>
                </Box>

            </DialogContent>
        </Dialog>
    );
}
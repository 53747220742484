import React, { useEffect, useState } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import SidebarAdmin from './sidebar'
import HeaderAdmin from './header'
import Close from './../close'

/* MATERIAL UI */
import { Alert, Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Select, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function OffersAdmin(){

    const api = '/instituteapi/admin/index.php'
    let history = useHistory()
    const { path, url } = useRouteMatch()

    useEffect(() => {
        viewOffers()
    }, [])

    const [offers, setOffers] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)
    const viewOffers = () => {
        var formData = new FormData()
        formData.append('offers', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false); setWarning('')
            if(data.warning){
                setWarning(data.warning)
            } else {
                setOffers(data)
            }
        })
    }

    return(
        <><SidebarAdmin />
        <div className='content'>
        <div className='container-fluid'>
            <div className='row p-3'>
                <div className='col-12 text-center'>
                    <Button color='warning' size='large' variant='contained' onClick={() => history.push(`${url}/configure/0`)} >Post offer</Button>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-8 col-12 mx-auto'>
                    <div className='row'>
                        <div className='col-12'>
                            { loading ? <LinearProgress color='warning' /> :
                            warning ? <Alert severity='warning'>{warning}</Alert> :
                            offers.map((off, i) =>
                            <Card key={i} className='mb-2' >
                                <CardContent>
                                    <small className='text-muted' >{off.of_date}</small>
                                    <h4 className='fw-bold mt-2'>{off.of_head}</h4>
                                    <div className='mt-3'>
                                        <p>{off.of_offer}</p>
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <IconButton onClick={() => history.push(`${url}/delete/${off.offerid}`)} ><DeleteIcon/></IconButton>
                                    <IconButton onClick={() => history.push(`${url}/configure/${off.offerid}`)} ><EditIcon/></IconButton>
                                </CardActions>
                            </Card> )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Route path={`${path}/configure/:offerid`} component={Configure} />
        <Route path={`${path}/delete/:offerid`} component={DeletePost} />
        </>
    );

    function Configure(){
        const { offerid } = useParams()

        const [heading, setHeading] = useState('')
        const [offer, setOffer] = useState('')
        const [warning, setWarning] = useState('')
        const [uploading, setUploading] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('offer-detail', offerid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setHeading(data.of_head); setOffer(data.of_offer);
                }
            })
        }, [offerid])

        const postoffer = () => {
            setWarning(''); setUploading(true)
            var formData = new FormData()
            formData.append('configure-offer', offerid)
            formData.append('offer-head', heading)
            formData.append('offer', offer)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.success){
                    // REDIRECT HERE...
                    viewOffers(); history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Enter offer' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-4'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField fullWidth variant='standard' label='Offer heading' type='text' value={heading} onChange={(event) => setHeading(event.target.value)} />
                                <TextField fullWidth variant='standard' label='Offer' type='text' className='mt-4' rows={4} multiline value={offer} onChange={(event) => setOffer(event.target.value)} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 mt-2'>
                                { warning && <Alert severity='warning'>{warning}</Alert> }
                            </div>
                            <div className='col-12 text-center mt-3'>
                                <Button variant='contained' color='warning' disabled={uploading} onClick={() => postoffer()} >{ uploading ? <CircularProgress color='inherit' size={24} /> : 'UPLOAD' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function DeletePost(){
        const { offerid } = useParams()

        const [deleting, setDeleting] = useState(false)
        const [warning, setWarning] = useState('')

        const deletePost = () => {
            setWarning(''); setDeleting(true)
            var formData = new FormData()
            formData.append('delete-offer', offerid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setDeleting(false)
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.success){
                    viewOffers(); history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogContent>
                    <div className='container-fluid mt-4 mb-5'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h2 className='fw-bold'>Do you want to delete this offer post?</h2>
                            </div>
                            <div className='col-12 mt-2'>
                                {warning && <Alert severity='warning'>{warning}</Alert> }
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button variant='contained' color='warning' className='col-md-4 col-6' disabled={deleting} onClick={() => deletePost()} >{deleting ? <CircularProgress color='inherit' size={24} /> : 'YES' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

}
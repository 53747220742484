import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'
import Bar from '../../bar'
import { BrowserMultiFormatReader } from '@zxing/library';

// MUI
import { Alert, Backdrop, Box, Card, CardContent, CircularProgress, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import {  } from '@mui/icons-material'

export default function AttendanceScanner({apipublic}){
    const videoRef = useRef(null);
    const { branchid } = useParams()
    
    const [scan, setScan] = useState(true)
    useEffect(() => {
        const codeReader = new BrowserMultiFormatReader();
        codeReader
        .decodeFromVideoDevice(undefined, videoRef.current, (result) => {
            if (result) {
                scan === true &&
                makeAttendance(result.text)
            }
        })
        .catch(err => {
            console.error('Error accessing video stream:', err);
        });
        return () => {
            codeReader.reset();
        };
    }, [])

    useEffect(() => {
        attendanceList()
    }, [])

    const [attendance, setAttendance] = useState([])
    const attendanceList = () => {
        var formData = new FormData()
        formData.append('get-attendance-list', branchid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status === false){
                setAttendance(data)
            } else {
                setAttendance(data.data)
            }
        })
    }
    
    const [working, setWorking] = useState(false)
    const [message, setMessage] = useState([])
    const makeAttendance = (studentid) => {
        setWorking(true)
        setMessage('')
        setScan(false)
        var formData = new FormData()
        formData.append('scanner-attendance', studentid)
        formData.append('branchid', branchid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            setScan(true)
            setMessage(data)
            attendanceList()
        })
    }

    return(
        <Box><Bar text='Attendance scanner' />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={working}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                <Grid container spacing={2} mt={3} >
                    <Grid item xs={12} md={6} sx={{height: '80vh'}} >
                        <Card variant="outlined" sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                            <CardContent>
                                <video ref={videoRef} style={{width: '100%', height: '100%', borderRadius: 10}} ></video>
                                {message.status === true || message.status === false ? <Alert severity={message.status === true ? 'success' : 'error'}>{message.msg}</Alert> : null }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} overflow='scroll' sx={{height: '80vh'}} >
                        <Card variant="outlined" sx={{height: '100%', overflow: 'scroll'}} >
                            <CardContent>
                                { attendance.status === false ? <Alert severity="warning">{attendance.msg}</Alert> :
                                <TableContainer>
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell align="left">Name</TableCell>
                                                <TableCell align="left">Code</TableCell>
                                                <TableCell align="right">IN</TableCell>
                                                <TableCell align="right">OUT</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { attendance.map((data, i) =>
                                            <TableRow key={i} >
                                                <TableCell component="th" scope="row">{i+1}</TableCell>
                                                <TableCell align="left">{data.s_name}</TableCell>
                                                <TableCell align="left">{data.s_code}</TableCell>
                                                <TableCell align="right">{data.at_in}</TableCell>
                                                <TableCell align="right">{data.at_out}</TableCell>
                                            </TableRow> ) }
                                        </TableBody>
                                    </Table>
                                </TableContainer>}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
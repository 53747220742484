import React, { useEffect, useState } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import Close from './../close'

/* MUI */
import { Alert, Snackbar, Dialog, DialogContent, DialogTitle, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Button, CircularProgress } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';

export default function AttendanceTeacher(){
    const api = '/instituteapi/teacher/index.php'
    let { batchid } = useParams()
    let history = useHistory()
    const { path, url } = useRouteMatch()
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        viewAttendance()
    }, [])
    
    const [attendances, setAttendances] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)
    const viewAttendance = () => {
        var formData = new FormData()
        formData.append('batch-students', batchid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setAttendances(data)
            }
        })
    }

    const makeAttendance = (studentid) => {
        var formData = new FormData()
        formData.append('make-attendance', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setMessage(data.warning); setOpen(true)
            } else if(data.success){
                setMessage('Attendance succeed!'); setOpen(true)
                viewAttendance()
            }
        })
    }

    return(
        <>
        <Dialog open={true} fullScreen>
            <Snackbar open={open} message={message} onClose={() =>  setOpen(false)} />
            <DialogTitle className='bg-light' ><Close title='Make attendance' /></DialogTitle>
            <DialogContent>
                <div className='container-fluid mb-5 mt-4'>
                    <div className='row'>
                        <div className='col-md-6 col-12 mx-auto'>
                            <div className='row'>
                                { loading ? <LinearProgress color='warning' /> :
                                warning ? <Alert severity='warning'>{warning}</Alert> :
                                <div className='col-12'>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>SR.NO.</TableCell>
                                                    <TableCell align='left'>NAME</TableCell>
                                                    <TableCell align='center'>CODE</TableCell>
                                                    <TableCell align='center'>AT IN</TableCell>
                                                    <TableCell align='center'>AT OUT</TableCell>
                                                    <TableCell align='right'>DELETE</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { attendances.map((at, i) =>
                                                <TableRow key={i} >
                                                    <TableCell>{i+1}</TableCell>
                                                    <TableCell align='left' onClick={() => makeAttendance(at.studentid)} >{at.s_name}</TableCell>
                                                    <TableCell align='center' onClick={() => makeAttendance(at.studentid)} >{at.s_code}</TableCell>
                                                    <TableCell align='center' onClick={() => makeAttendance(at.studentid)} >{at.at_in}</TableCell>
                                                    <TableCell align='center' onClick={() => makeAttendance(at.studentid)} >{at.at_out}</TableCell>
                                                    <TableCell align='right'><IconButton onClick={() => history.push(`${url}/delete/${at.at_table_id}/${at.studentid}`)} ><DeleteIcon /></IconButton></TableCell>
                                                </TableRow> ) }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div> }
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
        <Route path={`${path}/delete/:attendanceid/:studentid`} component={DeleteAttendance} />
        </>
    );

    function DeleteAttendance(){

        const { attendanceid } = useParams()
        const { studentid } = useParams()
        const [warning, setWarning] = useState('')
        const [deleting, setDeleting] = useState(false)

        const deleteAttendance = () => {
            setWarning(''); setDeleting(true)
            var formData = new FormData()
            formData.append('delete-attendance', attendanceid)
            formData.append('studentid', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.success){
                    history.go(-1); viewAttendance()
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <div className='container-fluid mt-5 mb-5'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <h2 className='fw-bold' >Do you want to delete this attendance?</h2>
                        </div>
                        <div className='col-12 mt-2'>
                            { warning && <Alert severity='warning'>{warning}</Alert> }
                        </div>
                        <div className='col-12 mt-3 text-center'>
                            <Button color='warning' className='col-md-3 col-5' size='large' variant='contained' disabled={deleting} onClick={() => deleteAttendance()} >{deleting ? <CircularProgress color='inherit' /> : 'YES' }</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

}